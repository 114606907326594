import ApolloClient, { InMemoryCache } from 'apollo-boost';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import { GRAPH_SERVICE } from '../constants';

// see: https://www.apollographql.com/docs/react/advanced/caching/#inmemorycache
// The InMemoryCache normalizes your data before saving it to the store by splitting
// the result into individual objects, creating a unique identifier for each object,
// and storing those objects in a flattened data structure. By default, InMemoryCache
// will attempt to use the commonly found primary keys of id and _id for the unique
// identifier if they exist along with __typename on an object.
//
// Much of the data we are dealing with in this project (as of 8/5/19), does not have a
// unique identifier. In fact, some data do contain an 'id' key, but fail to provide unique
// values or have null as a value.
// `dataIdFromObject: () => null` provides no data key for caching, and thus uses a different
// method for caching.
const client = new ApolloClient({
    uri: GRAPH_SERVICE,
    cache: new InMemoryCache({
        dataIdFromObject: () => null
    }),
});

/**
 * A simple util for making a graphql query without a query component
 *
 * @param {object} queryParts Contains the query and variables
 * @param {object} queryParts.query The gql query
 * @param {object} queryParts.variables (optional) Query variables
 *
 * @returns {promise} A promise that resolves on query completion.
 */
const makeQuery = (queryParts) => {
    const query = get(queryParts, 'query', null);
    const variables = get(queryParts, 'variables', {});

    if (isNull(query)) {
        console.error('Provided query are null, please provide a valid query object.');
        return null;
    }

    return client.query({ query, variables });
};

export default makeQuery;
