import assign from 'lodash/assign';
import { COLOR_BY_OPTIONS, VISUALIZATIONS } from '../constants';
import TranscriptomicsDataset from '../model/transcriptomics-dataset';
import getDatasets from './datasets-metadata';

/**
 * Returns a valid param value from a URLSearchParams object or a default value
 * @param {URLSearchParams} params - `searchParams` from URL instance
 * @param {string} property - property name to validate
 * @param {[key: string]: string} lookup - a dictionary style object with valid values to check
 * @param {string} defaultValue - a valid value
 * @returns - string with valid value
 */
export const validateParam = (params, property, lookup, defaultValue) => {
    const urlState = params.get(property);
    const isValid = Object.values(lookup).some((value) => value === urlState);
    return isValid ? urlState : defaultValue;
};

/**
 * Fetches all metadata needed to initialize the application store.
 * Returns a hash of shape: { [metadata_property]: Object }
 *
 * At the time of implementation, only "datasets" was necessary, but I could easily see
 * more uses as the application grows.
 */
export default function fetchStoreMetadata() {
    const metadataCalls = [
        getDatasets().then(datasets => ({ datasets })),
    ];

    return Promise.all(metadataCalls)
        .then(metadata => metadata.reduce((acc, metaDatum) => assign(acc, metaDatum), {}))
        .then(metadata => {
            const url = new URL(document.location);
            const path = url.pathname;
            const selectedDataset = metadata.datasets.find((dataset) => TranscriptomicsDataset.deriveUriFromName(dataset.name) === path);
            const params = url.searchParams;
            const selectedVisualization = validateParam(params, 'selectedVisualization', VISUALIZATIONS, VISUALIZATIONS.HEATMAP);
            const colorByFeature = validateParam(params, 'colorByFeature', COLOR_BY_OPTIONS, COLOR_BY_OPTIONS.CELL_TYPE);
            const colorByFeatureValue = params.get('colorByFeatureValue');

            return {
                ...metadata,
                selectedDataset,
                selectedVisualization,
                colorByFeature,
                colorByFeatureValue,
            };
        })
        .catch((e) => {throw e;});
}
