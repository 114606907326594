/**
 * Utility function for rounding numbers to a certain number of digit places.
 * Examples:
 * roundDigits(1.2736, 3) -> 1.274
 * roundDigits(1.2736, 2) -> 1.27
 * roundDigits(1.2736, 1) -> 1.3
 * roundDigits(531.2736, 0) -> 531
 * roundDigits(53125.2736, -1) -> 53130
 * @param {number} x - number to be rounded to d digit places
 * @param {number} digits - provided integer to specify how many decimal places to round off.
 */
export default function roundDigits(x, digits) {
    if (digits !== Math.floor(digits)) {
        console.warn(`roundDigits was not provided an integer for decimal places: ${digits}`);
    }
    return Math.round(x * Math.pow(10, digits)) / Math.pow(10, digits);
}
