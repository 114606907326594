import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { range } from 'd3-array';
import { axisBottom } from 'd3-axis';
import { select } from 'd3-selection';
import { scaleSequential, scaleLinear } from 'd3-scale';
import { format } from 'd3-format';
import './style.scss';

/**
 * Scalebar is comprised of a color scale, tick marks, and a title
 * code based on example from https://bl.ocks.org/john-guerra/17fe498351a3e70929e2e36d081e1067
 *
 * @param {Object} props
 * @param {Object} props.colorScheme - d3 color scheme reference
 * @param {boolean} props.reverseScheme - whether to reverse the color scheme, default true
 * @param {number} props.minValue - minimum scale value
 * @param {number} props.maxValue - maximum scale value
 * @param {number} props.width - scalebar width, default 400
 * @param {number} props.height - scalebar height, default 100
 * @param {number} props.precision - number of digits to right of decimal point, defaults to 0
 * @param {string} props.title - scalebar title
 */
const Scalebar = props => {
    const {
        colorScheme,
        reverseScheme = true,
        minValue = 0,
        maxValue = 100,
        width,
        height,
        precision = 0,
        title = '',
        tickCount = 5,
        onClick,
    } = props;

    // svg, canvas dimensions of component
    const componentWidth = width;
    const componentHeight = height;

    // scalebar params
    const xStartScalebar = 0;
    const xEndScalebar = componentWidth;

    // color scale params
    const colorScaleHeight = 20;
    const yTopColorScale = 0;
    const yBottomColorScale = yTopColorScale + colorScaleHeight;

    // title params
    const titleFont = '15px Arial';

    const canvasRef = useRef(null);
    const svgRef = useRef(null);
    const titleRef = useRef(null);

    /**
     * Draw the tickmarks and values using SVG
     *
     * @param {Object} linearScale - d3 scaleLinear object
     * @param {string} numFormat - d3 number format
     * @param {number} x - x coord for translation
     * @param {number} y - y coord for translation
     */
    const drawTickMarks = useCallback((linearScale, numFormat, x, y, tickCount) => {
        // add tickmarks with numbers drawn at bottom of scale
        const translate = `translate(${x}, ${y})`;
        const ticks = tickCount - 1;

        const tickValues = [...range(minValue, maxValue, (maxValue - minValue) / ticks), maxValue];
        select(svgRef.current)
            .append('g')
            .attr('transform', translate)
            .call(axisBottom(linearScale).tickFormat(format(numFormat)).tickValues(tickValues));
    }, [minValue, maxValue, svgRef]);

    /**
     * Draws centered scalebar title beneath the color scale and tickmarks using SVG
     *
     * @param {Object} ctx - canvas context
     * @param {string} font - font and size
     * @param {string} title - description of scalebar
     * @param {number} scalebarWidth - scalebar width
     * @param {number} yTitle - y coord of title
     */
    const drawTitle = (ctx, font, title, scalebarWidth, yTitle) => {
        // get the title dimensions by pretending to draw it on the canvas context
        ctx.font = font;
        const { height } = ctx.measureText(title);

        const xTitle = scalebarWidth / 2;
        const translate = `translate(${xTitle}, ${yTitle}) scale(0.6)`;

        // select the <svg> for the title and draw the title text on it
        select(titleRef.current)
            .append('text')
            .attr('transform', translate)
            .attr('text-anchor', 'middle')
            .attr('x', 0)
            .attr('y', height)
            .text(title);
    };

    useEffect(() => {
        // maps the min and max values extent to the x-coords of actual scalebar, used by color scale and tickmarks
        // 'nice' rounds the domain values so that they'll appear on 1st, last tick marks
        const linearScale = scaleLinear()
            .domain([minValue, maxValue])
            .range([xStartScalebar, xEndScalebar]);

        const ctx = canvasRef.current.getContext('2d');

        /**
         * Draw the color scale onto the canvas
         *
         * @param {Object} linearScale
         * @param {Object} ctx - canvas context
         */
        const drawColorScale = (linearScale, ctx) => {
            // map the color scheme across the scalebar numerical range
            let start = minValue;
            let end = maxValue;

            if (reverseScheme) {
                start = maxValue;
                end = minValue;
            }
            const sequentialColor = scaleSequential(colorScheme).domain([start, end]);

            // draw colored vertical lines on canvas
            range(minValue, maxValue, (maxValue - minValue) / 100).forEach(increment => {
                ctx.lineWidth = 15;
                ctx.beginPath();
                ctx.strokeStyle = sequentialColor(increment);
                ctx.moveTo(linearScale(increment), yTopColorScale);
                ctx.lineTo(linearScale(increment), yBottomColorScale);
                ctx.stroke();
            });
        };

        /**
         * Convert the 'precision' prop to a D3 number format
         *
         * @return {string} D3 format
         */
        const getNumberFormat = () => {
            // there are *many* formats available in D3, in the future this function can be expanded
            // See https://github.com/d3/d3-format
            let format = '';
            if (precision > 0) {
                format = `.${precision}f`;
            }

            return format;
        };

        // clear canvas and svg contents
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        const svgGroups = select('.scalebar__svg').selectAll('g');
        svgGroups.remove();
        const titleText = select('.scalebar__title').selectAll('text');
        titleText.remove();


        // draw the scalebar
        drawColorScale(linearScale, ctx);

        if (tickCount > 0) {
            const numberFormat = getNumberFormat();
            drawTickMarks(linearScale, numberFormat, xStartScalebar, yBottomColorScale, tickCount);
        }

        const yTitle = yBottomColorScale + 25; // colorscale and tickmarks same height
        drawTitle(ctx, titleFont, title, componentWidth, yTitle);
    }, [drawTickMarks, colorScheme, maxValue, componentWidth, minValue, precision, reverseScheme, title, xEndScalebar, yBottomColorScale, tickCount]);

    const scalebarStyle = {
        height: `${componentHeight}px`,
        width: `${componentWidth}px`
    };

    const canvasAttributes = {
        height: componentHeight,
        width: componentWidth
    };

    const svgAttributes = {
        width: componentWidth,
        height: componentHeight,
        viewBox: `0, 0, ${componentWidth}, ${componentHeight}`
    };

    const titleAttributes = {
        width: componentWidth,
        height: componentHeight,
        viewBox: `0, 0, ${componentWidth}, ${componentHeight}`
    };

    return (
        <div
            className='scalebar'
            style={scalebarStyle}
            onClick={onClick}
        >
            <canvas className='scalebar__canvas' ref={canvasRef} {...canvasAttributes} />
            <svg className='scalebar__svg' ref={svgRef} {...svgAttributes} />
            <svg className='scalebar__title' ref={titleRef} {...titleAttributes} />
        </div>
    );
};

Scalebar.propTypes = {
    colorScheme: PropTypes.func.isRequired,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    reverseScheme: PropTypes.bool,
    precision: PropTypes.number,
    title: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    tickCount: PropTypes.number,
    onClick: PropTypes.func,
};

export default Scalebar;
