import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// ACTIONS
import {
    dendrogramDimensionUpdate,
    toggleIsMinimizedSelectionPane,
} from '../../actions/dendrogram-actions';
import {
    tooltipUpdate,
    tooltipHide,
} from '../../actions/tooltip-actions';
import {
    removeClusterSelection,
    changeClusterSelection,
} from '../../actions';

// COMPONENTS
import Dendrogram, { SelectionInfo, DendrogramTextOverlay, TooltipContent } from '../../components/dendrogram';
import WindowedTranslateWrapper from '../../components/windowed-translate-wrapper';

// CONSTANTS
import {
    TAXONOMY_COLOR_ACCESSOR,
    TAXONOMY_ID_ACCESSOR,
    TAXONOMY_LABEL_ACCESSOR,
    ZERO,
} from '../../constants';

// SELECTORS
import {
    getSelectedDatasetName,
    getSelectedClustersByDataset,
    getClusterCounts,
    getClusterCountTotal,
    getClusterCountMax,
} from '../../selectors';
import {
    getEdgeData,
    getNodeData,
    getTooltipEnabled,
    getNodeTextData,
    getIsMinimizedSelectionPaneForDataset,
} from '../../selectors/dendrogram-selectors';
import {
    getTaxonomyLeaves,
    getSelectedTaxonomyNodeDescendantMap,
    getSelectedLeafIndexRangePairs,
} from '../../selectors/taxonomy-selectors';

// UTILS
import useKeyHandler from '../../utils/use-key-handler';
import useReferenceWrapper from '../../utils/use-reference-wrapper';

// MODULE CONSTANTS
const PADDING_TOP = 10;

const DendrogramContainer = (props, ref) => {
    const dispatch = useDispatch();
    const edgeData = useSelector(getEdgeData);
    const leafData = useSelector(getTaxonomyLeaves);
    const clusterCountData = useSelector(getClusterCounts);
    const clusterCountTotal = useSelector(getClusterCountTotal);
    const clusterCountMax = useSelector(getClusterCountMax);
    const fullDendrogramWidth = leafData.length * props.columnWidth;
    const nodeData = useSelector(getNodeData);
    const nodeTextData = useSelector(getNodeTextData);
    const selectedDataset = useSelector(getSelectedDatasetName);
    const selectedClusters = useSelector(getSelectedClustersByDataset);
    const selectedDescendants = useSelector(getSelectedTaxonomyNodeDescendantMap);
    const selectedLeafIndexRangePairs = useSelector(getSelectedLeafIndexRangePairs);
    const selectionRef = useReferenceWrapper(selectedDescendants);
    const tooltipEnabled = useSelector(getTooltipEnabled);
    const isMinimizedSelectionPane = useSelector(getIsMinimizedSelectionPaneForDataset);

    // Storing dimension layout info in state allows us to use reselect when creating the layout.
    useEffect(() => {
        dispatch(dendrogramDimensionUpdate({
            columnWidth: props.columnWidth,
            height: props.height,
            rowHeight: props.leafRowHeight + props.countRowHeight,
            paddingTop: PADDING_TOP,
        }));
    }, [dispatch, props.columnWidth, props.height, props.leafRowHeight, props.countRowHeight]);

    // Cancel tooltip if it is not enabled
    useEffect(
        () => {if (!tooltipEnabled) dispatch(tooltipHide());},
        [tooltipEnabled, dispatch],
    );

    // Escape deselects a node
    const escapeHandler = useCallback(
        (escKeyIsDown) => {if (escKeyIsDown) dispatch(removeClusterSelection(selectedDataset));},
        [dispatch, selectedDataset],
    );
    useKeyHandler(['Escape', 'Esc'], escapeHandler);

    // click handler
    const onDendrogramClick = useCallback(({ object }, { srcEvent }) => {
        const isMultiSelect = srcEvent.shiftKey || srcEvent.metaKey || srcEvent.ctrlKey;

        // Select cluster id only if there are no other clusters selected or user is selecting an additional node.
        // Provides deselect functionality.
        if (isEmpty(selectionRef.current) || isMultiSelect) {
            dispatch(changeClusterSelection(object, selectedDataset));
        } else {
            dispatch(removeClusterSelection(selectedDataset));
        }
    }, [dispatch, selectionRef, selectedDataset]);

    // hover handler
    const onHover = useCallback((info, e) => {
        const data = get(info, ['object', 'data']);
        const alias = get(info, ['object', 'data', 'alias']);
        const sourceLayerId = get(info, ['object', 'sourceLayerId']);

        const contentProps = {
            data,
            sourceLayerId,
            cellCount: clusterCountData[alias] || ZERO,
            percent: clusterCountData[alias] / clusterCountTotal * 100 || ZERO,
        };

        if (!data) {
            dispatch(tooltipHide());
            return;
        }

        const tooltipData = {
            contentProps,
            contentComponent: TooltipContent,
            x: e.srcEvent.pageX,
            y: e.srcEvent.pageY,
        };

        dispatch(tooltipUpdate(tooltipData));
    }, [dispatch, clusterCountTotal, clusterCountData]);

    const toggleMinimize = useCallback(() => {
        dispatch(toggleIsMinimizedSelectionPane(selectedDataset));
    }, [dispatch, selectedDataset]);

    if (!nodeData) {
        return null;
    }

    // Since the dendrogram labels are absolute positioned HTML elements,
    // if we translated them with the deck.gl chart, it would have the effect
    // of staying still. Treating the label overlays as "stationary" allows
    // for the illusion of normal coordinated scrolling between the elements.
    const StationaryChild = !props.isLoading && (
        <DendrogramTextOverlay
            idAccessor={TAXONOMY_ID_ACCESSOR}
            labelAccessor={TAXONOMY_LABEL_ACCESSOR}
            data={nodeTextData}
            paddingTop={PADDING_TOP}
            selectedMap={selectedDescendants}
        />
    );

    const dendrogramViewState = {
        target: [
            (props.width / 2) + props.scrollLeft,
            props.height / 2,
        ],
    };

    return (
        <>
            {selectedClusters && (
                <SelectionInfo
                    isMinimizedSelectionPane={isMinimizedSelectionPane}
                    nodeAccessor={TAXONOMY_ID_ACCESSOR}
                    selectedClusters={selectedClusters}
                    toggleMinimize={toggleMinimize}
                />
            )}
            <WindowedTranslateWrapper
                childrenStationary={StationaryChild}
                ref={ref}
                scrollLeft={props.scrollLeft}
                scrollTop={0}
                totalHeight={props.height}
                totalWidth={fullDendrogramWidth}
                windowedHeight={props.height}
                windowedWidth={props.width}
                followerOnly
            >
                <Dendrogram
                    columnWidth={props.columnWidth}
                    edgeData={edgeData}
                    height={props.height}
                    leafData={leafData}
                    clusterCountData={clusterCountData}
                    clusterCountMax={clusterCountMax}
                    nodeColorAccessor={TAXONOMY_COLOR_ACCESSOR}
                    nodeData={nodeData}
                    nodeLabelAccessor={TAXONOMY_LABEL_ACCESSOR}
                    nodeTextData={nodeTextData}
                    onDendrogramClick={onDendrogramClick}
                    onHover={onHover}
                    onLoad={props.onLoad}
                    paddingTop={PADDING_TOP}
                    leafRowHeight={props.leafRowHeight}
                    countRowHeight={props.countRowHeight}
                    selectedDescendants={selectedDescendants}
                    selectedLeafIndexRangePairs={selectedLeafIndexRangePairs}
                    scrollLeft={props.scrollLeft}
                    width={props.width}
                    viewState={dendrogramViewState}
                />
            </WindowedTranslateWrapper>
        </>
    );
};

export default React.forwardRef(DendrogramContainer);

DendrogramContainer.propTypes = {
    // css classname
    className: PropTypes.string,

    // width of dendrogram leaf
    columnWidth: PropTypes.number.isRequired,

    // height of chart
    height: PropTypes.number,

    // max-height of info box
    infoMaxHeight: PropTypes.number,

    // indicate if greater component is loading
    // helpful to sync the node label rendering
    isLoading: PropTypes.bool,

    // callback for when chart is loaded
    onLoad: PropTypes.func,

    // height of dendrogram leaves
    leafRowHeight: PropTypes.number,

    // height of dendrogram cell count chart
    countRowHeight: PropTypes.number,

    // scroll left state position
    scrollLeft: PropTypes.number,

    // width of screen available for component
    width: PropTypes.number,
};
