export default function getMatrixIndicesFromEventFunction({ rowHeight = 1, columnWidth = 1 }, getPosition) {
    return event => {
        const { x, y } = getPosition(event);

        return {
            rowIndex: Math.floor(y / rowHeight),
            columnIndex: Math.floor(x / columnWidth)
        };
    };
}
