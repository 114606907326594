import { createSelector } from 'reselect';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import {
    MARKER_GENE_ACCESSOR,
    VISUALIZATIONS_MAP,
} from '../constants';
import { getSelectedDatasetName } from '.';

export const getSelectedVisualizationKey = state => VISUALIZATIONS_MAP[state.selectedVisualization];
export const getGeneValidationRoot = state => state.geneValidation;

export const getCurrentGeneValidation = createSelector(
    [getGeneValidationRoot, getSelectedDatasetName, getSelectedVisualizationKey],
    (root, dataset, visualization) => get(root, [dataset, visualization], {}),
);
export const getInputText = createSelector([getCurrentGeneValidation], ({ inputText }) => inputText);
export const getInputGenes = createSelector([getCurrentGeneValidation], ({ inputGenes }) => inputGenes);
export const getValidGenes = createSelector([getCurrentGeneValidation], ({ validGenes }) => validGenes);

export const getGeneValidationReport = createSelector(
    [getInputGenes, getValidGenes],
    (inputGenes, validGenes) => {
        const validGeneLookup = keyBy(validGenes, gene => gene[MARKER_GENE_ACCESSOR].toLowerCase());

        // 1) Only show recognized genes once.
        // 2) Show every unique unrecognized gene (case sensitive).
        const geneValidationObject = inputGenes.reduce((acc, inputSymbol) => {
            const recognizedSymbol = get(validGeneLookup, [inputSymbol.toLowerCase(), MARKER_GENE_ACCESSOR]);

            const label = recognizedSymbol || inputSymbol;
            const isRecognized = !!recognizedSymbol;

            acc[label] = {
                label,
                isRecognized,
            };

            return acc;
        }, {});

        return Object.values(geneValidationObject);
    },
);
