import get from 'lodash/get';
import {
    MARKER_GENE_ROW_LABEL,
    MARKER_GENE_ROW_LABEL_HEADER,
    USER_GENE_ROW_LABEL,
    LABEL_MENU_HIDE,
    LABEL_MENU_HIDE_ALL,
    LABEL_MENU_UNHIDE_ALL,
    LABEL_MENU_DELETE,
    LABEL_MENU_DOWN,
    LABEL_MENU_UP,
    SPECIES_MOUSE,
    LABEL_MENU_VIEW_IN_MOUSE_ATLAS,
    ICON_DELETE,
    ICON_COLOR_SCATTER_PLOT,
    ICON_LINK,
    ICON_HIDE,
    ICON_UP,
    ICON_DOWN,
    ICON_MENU,
    ICON_UNHIDE,
    LABEL_MENU,
    LABEL_MENU_PLOT,
} from '../../constants';

const MOUSE_BASE_URL = '//mouse.brain-map.org/search/show?search_type=gene&exact_match=true&search_term=';
const getMouseGeneBrainAtlasUrl = geneSymbol => `${MOUSE_BASE_URL}${geneSymbol}`;

const getColorScatterPlotText = geneSymbol => `Color Scatter Plot with expression of ${geneSymbol}`;

export default function getRowLabelMenuOptions(menuType, properties) {
    const options = [];

    switch (menuType) {
        case USER_GENE_ROW_LABEL:
            options.push(
                {
                    iconClassname: ICON_MENU,
                    isMenuItem: false,
                    text: LABEL_MENU,
                },
                {
                    callback: properties.moveUpUserGene,
                    iconClassname: ICON_UP,
                    isMenuItem: true,
                    text: LABEL_MENU_UP,
                },
                {
                    callback: properties.moveDownUserGene,
                    iconClassname: ICON_DOWN,
                    isMenuItem: true,
                    text: LABEL_MENU_DOWN,
                },
                {
                    callback: properties.colorScatterPlotByGene,
                    iconClassname: ICON_COLOR_SCATTER_PLOT,
                    iconText: LABEL_MENU_PLOT,
                    isMenuItem: true,
                    text: getColorScatterPlotText,
                },
                {
                    callback: properties.deleteUserGene,
                    iconClassname: ICON_DELETE,
                    isMenuItem: true,
                    text: LABEL_MENU_DELETE,
                },
            );
            if (get(properties, ['selectedDatasetObject', 'species']) === SPECIES_MOUSE) {
                options.push({
                    callback: properties.closeLabelMenu,
                    iconClassname: ICON_LINK,
                    isMenuItem: true,
                    link: getMouseGeneBrainAtlasUrl,
                    text: LABEL_MENU_VIEW_IN_MOUSE_ATLAS,
                });
            }

            break;

        case MARKER_GENE_ROW_LABEL:
            options.push(
                {
                    iconClassname: ICON_MENU,
                    isMenuItem: false,
                    text: LABEL_MENU,
                },
                {
                    callback: properties.moveUpMarkerGene,
                    iconClassname: ICON_UP,
                    isMenuItem: true,
                    text: LABEL_MENU_UP,
                },
                {
                    callback: properties.moveDownMarkerGene,
                    iconClassname: ICON_DOWN,
                    isMenuItem: true,
                    text: LABEL_MENU_DOWN,
                },
                {
                    callback: properties.colorScatterPlotByGene,
                    iconClassname: ICON_COLOR_SCATTER_PLOT,
                    iconText: LABEL_MENU_PLOT,
                    isMenuItem: true,
                    text: getColorScatterPlotText,
                },
                {
                    callback: properties.hideMarkerGene,
                    iconClassname: ICON_HIDE,
                    isMenuItem: true,
                    text: LABEL_MENU_HIDE,
                },
            );
            if (get(properties, ['selectedDatasetObject', 'species']) === SPECIES_MOUSE) {
                options.push({
                    callback: properties.closeLabelMenu,
                    iconClassname: ICON_LINK,
                    isMenuItem: true,
                    link: getMouseGeneBrainAtlasUrl,
                    text: LABEL_MENU_VIEW_IN_MOUSE_ATLAS,
                });
            }

            break;

        case MARKER_GENE_ROW_LABEL_HEADER:
            options.push(
                {
                    iconClassname: ICON_MENU,
                    isMenuItem: false,
                    text: LABEL_MENU,
                },
                {
                    callback: properties.hideAllMarkerGenes,
                    iconClassname: ICON_HIDE,
                    isMenuItem: true,
                    text: LABEL_MENU_HIDE_ALL,
                },
                {
                    callback: properties.unhideAllMakerGenes,
                    iconClassname: ICON_UNHIDE,
                    isMenuItem: true,
                    text: LABEL_MENU_UNHIDE_ALL,
                },
            );

            break;

        default:
            break;
    }

    return options;
}
