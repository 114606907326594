import React from 'react';
import PropTypes from 'prop-types';
import DeckGL from '@deck.gl/react';
import { OrthographicView } from '@deck.gl/core';

// COMPONENTS
import WindowedTranslateWrapper from '../windowed-translate-wrapper';

// CONSTANTS
import {
    GRID_COMPOSITE_LAYER,
} from '../../constants';

// DECK.GL LAYERS
import HeatmapGridLayer from '../../custom-layers/heatmap-grid-composite-layer';

const CONTENT_GRID_LAYER_CLASSES = [HeatmapGridLayer];

const ContentGrid = React.forwardRef((props, contentGridRef) => {
    const {
        columnWidth,
        getColor,
        height,
        matrix,
        matrixRowIndexRangesWithData,
        matrixWidth,
        minColor,
        nonSelectedIndexRangePairs,
        onClick,
        onAfterRender,
        onMouseLeave,
        onMouseMove,
        rowHeight,
        scrollLeft,
        scrollTop,
        selectedIndexRangePairs,
        sparseMatrix,
        updateScroll,
        width,
        viewState,
    } = props;

    // Setting the controller as false helps performance/avoids issues with slow clicking
    // https://github.com/uber/deck.gl/blob/master/docs/api-reference/controller.md
    const view = new OrthographicView({
        id: 'HEATMAP_CONTROLLER',
        controller: false,
    });

    return (
        <WindowedTranslateWrapper
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            onScroll={updateScroll}
            ref={contentGridRef}
            scrollLeft={scrollLeft}
            scrollTop={scrollTop}
            totalHeight={matrix.length * rowHeight}
            totalWidth={matrixWidth * columnWidth}
            windowedHeight={height}
            windowedWidth={width}
        >
            <DeckGL
                getCursor={() => 'auto'}
                layers={CONTENT_GRID_LAYER_CLASSES.map((Layer) => new Layer({
                    id: GRID_COMPOSITE_LAYER,
                    columnWidth,
                    getColor,
                    matrix,
                    matrixRowIndexRangesWithData,
                    matrixWidth,
                    minColor,
                    nonSelectedIndexRangePairs,
                    rowHeight,
                    selectedIndexRangePairs,
                    sparseMatrix,
                }))}
                onAfterRender={onAfterRender}
                views={[view]}
                viewState={viewState}
            />
        </WindowedTranslateWrapper>
    );
});

ContentGrid.propTypes = {
    // key of column label
    columnLabelAccessor: PropTypes.string,

    // width of each column
    columnWidth: PropTypes.number,

    // color used for minimum grid cell value
    minColor: PropTypes.string,

    // height of the constraining element
    // used to show scroll bars
    height: PropTypes.number,

    // function for getting color of rect cell
    // (t: number) => color string
    getColor: PropTypes.func,

    nonSelectedIndexRangePairs: PropTypes.array,

    onAfterRender: PropTypes.func,

    // onClick callback
    onClick: PropTypes.func,

    // function used to display the loading indicator
    onLoad: PropTypes.func,

    // onMouseLeave callback
    onMouseLeave: PropTypes.func,

    // onMouseMove callback
    onMouseMove: PropTypes.func,

    // 2d matrix data. Array for each row in Grid. Each array element contains an arrays for each column
    matrix: PropTypes.PropTypes.arrayOf(PropTypes.array),

    matrixRowIndexRangesWithData: PropTypes.array,

    matrixWidth: PropTypes.number,

    // height of each row
    rowHeight: PropTypes.number,

    // key of row label
    rowLabelAccessor: PropTypes.string,

    // current left scroll position
    scrollLeft: PropTypes.number,

    // current top scroll position
    scrollTop: PropTypes.number,

    selectedIndexRangePairs: PropTypes.array,

    // sparse array of GridCells containing data
    sparseMatrix: PropTypes.arrayOf(PropTypes.object),

    // (ref: element) => void
    // callback used to get scroll position when changed
    updateScroll: PropTypes.func,

    // width of the constraining element
    // used to show scroll bars
    width: PropTypes.number,

    tooltipContent: PropTypes.func,

    viewState: PropTypes.object,
};

export default ContentGrid;
