import React, { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// UTILs
import getClassName from '../../utils/get-class-name';

// STYLES
import './style.scss';

// MODULE CONSTANTS
const NODE_PARENT_DISTANCE_THRESHOLD_Y = 10;

/**
 * Determines the class for how a the text label for a node should be positioned,
 * with regard to css absolute position of the node point, 'right' or 'left'.
 *
 * 'right' would put the point to the right:    text.
 * 'left' would put the point to the left:      .text
 *
 * @param {object} d - D3 Node
 */
const getNodeTextAnchorDirection = (d) => {
    // If the parent is to the right; and is not much higher than the node in question;
    // I find that putting it to the left of the node seems to work fairly well.
    // Also, if the parent is to the left; and is sufficiently higher than the node in question; there is usually space for a label.
    // Applying an XNOR (conditionA === conditionB) on these two conditions seems to yield nice results most of the time.

    const parentFromRight = d.parent.x > d.x;
    const closeParentY = (d.y - d.parent.y) < NODE_PARENT_DISTANCE_THRESHOLD_Y;
    const shouldPositionRight = closeParentY === parentFromRight;

    return shouldPositionRight ? 'right' : 'left';
};

const getNodeTextOpacityClassName = (selectedMap, idAccessor, d) => {
    const selectionExists = !!selectedMap;
    const isSelected = get(selectedMap, [d[idAccessor]]);

    return selectionExists && !isSelected ? 'dimmed' : 'full-opacity';
};

const getNodeTextClassNames = (selectedMap, idAccessor, d) => {
    const anchorClassName = getNodeTextAnchorDirection(d);
    const opacityClassName = getNodeTextOpacityClassName(selectedMap, idAccessor, d);
    return getClassName('dendrogram-node-text__label', [anchorClassName, opacityClassName]);
};

const DendrogramTextOverlay = ({
    idAccessor,
    labelAccessor,
    data,
    paddingTop,
    selectedMap,
}) => data.map(d => (
    <div
        className='dendrogram-node-text__pivot-point'
        key={d.data[labelAccessor]}
        style={{
            top: d.y + paddingTop,
            left: d.x,
        }}
    >
        <div className={getNodeTextClassNames(selectedMap, idAccessor, d)}>
            {d.data[labelAccessor]}
        </div>
    </div>
));

DendrogramTextOverlay.propTypes = {
    // key of node id
    idAccessor: PropTypes.string.isRequired,

    // key name to access label from datum objects
    labelAccessor: PropTypes.string.isRequired,

    // array of D3 Cluster Nodes
    data: PropTypes.arrayOf(PropTypes.object).isRequired,

    // amount of padding above the dendrogram
    paddingTop: PropTypes.number.isRequired,

    // map of selected nodes to their d3 Node objects
    // [node_id]: <D3_Node>
    selectedMap: PropTypes.object,
};

export default memo(DendrogramTextOverlay);
