/**
 * Util for setting a key value pair in local storage
 * @param {string} key - key used to access data in local storage
 * @param {string or object or array} value - value to store that is a string or can be converted to a string via JSON.stringify
 */
export default function setLocaltorage(key, value) {
    const storageValue = typeof value === 'string' ? value : JSON.stringify(value);

    localStorage.setItem(key, storageValue);
}
