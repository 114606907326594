import React from 'react';
import PropTypes from 'prop-types';
import { ErrorIndicator } from '../indicators';
import eventError from '../../analytics/event-error';
import isProduction from '../../utils/is-production';
import { PROD_ERROR_MESSAGE } from '../../constants';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        const initialState = {
            hasError: !!props.error,
        };

        if (initialState.hasError) {
            // for Google Tag Manager
            eventError(props.error.message, 'FATAL_ERROR');

            initialState.errorMessage = isProduction() ? PROD_ERROR_MESSAGE : props.error.message;
        }

        this.state = initialState;
    }

    static getDerivedStateFromError(error) {
        // for Google Tag Manager
        eventError(error.message, 'FATAL_ERROR');

        return {
            hasError: true,
            errorMessage: isProduction() ? PROD_ERROR_MESSAGE : error.message,
        };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorIndicator message={this.state.errorMessage} />;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    error: PropTypes.object,
};
