import assign from 'lodash/assign';
import get from 'lodash/get';

import {
    DENDROGRAM_DIMENSION_UPDATE,
    DENDROGRAM_FETCH_TAXONOMY,
    DENDROGRAM_RECEIVE_TAXONOMY,
    DENDROGRAM_TOGGLE_SELECTION_PANE_MINIMIZE,
} from '../actions/dendrogram-actions';
import { FETCH_STATE } from '../constants';

const receiveTaxonomyReducer = (state, action) => {
    const {
        data: {
            taxonomy,
        },
        metadata: {
            selectedDataset,
        },
        status,
    } = action;

    return assign({}, state, {
        taxonomyFetchState: status,
        taxonomy: {
            [selectedDataset]: taxonomy,
        },
    });
};

const toggleSelectionPaneMinimizeReducer = (state, { selectedDataset }) => {
    const currentShowHide = get(state, ['isMinimizedSelectionPane']);
    const isMinimizedSelectionPane = assign({}, currentShowHide, {
        [selectedDataset]: !get(currentShowHide, [selectedDataset])
    });

    return assign({}, state, { isMinimizedSelectionPane });
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 * Dataset specific store items are created lazily when set into state
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createDendrogramReducer() {
    // TODO: CONSISTENT STRUCTURE OF STATE.
    // TODO: CURRENTLY IMPLICIT PROPERTIES HAVE THEIR OWN DATASET NESTING
    const defaultState = {
        dimensions: null,
        taxonomyFetchState: FETCH_STATE.INIT,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
            case DENDROGRAM_DIMENSION_UPDATE:
                return assign({}, state, { dimensions: action.dimensions });

            case DENDROGRAM_FETCH_TAXONOMY:
                return assign({}, state, { taxonomyFetchState: action.status });

            case DENDROGRAM_RECEIVE_TAXONOMY:
                return receiveTaxonomyReducer(state, action);

            case DENDROGRAM_TOGGLE_SELECTION_PANE_MINIMIZE:
                return toggleSelectionPaneMinimizeReducer(state, action);

            default:
                return state;
        }
    };
}
