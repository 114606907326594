import { FETCH_STATE } from '../constants';
import { makeQuery } from '../utils';
import { fetchErrorAction } from './errors-actions';

export default function fetchData(
    query,
    fetchingActionType,
    completeActionType,
    errorActionType,
    metadata,
) {
    return async (dispatch) => {
        try {
            dispatch({
                type: fetchingActionType,
                status: FETCH_STATE.FETCHING
            });

            const { data } = await makeQuery(query);

            dispatch({
                type: completeActionType,
                status: FETCH_STATE.COMPLETE,
                data,
                metadata,
            });
        } catch (error) {
            dispatch(fetchErrorAction(error, metadata, errorActionType));
        }
    };
}
