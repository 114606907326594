import gql from 'graphql-tag';

const query = gql`
    query scatterPlotCellExpression(
        $dataset: String
        $feature: String
    ) {
        expressionData: allRowsForFeature(
            dataset: $dataset
            feature: $feature
        )
    }
`;

const getScatterPlotGeneExpressionQuery = (dataset, feature) => {
    const variables = { dataset, feature };

    return { query, variables };
};

export default getScatterPlotGeneExpressionQuery;
