import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import MinimizeExpandContainer from '../minimize-expand-container';
import SelectionInfoContent from './selection-info-content';

// UTILS
import pluralizeString from '../../utils/pluralize-string';

import './style.scss';

const SelectionInfo = (props) => {
    const {
        isMinimizedSelectionPane,
        nodeAccessor,
        selectedClusters,
        toggleMinimize,
    } = props;

    return (
        <MinimizeExpandContainer
            expandText={`Show ${pluralizeString(selectedClusters.length, 'cell type')}`}
            minimizeText='Hide'
            shouldDisplayHeader={!!(selectedClusters.length > 1 || isMinimizedSelectionPane)}
            showIconText
            isMinimized={!!isMinimizedSelectionPane}
            onMinimizeClick={toggleMinimize}
            onExpandClick={toggleMinimize}
        >
            <div
                className='dendrogram-selection__container'
                style={{
                    maxHeight: props.infoMaxHeight,
                }}
            >
                {selectedClusters.map(node => (
                    <SelectionInfoContent
                        key={`info:dendrogram:${node[nodeAccessor]}`}
                        data={node.data}
                    />
                ))}
            </div>
        </MinimizeExpandContainer>
    );
};

export default SelectionInfo;

SelectionInfo.propTypes = {
    infoMaxHeight: PropTypes.number,
    isMinimizedSelectionPane: PropTypes.bool,
    nodeAccessor: PropTypes.string,
    selectedClusters: PropTypes.array,
    toggleMinimize: PropTypes.func,
};
