import gql from 'graphql-tag';

// getTSNEData and TSNEName is coupled to API
const query = gql`
        query ScatterPlotPointData($selectedDatasetScatterPlotName: String!) {
            scatterPlotPointData: getTSNEData(TSNEName: $selectedDatasetScatterPlotName)
        }`;

// Get cluster data and scatter-plot points
const getScatterPlotPointDataQuery = (selectedDatasetName, selectedDatasetScatterPlotName) => {
    const variables = { selectedDatasetName, selectedDatasetScatterPlotName };

    return { query, variables };
};

export default getScatterPlotPointDataQuery;
