import React from 'react';
import get from 'lodash/get';
import { ENV_CONSTANTS } from '../../constants';

const PortalHeader = () => {
    const portalUrl = get(ENV_CONSTANTS, 'BRAIN_MAP', null);
    const rnaseqUrl = get(ENV_CONSTANTS, 'RNASEQ', null);

    return (
        <div className='tv-portal-header'>
            <div className='tv-portal-header__branding'>
                <div className='tv-portal-header__branding--ABM'>
                    <a href={portalUrl} target='_blank'>
                        <span>ALLEN BRAIN MAP</span>
                    </a>
                </div>
                <div>
                    <span>Transcriptomics Explorer</span>
                </div>
            </div>
            <div className='tv-portal-header__return-to'>
                <a href={rnaseqUrl} target='_top'>
                    <i className='fas fa-chevron-left tv-portal-header__icon'/> Cell Types Database: RNA-seq
                </a>
            </div>
        </div>
    );
};

export default PortalHeader;
