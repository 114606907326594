import { createSelector } from 'reselect';
import {
    getAvailableDatasets,
    getSelectedDataset,
} from '.';


export const getDatasetOptions = createSelector(
    [getAvailableDatasets],
    availableDatasets => availableDatasets.map(
        dataset => ({ value: dataset, label: dataset.displayName }),
    )
);

export const getSelectedDatasetOption = createSelector(
    [getDatasetOptions, getSelectedDataset],
    (options, selectedDataset) => options.find(option => option.label === selectedDataset.displayName),
);
