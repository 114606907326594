import assign from 'lodash/assign';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import castArray from 'lodash/castArray';
import uniqBy from 'lodash/uniqBy';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';

import { ADD_USER_GENES, DELETE_USER_GENE } from '../actions/gene-search-actions';
import {
    HEATMAP_MOVE_USER_GENE,
} from '../actions/heatmap-actions';
import getFromSession from '../utils/get-from-session-storage';
import setSessionStorage from '../utils/set-session-storage';
import { arrayMove } from '../utils/array-utils';
import { VISUALIZATIONS_MAP } from '../constants';

/**
 * Update Redux state store to add user-selected gene symbols
 *
 * @param {Object} state - current Redux state of geneSymbols
 * @param {Object} action - Redux action
 * @param {Object} action.datasetKey - currently HUMAN or MOUSE
 * @param {Object} action.visualizationKey - currently HEATMAP
 * @param {Object|Object[]} action.userGenes - user's selected gene info, includes gene symbol and entrez gene id
 */
const addUserGenes = (state, action) => {
    // add user's gene info to front/top of existing array and remove duplicates
    const storedGenes = get(state, [action.dataset, action.visualizationKey, 'userGenes'], []);
    const userInputGenes = castArray(action.userGenes);
    const noDups = uniqBy([...userInputGenes, ...storedGenes], 'symbol');

    setSessionStorage(`${action.dataset}:${action.visualizationKey}`, noDups);

    const newState = {
        [action.dataset]: {
            [action.visualizationKey]: {
                userGenes: noDups
            }
        }
    };

    return assign({}, state, newState);
};

const deleteUserGene = (state, action) => {
    const { userGenes } = state[action.dataset][action.visualizationKey];
    const nextUserGenes = filter(userGenes, ({ symbol }) => symbol !== action.geneSymbol);

    setSessionStorage(`${action.dataset}:${action.visualizationKey}`, nextUserGenes);

    const newState = {
        [action.dataset]: {
            [action.visualizationKey]: {
                userGenes: nextUserGenes
            }
        }
    };

    return assign({}, state, newState);
};

function reorderUserGene(state, action) {
    const visualizationKey = VISUALIZATIONS_MAP[action.selectedVisualization];

    const currentGenes = get(state, [action.selectedDataset, visualizationKey, 'userGenes'], []);
    const fromIndex = findIndex(currentGenes, storeGene => storeGene.symbol === action.geneSymbol);
    const toIndex = clamp(fromIndex + action.delta, 0, currentGenes.length - 1);
    const nextUserGenes = arrayMove(currentGenes, fromIndex, toIndex);

    setSessionStorage(`${action.selectedDataset}:${visualizationKey}`, nextUserGenes);

    const newState = {
        [action.selectedDataset]: {
            [visualizationKey]: {
                userGenes: nextUserGenes,
            }
        }
    };

    return assign({}, state, newState);
}


/**
 * creates reducer with given metadata
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createGeneSearchReducer(metadata) {
    const defaultState = metadata.datasets.reduce(
        (acc, dataset) => assign(acc, {
            [dataset.name]: {
                // TODO: STATE REFACTOR, IMPROVE PATTERN
                HEATMAP: { userGenes: getFromSession(`${dataset.name}:HEATMAP`) }
            }
        }),
        {},
    );

    return (state = defaultState, action) => {
        switch (action.type) {
            case ADD_USER_GENES:
                return addUserGenes(state, action);

            case DELETE_USER_GENE:
                return deleteUserGene(state, action);

            case HEATMAP_MOVE_USER_GENE:
                return reorderUserGene(state, action);

            default:
                return state;
        }
    };
}
