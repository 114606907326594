import React from 'react';
import PropTypes from 'prop-types';
import {
    TAXONOMY_COLOR_ACCESSOR,
    TAXONOMY_DESIGNATION_ACCESSOR,
    TAXONOMY_LABEL_ACCESSOR,
    TAXONOMY_CELL_TYPE_ALIAS,
    TAXONOMY_CELL_TYPE_LABEL
} from '../../constants';

const SelectionInfoContent = ({ data }) => {
    const alias = data[TAXONOMY_LABEL_ACCESSOR];
    const label = data[TAXONOMY_DESIGNATION_ACCESSOR];

    return (
        <div
            className='dendrogram__selected-cluster-container__base'
            style={{ borderLeft: `${data[TAXONOMY_COLOR_ACCESSOR]} 4px solid` }}
        >
            {alias && (
                <div
                    className='dendrogram__selected-cluster-field__base'
                    title={TAXONOMY_CELL_TYPE_ALIAS}
                >
                    {alias}
                </div>
            )}
            {label && (
                <div
                    className='dendrogram__selected-cluster-field__base'
                    title={TAXONOMY_CELL_TYPE_LABEL}
                >
                    {label}
                </div>
            )}
        </div>
    );
};

SelectionInfoContent.propTypes = {
    data: PropTypes.object.isRequired,
};

export default React.memo(SelectionInfoContent);
