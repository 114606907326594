import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// style
import './style.scss';

// constants
import { FETCH_STATE } from '../../constants';

// actions
import { fetchData } from '../../actions';
import {
    HEATMAP_FETCH_MARKER_GENES,
    HEATMAP_RECEIVE_MARKER_GENES,
    HEATMAP_ERROR_MARKER_GENES,
    HEATMAP_FETCH_MARKER_GENE_MATRIX,
    HEATMAP_RECEIVE_MARKER_GENE_MATRIX,
    HEATMAP_ERROR_MARKER_GENE_MATRIX,
    HEATMAP_FETCH_USER_GENE_MATRIX,
    HEATMAP_RECEIVE_USER_GENE_MATRIX,
    HEATMAP_ERROR_USER_GENE_MATRIX,
    HEATMAP_FETCH_FEATURE_SET_RANGE,
    HEATMAP_RECEIVE_FEATURE_SET_RANGE,
    HEATMAP_ERROR_FEATURE_SET_RANGE,
} from '../../actions/heatmap-actions';

// selectors
import {
    getMarkerGenesFetchState,
    getFeatureSetMatrix,
    getRowLabels,
    getMarkerGeneMatrixFetchState,
    getAllMarkerGenesByDataset,
    getUserGeneSymbolsToFetch,
    getUserGeneMatrixFetchState,
    getFeatureSetRangeFetchState,
} from '../../selectors/heatmap-selectors';
import {
    getSelectedDataset,
    getSelectedDatasetName,
} from '../../selectors';
import { getLeafLabels } from '../../selectors/taxonomy-selectors';

// queries
import getMarkerGeneListQuery from '../../queries/get-marker-genes-query';
import getFeatureSetQuery from '../../queries/get-feature-set-query';
import getFeatureSetRangeQuery from '../../queries/get-feature-set-range-query';

// hooks and utils
import useWindowWidth from '../../utils/use-window-width';
import useWindowHeight from '../../utils/use-window-height';

// components
import HeatmapTableContainer from './heatmap-table-container';
import { Loader } from '../../components/indicators';

const fetchMarkerGeneList = (dispatch, selectedDataset) => {
    const graphqlQuery = getMarkerGeneListQuery(selectedDataset);
    const metadata = { selectedDataset };
    dispatch(fetchData(
        graphqlQuery,
        HEATMAP_FETCH_MARKER_GENES,
        HEATMAP_RECEIVE_MARKER_GENES,
        HEATMAP_ERROR_MARKER_GENES,
        metadata,
    ));
};

const fetchFeatureSetRange = (dispatch, selectedDatasetObject) => {
    const graphqlQuery = getFeatureSetRangeQuery(selectedDatasetObject);
    const metadata = { selectedDataset: selectedDatasetObject.name };
    dispatch(fetchData(
        graphqlQuery,
        HEATMAP_FETCH_FEATURE_SET_RANGE,
        HEATMAP_RECEIVE_FEATURE_SET_RANGE,
        HEATMAP_ERROR_FEATURE_SET_RANGE,
        metadata,
    ));
};

const fetchMarkerGeneMatrix = (dispatch, selectedDatasetObject, features, leafClusterLabels) => {
    const graphqlQuery = getFeatureSetQuery(features, leafClusterLabels, selectedDatasetObject);
    const metadata = { selectedDataset: selectedDatasetObject };
    dispatch(fetchData(
        graphqlQuery,
        HEATMAP_FETCH_MARKER_GENE_MATRIX,
        HEATMAP_RECEIVE_MARKER_GENE_MATRIX,
        HEATMAP_ERROR_MARKER_GENE_MATRIX,
        metadata,
    ));
};

const fetchUserGeneMatrix = (dispatch, selectedDatasetObject, features, leafClusterLabels) => {
    const graphqlQuery = getFeatureSetQuery(features, leafClusterLabels, selectedDatasetObject);
    const metadata = { selectedDataset: selectedDatasetObject, features };
    dispatch(fetchData(
        graphqlQuery,
        HEATMAP_FETCH_USER_GENE_MATRIX,
        HEATMAP_RECEIVE_USER_GENE_MATRIX,
        HEATMAP_ERROR_USER_GENE_MATRIX,
        metadata,
    ));
};

const HeatmapContainer = () => {
    const selectedDatasetObject = useSelector(getSelectedDataset);
    const selectedDataset = useSelector(getSelectedDatasetName);
    const markerGenesFetchState = useSelector(getMarkerGenesFetchState);
    const featureSetRangeFetchState = useSelector(getFeatureSetRangeFetchState);
    const leafClusterLabelsFetchState = useSelector(state => state.fetch.taxonomyFetchState);
    const markerGeneMatrixFetchState = useSelector(getMarkerGeneMatrixFetchState);
    const userGeneMatrixFetchState = useSelector(getUserGeneMatrixFetchState);
    const leafClusterLabels = useSelector(getLeafLabels);
    const featureSetMatrix = useSelector(getFeatureSetMatrix);
    const allMarkerGenes = useSelector(getAllMarkerGenesByDataset);
    const userGenesToFetch = useSelector(getUserGeneSymbolsToFetch);
    const rowLabels = useSelector(getRowLabels);
    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const dispatch = useDispatch();
    const heatmapContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const heatmapTop = heatmapContainerRef.current && heatmapContainerRef.current.getBoundingClientRect().top;
    const markerGenesFetchStateComplete = markerGenesFetchState === FETCH_STATE.COMPLETE;
    const okToFetchMarkerGenes = isEmpty(allMarkerGenes) && markerGenesFetchState === FETCH_STATE.INIT;
    const okToFetchFeatureSetRange = featureSetRangeFetchState === FETCH_STATE.INIT;
    const featureSetRangeFetchStateComplete = featureSetRangeFetchState === FETCH_STATE.COMPLETE;
    const leafClusterLabelsFetchStateComplete = leafClusterLabelsFetchState === FETCH_STATE.COMPLETE;
    const okToFetchMarkerGeneMatrix = featureSetRangeFetchStateComplete && markerGenesFetchStateComplete && leafClusterLabelsFetchStateComplete && markerGeneMatrixFetchState === FETCH_STATE.INIT;
    const okToFetchUserGeneMatrix = !isEmpty(userGenesToFetch) && featureSetRangeFetchStateComplete && leafClusterLabelsFetchStateComplete && userGeneMatrixFetchState === FETCH_STATE.INIT;
    const fetchingMatrixData = markerGeneMatrixFetchState === FETCH_STATE.FETCHING || userGeneMatrixFetchState === FETCH_STATE.FETCHING;
    const showLoader = useMemo(() => isLoading || fetchingMatrixData, [isLoading, fetchingMatrixData]);
    const onHeatmapLoad = useCallback(isLoading => setIsLoading(isLoading), []);

    useEffect(() => {setIsLoading(true);}, [selectedDataset]);

    useEffect(
        () => {if (okToFetchMarkerGenes) fetchMarkerGeneList(dispatch, selectedDataset, markerGenesFetchState);},
        [dispatch, selectedDataset, markerGenesFetchState, okToFetchMarkerGenes]
    );
    useEffect(
        () => {if (okToFetchFeatureSetRange) fetchFeatureSetRange(dispatch, selectedDatasetObject);},
        [dispatch, selectedDatasetObject, okToFetchFeatureSetRange]
    );
    useEffect(
        () => {if (okToFetchMarkerGeneMatrix) fetchMarkerGeneMatrix(dispatch, selectedDatasetObject, allMarkerGenes, leafClusterLabels);},
        [dispatch, selectedDatasetObject, allMarkerGenes, leafClusterLabels, okToFetchMarkerGeneMatrix]
    );
    useEffect(
        () => {if (okToFetchUserGeneMatrix) fetchUserGeneMatrix(dispatch, selectedDatasetObject, userGenesToFetch, leafClusterLabels);},
        [dispatch, selectedDatasetObject, userGenesToFetch, leafClusterLabels, okToFetchUserGeneMatrix]
    );

    return (
        <div className='heatmap__container' ref={heatmapContainerRef}>
            {showLoader && <Loader/>}
            <HeatmapTableContainer
                height={windowHeight - heatmapTop}
                isLoading={isLoading}
                matrix={featureSetMatrix}
                onLoad={onHeatmapLoad}
                rowLabels={rowLabels}
                width={windowWidth}
            />
        </div>
    );
};

export default HeatmapContainer;
