import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { GENE_SEARCH_API_MAX_LIMIT } from '../constants';

const query = gql`query GeneValidation(
    $dataset: String!
    $features: [String]!
    $limit: Int
) {
    geneArray: getFeaturesInDataSet(
        DataSet: $dataset
        features: $features
        limit: $limit
    ) {
        symbol
        entrezId
    }
}`;

/**
 * Construct gene symbol validation query
 *
 * @param {string} features
 * @param {string} dataset - name of selected dataset
 * @param {number} limit - max number of search results
 * @returns {Object} GraphQL tag object made up of 'query' and 'variables' properties, or null if features is empty
 */
const getGeneSymbolValidationQuery = (
    features,
    dataset,
    limit = GENE_SEARCH_API_MAX_LIMIT,
) => {
    if (isEmpty(features) || isEmpty(dataset)) {
        return null;
    }

    const variables = {
        dataset,
        features,
        limit,
    };

    return { query, variables };
};

export default getGeneSymbolValidationQuery;
