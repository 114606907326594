import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { scaleQuantile } from 'd3-scale';
import { range } from 'd3-array';

import {
    CONSECUTIVE_NUMBER_REGEX,
    HEX_RGB_REGEX,
} from '../constants/regex';

// store previously computed values.
const colorCache = {};
const BLACK_HEX = '#000000';
const BIN_QUANTITY = 100;
const HEX_LENGTH = 7;

export function hexToNumericalRgb(hex) {
    if (!hex || hex.length !== HEX_LENGTH) {
        throw new Error('Hex conversion only works with non-abbreviated full hexadecimal value.');
    }

    if (!colorCache[hex]) {
        const result = HEX_RGB_REGEX.exec(hex);

        colorCache[hex] = result && [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
        ];
    }

    return colorCache[hex];
}

export function getNumberArrayFromRgb(rgbColor) {
    if (!colorCache[rgbColor]) {
        const [r, g, b, a] = rgbColor.match(CONSECUTIVE_NUMBER_REGEX);

        // opacity is from 0-1 in rgb, but deck.gl works with a scale from 0-255 (when not using binary data).
        // if opacity exists, multiply it by 255 (0 value will remain 0).
        const opacity = a && +a * 255;

        // If opacity is undefined, return an array of length 3 rather than 4.
        colorCache[rgbColor] = isNil(opacity) ? [+r, +g, +b] : [+r, +g, +b, opacity];
    }

    return colorCache[rgbColor];
}

// Used by scatterplot: color by attribute
export const getColorsFromAttribute = (
    lookup,
    data,
    attribute = 'color',
    normalizedValue = 255,
) => {
    const length = get(data, 'length', 0);
    const colors = new Float32Array(length * 3);

    if (!lookup || !length) {
        colors.fill(0);
    } else {
        for (let i = 0; i < length; i++) {
            // defensive lookup is faster than lodash.get
            const stringColor = (lookup[data[i]] && lookup[data[i]][attribute]) || BLACK_HEX;
            const color = hexToNumericalRgb(stringColor);

            colors[i * 3] = color[0] / normalizedValue;
            colors[i * 3 + 1] = color[1] / normalizedValue;
            colors[i * 3 + 2] = color[2] / normalizedValue;
        }
    }

    return colors;
};

// Used by scatterplot: color by value
// evaluates a number array into binned colors.
export function getBinnedContinuousColorFunction(
    colorScale,
    [min, max],
    data,
    lookup,
    normalizeValue = 255,
) {
    const length = get(data, 'length', 0);
    const colorValues = new Float32Array(length * 3);

    if (min === max || !lookup) {
        colorValues.fill(0);
    } else {
        const preComputedColors = getBinnedColors(colorScale);

        // Create a quantile number scale with the desired bin quantity.
        // Adapted from [cellxgene](https://github.com/chanzuckerberg/cellxgene/blob/master/client/src/util/stateManager/colorHelpers.js#L95-L98)
        // domain is extent of input data
        // NOTE: range is an int array [n-1, n-2, ..., 0] because we expect the color scale to be inverted.
        //       range(100 - 1, -1, -1) === range(0, 100, 1).reverse();
        const numberScale = scaleQuantile()
            .domain([min, max])
            .range(range(BIN_QUANTITY - 1, -1, -1));

        for (let i = 0; i < length; i++) {
            // defensive lookup is faster than lodash.get
            const value = lookup.expressionData[data[i]] || lookup.expressionMin;
            const color = preComputedColors[numberScale(value)];

            colorValues[i * 3] = color[0] / normalizeValue;
            colorValues[i * 3 + 1] = color[1] / normalizeValue;
            colorValues[i * 3 + 2] = color[2] / normalizeValue;
        }
    }

    return colorValues;
}

// computes each color of a binned scale.
export function getBinnedColors(
    colorScale,
    processFn = getNumberArrayFromRgb,
    binQuantity = BIN_QUANTITY
) {
    const colors = new Array(binQuantity);

    for (let i = 0; i < BIN_QUANTITY; i += 1) {
        colors[i] = processFn(colorScale(i / BIN_QUANTITY));
    }

    return colors;
}
