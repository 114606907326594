import React from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';

import Menu from './menu';
import OptionIcon from './option-icon';

//UTILS
import getClassName from '../../utils/get-class-name';

import './style.scss';

const listItemClassNameMap = {
    isHeader: 'row-header__category-header',
    isHovered: 'row-header__list-item__hovered',
    isInteractive: 'row-header__interactive',
    isStatic: 'row-header__static',
};

const getListItemClassnames = (listAttributes) => {
    // filter out any classnames, besides 'baseClass' that are not in listAttributes.
    const classNames = filter(listItemClassNameMap, (_, property) => listAttributes[property]);

    return classNames.join(' ');
};

const getAdjustedRowHeight = (rowHeight, isStaticHeader) => {
    // In order to complete the border-bottom on sampling strategy category header
    // we have to account for 1px margin, and 1px border.
    const marginHeight = isStaticHeader ? 1 : 0;
    const borderHeight = isStaticHeader ? 1 : 0;
    return rowHeight - (marginHeight + borderHeight);
};

const RowLabels = React.forwardRef((props, ref) => {
    const {
        className,
        headerHeight,
        height,
        hoveredLabel,
        labelMenuData,
        labelMenuOptionsMap,
        onInfoLeave,
        onInfoOver,
        onLabelClick,
        onLabelHover,
        onLabelLeave,
        rowHeight,
        rowLabels,
        tooltipContent,
        width,
    } = props;
    const showInfo = !!tooltipContent;

    return (
        <div className={className}>
            {labelMenuData && <Menu labelMenuData={labelMenuData} />}
            <div
                className='labels--left'
                ref={ref}
                style={{
                    width,
                    height: height - headerHeight,
                    marginTop: headerHeight,
                }}
            >
                <ul className='row-header__list'>
                    {rowLabels && rowLabels.map((label, i) => {
                        // predicates
                        const isStaticHeader = label.isHeader && !onLabelClick;
                        const isHovered = label.text === hoveredLabel;
                        const isInteractive = !!onLabelClick;
                        const isStatic = !isInteractive;
                        const shouldRenderActionIcons = !labelMenuData && isHovered;
                        const shouldRenderInfoIcon = label.isHeader && showInfo;
                        // calculated attributes
                        const adjustedRowHeight = getAdjustedRowHeight(rowHeight, isStaticHeader);
                        const listItemClassNames = getListItemClassnames({ isHeader: label.isHeader, isHovered, isInteractive, isStatic });

                        return (
                            <li
                                className={getClassName('row-header__list-item', listItemClassNames)}
                                key={`row-label:${label.text}:${i}`}
                                onClick={onLabelClick}
                                onMouseLeave={onLabelClick && onLabelLeave}
                                onMouseMove={onLabelClick && onLabelHover}
                                onMouseOver={onLabelClick && onLabelHover}
                                style={{ height: adjustedRowHeight }}
                            >
                                {shouldRenderActionIcons && (
                                    <span
                                        className='row-header__action-icons'
                                        style={{ height: adjustedRowHeight }}
                                    >
                                        {labelMenuOptionsMap[label.data.type].map(option => (
                                            <OptionIcon
                                                key={option.iconClassname}
                                                labelText={label.text}
                                                onClickDefault={onLabelClick}
                                                option={option}
                                            />
                                        ))}
                                    </span>
                                )}
                                <span
                                    className='row-label__feature-text'
                                    onClick={onLabelClick}
                                >
                                    {label.text}
                                </span>
                                {shouldRenderInfoIcon && (
                                    <i
                                        className='fas fa-info-circle'
                                        onMouseLeave={onInfoLeave}
                                        onMouseOver={onInfoOver}
                                    />
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
});

export default RowLabels;

RowLabels.propTypes = {
    // class name of the outer most div
    className: PropTypes.string,

    // width of the outer most div
    width: PropTypes.number,

    // height of the outer most div
    height: PropTypes.number,

    // height of the header above the labels
    headerHeight: PropTypes.number,

    // hovered row label
    hoveredLabel: PropTypes.string,

    // Menu map for action options of a label
    labelMenuOptionsMap: PropTypes.object,

    onInfoLeave: PropTypes.func,

    onInfoOver: PropTypes.func,

    onLabelLeave: PropTypes.func,

    onLabelHover: PropTypes.func,

    // array of row labels, some of which are headers for a group of labels
    rowLabels: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        isHeader: PropTypes.bool,
    })),

    // height of each row
    rowHeight: PropTypes.number,

    // click handler for label
    onLabelClick: PropTypes.func,

    // data to be used in label menu
    labelMenuData: PropTypes.object,

    // content to pass to tooltip
    tooltipContent: PropTypes.object,
};
