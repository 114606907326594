import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isNull from 'lodash/isNull';

import Drawer from '../drawer';
import { ENV_CONSTANTS } from '../../constants';
import useWindowHeight from '../../utils/use-window-height';

/**
 * Simple header content component that provides a link to the given dataset's download page.
 */
const headerDownloadButton = (dataset) => {
    const currentURL = get(dataset, ['downloadPage'], null);

    if (isNull(currentURL)) { return <div />; }

    return (
        <a href={currentURL} target='_none'>Download Data</a>
    );
};

/**
 * Simple header content component
 */
const headerRightContent = (dataset) => {
    const whitepaperUrl = get(dataset, ['protocolsUrl'], null);
    const helpUrl = get(ENV_CONSTANTS, 'HELP', null);

    const whitePaperLink = whitepaperUrl && <a href={whitepaperUrl} target='_blank'>Technical Whitepaper</a>;
    const splitter = whitePaperLink && helpUrl && <span className='application__link-splitter' />;
    const helpLink = helpUrl && <a href={helpUrl} target='_blank'>Help</a>;

    return (
        <>
            {whitePaperLink}
            {splitter}
            {helpLink}
        </>
    );
};

/**
 * Application Footer
 */
const ApplicationFooter = (props) => {
    const { dataset } = props;

    const DEFAULT_IFRAME_HEIGHT = 442;
    const downloadButton = headerDownloadButton(dataset);
    const whitePaperAndHelp = headerRightContent(dataset);
    const windowHeight = useWindowHeight();
    const iframeHeight = Math.min(windowHeight, DEFAULT_IFRAME_HEIGHT);

    return (
        <div className='application__footer'>
            <Drawer headerLeftContent={downloadButton} headerRightContent={whitePaperAndHelp}>
                <iframe src={ENV_CONSTANTS.AIBS_FOOTER} height={iframeHeight} width={'100%'} />
            </Drawer>
        </div>
    );
};

ApplicationFooter.propTypes = {
    history: PropTypes.object,
    dataset: PropTypes.object,
};

export default ApplicationFooter;
