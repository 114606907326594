import assign from 'lodash/assign';

import {
    HIDE_TOOLTIP,
    UPDATE_TOOLTIP,
} from '../actions/tooltip-actions';

const initialState = {
    isOpen: false,
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 *
 * @returns {function} (state: object, action: object) => object
 */
export default function createTooltipReducer() {
    return (state = initialState, action) => {
        switch (action.type) {
            case HIDE_TOOLTIP:
                return assign({}, state, { isOpen: false });
            case UPDATE_TOOLTIP:
                return assign({}, state, { isOpen: true, config: action.config });
            default:
                return state;
        }
    };
}
