import gql from 'graphql-tag';

export const getSpecimenAggregateQueryBody = ({ alias, dataset, groupBy, sortBy }) => `
    ${alias}: specimenAggregate (
        sort: [
            {
                field: "${sortBy}"
                order: ASC
            }
        ]
        filter: [{
            field: "dataSet.name"
            operator: EQ
            value: "${dataset}"
        }]
        aggregationOperation: {
            operator: SUM,
            field: "subspecimenCount"
        }
        groupBy: ${JSON.stringify(groupBy)}
    ) {
        count
        properties {
            property
            value
        }
    }
`;

/**
 * Builds a query with aliases for each set of variables to provide to specimenAggregate.
 * @param {Object[]} queryParamsList - array of variables to use in the query. { alias: String, dataset: String, groupBy: String[], sortBy: String }
 */
const getSpecimenAggregateQuery = (queryParamsList) => {
    const query = gql`
        query {
            ${queryParamsList.map(queryParams => getSpecimenAggregateQueryBody(queryParams))}
        }`;

    return { query };
};

export default getSpecimenAggregateQuery;
