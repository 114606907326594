// MODIFIED VERSION OF https://github.com/visgl/deck.gl/blob/master/modules/layers/src/scatterplot-layer/scatterplot-layer-vertex.glsl.js
import glsl from 'glslify';

export default glsl`\
#define SHADER_NAME selection-optimized-scatterplot-layer-vertex-shader

// ///////////////////////////////////////////
// Appended top level declarations for selection optimized shader
// ////////////////////////////////////
attribute float instanceIsSelected;

uniform float selectionExists;
uniform float alphaBase;
uniform float defaultAlphaIncrease;
uniform float selectedAlphaIncrease;
uniform float defaultRadius;
uniform float selectedRadiusIncrease;

// ///////////////////////////////////////////
// Original top level declarations from deck.gl
// /////////////////////////////////
attribute vec3 positions;
attribute vec3 instancePositions;
attribute vec3 instancePositions64Low;
attribute float instanceRadius;
attribute float instanceLineWidths;
attribute vec4 instanceFillColors;
attribute vec4 instanceLineColors;
attribute vec3 instancePickingColors;

uniform float opacity;
uniform float radiusScale;
uniform float radiusMinPixels;
uniform float radiusMaxPixels;
uniform float lineWidthScale;
uniform float lineWidthMinPixels;
uniform float lineWidthMaxPixels;
uniform float stroked;
uniform bool filled;

varying vec4 vFillColor;
varying vec4 vLineColor;
varying vec2 unitPosition;
varying float innerUnitRadius;
varying float outerRadiusPixels;

void main(void) {
  // ///////////////////////////////////////////
  // Appended logic for selection optimized shader
  // ////////////////////////////////

  // modes for an instance:
  // 1) nothing-selected
  // 2) something is selected but instance-not-selected
  // 3) instance is selected (incoming attribute)
  float nothingIsSelected = 1.0 - (selectionExists * 1.0);
  float instanceIsNotSelected = selectionExists - (instanceIsSelected * 1.0);

  // Alpha:
  //   If nothing is selected: alphaBase + defaultAlphaIncrease = ~0.5
  //   If instance is selected: alphaBase + selectedAlphaIncrease = ~0.8
  //   If instance is not selected: alphaBase = ~0.1
  float fillAlpha = alphaBase + (nothingIsSelected * defaultAlphaIncrease) + (instanceIsSelected * selectedAlphaIncrease);

  // Radius:
  //    If instance is selected: defaultRadius + selectedRadiusIncrease = ~3.0
  //    Otherwise: defaultRadius = ~1.0
  float modifiedInstanceRadius = defaultRadius + (instanceIsSelected * selectedRadiusIncrease);

  // Line Color:
  //   If instance is selected: use black
  //   If the instance is not selected, regardless of other selections: use fill color
  vec3 modifiedInstanceLineColors = step(1.0, nothingIsSelected + instanceIsNotSelected) * instanceFillColors.rgb;

  // ///////////////////////////////////////////
  // Original main function from deck.gl
  // ////////////////////////////////
  geometry.worldPosition = instancePositions;

  // Multiply out radius and clamp to limits
  outerRadiusPixels = clamp(
    project_size_to_pixel(radiusScale * modifiedInstanceRadius),
    radiusMinPixels, radiusMaxPixels
  );

  // Multiply out line width and clamp to limits
  float lineWidthPixels = clamp(
    project_size_to_pixel(lineWidthScale * instanceLineWidths),
    lineWidthMinPixels, lineWidthMaxPixels
  );

  // outer radius needs to offset by half stroke width
  outerRadiusPixels += stroked * lineWidthPixels / 2.0;

  // position on the containing square in [-1, 1] space
  unitPosition = positions.xy;
  geometry.uv = unitPosition;
  geometry.pickingColor = instancePickingColors;

  innerUnitRadius = 1.0 - stroked * lineWidthPixels / outerRadiusPixels;

  vec3 offset = positions * project_pixel_size(outerRadiusPixels);
  DECKGL_FILTER_SIZE(offset, geometry);
  gl_Position = project_position_to_clipspace(instancePositions, instancePositions64Low, offset, geometry.position);
  DECKGL_FILTER_GL_POSITION(gl_Position, geometry);

  // ///////////////////////////////////////////
  // vFillColor, vLineColor have been modified for selection optimization
  // ////////////////////////////

  // Apply opacity to instance color, or return instance picking color
  vFillColor = vec4(instanceFillColors.rgb, fillAlpha * opacity);
  DECKGL_FILTER_COLOR(vFillColor, geometry);
  vLineColor = vec4(modifiedInstanceLineColors.rgb, instanceIsSelected * opacity);
  DECKGL_FILTER_COLOR(vLineColor, geometry);
}
`;
