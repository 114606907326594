import TagManager from 'react-gtm-module';
import castArray from 'lodash/castArray';
import { ENV_CONSTANTS, GOOGLE_TAG_MANAGER_ID } from '../constants';

/**
 * Add custom event, 'Add user genes' to Google Tag Manager's DataLayer
 *
 * @param {string} dataset
 * @param {string} visualizationKey - 'HEATMAP'
 * @param {Object|Object[]} userGenes, elements have {symbol:<string>, entrezId:<number>}
 */
const eventAddUserGenes = (dataset, visualizationKey, userGenes) => {
    // build comma-delimited string of gene symbols
    const geneSymbols = castArray(userGenes).map(elem => elem.symbol).join(',');

    const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_ID,
        auth: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_AUTH,
        preview: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_ENVIRONMENT,
        dataLayer: {
            event: 'Add user genes',
            dataset,
            visualization: visualizationKey,
            geneSymbols
        }
    };

    TagManager.initialize(tagManagerArgs);
};

export default eventAddUserGenes;
