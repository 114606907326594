import PropTypes from 'prop-types';
import React from 'react';

/**
 * If a windowed translate wrapper is not meant to scroll itself, rather its scroll is controlled via the given ref,
 * this component will be its container. An example use case is the dendrogram following the content-grid.
 *
 * NOTE: In the case highlighted above, why not allow both the dendrogram and content-grid to scroll each other?
 * While having both containers have the ability to scroll each other is possible, I could not implement in a way that was performant
 * on all browsers. I found that scrolling one container would make the other "jitter". In the future we may be able to correct this,
 * however, we may be moving away from scrolling navigation altogether, making this feature moot.
 *
 * @param {object} props
 * @param {object} ref
 */
const FollowerWrapper = ({ children, style }, ref) => (
    <div
        className='follower-wrapper'
        style={style}
        ref={ref}
    >
        {children}
    </div>
);

export default React.forwardRef(FollowerWrapper);

FollowerWrapper.propTypes = {
    //react children
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    //css style
    style: PropTypes.object,
};
