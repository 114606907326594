import { GENERIC_FETCH_ERROR } from './errors-actions';

export const DENDROGRAM_FETCH_TAXONOMY = 'DENDROGRAM_FETCH_TAXONOMY';
export const DENDROGRAM_RECEIVE_TAXONOMY = 'DENDROGRAM_RECEIVE_TAXONOMY';
export const DENDROGRAM_FETCH_ERROR_TAXONOMY = GENERIC_FETCH_ERROR;
export const DENDROGRAM_DIMENSION_UPDATE = 'DENDROGRAM_CHART_DIMENSIONS';
export const DENDROGRAM_TOGGLE_SELECTION_PANE_MINIMIZE = 'DENDROGRAM_TOGGLE_SELECTION_PANE_MINIMIZE';

export const dendrogramDimensionUpdate = dimensions => ({
    type: DENDROGRAM_DIMENSION_UPDATE,
    dimensions,
});

export const toggleIsMinimizedSelectionPane = selectedDataset => ({
    type: DENDROGRAM_TOGGLE_SELECTION_PANE_MINIMIZE,
    selectedDataset,
});
