import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// CONSTANTS
import { COLOR_BY_OPTIONS } from '../../constants';

// COMPONENTS
import DropdownSelect from '../dropdown-select';
import GeneSearchDropdownContainer from '../../containers/gene-search/gene-search-dropdown-container';

const SHOW_GENE_SELECT_CLASS = 'gene-select';

const COLOR_BY_FEATURE_OPTIONS = [
    { value: COLOR_BY_OPTIONS.CELL_TYPE, label: COLOR_BY_OPTIONS.CELL_TYPE },
    { value: COLOR_BY_OPTIONS.GENE_EXPRESSION, label: COLOR_BY_OPTIONS.GENE_EXPRESSION }
];

/**
 * A dropdown that provides options for coloring the scatter-plot by cluster or expression.
 */
const ColorBy = (props) => {
    const {
        colorByFeature,
        colorByFeatureSelection,
        colorByHandler,
        colorBySelectValue,
        defaultFeatureValueOptions,
        selectGeneHandler,
    } = props;
    // Refs
    const geneSelectRef = useRef(null);

    // Fields
    const geneSelectShow = colorByFeature === COLOR_BY_OPTIONS.GENE_EXPRESSION;

    useEffect(() => {
        if (geneSelectShow) {
            geneSelectRef.current.focus();
        }
    }, [geneSelectShow]);

    return (
        <div className='color-by-container'>
            <div className='color-by'>
                <span className='color-by-label'>Color By:</span> <DropdownSelect
                    options={COLOR_BY_FEATURE_OPTIONS}
                    value={colorByFeatureSelection}
                    onChangeHandler={colorByHandler} />
            </div>
            {geneSelectShow && <div className={SHOW_GENE_SELECT_CLASS}>
                <span className='color-by-label'>Gene: {colorBySelectValue.value}</span> <GeneSearchDropdownContainer
                    className='gene-select-dropdown'
                    isClearable
                    placeholder={'Enter a gene symbol'}
                    defaultOptions={defaultFeatureValueOptions}
                    onChangeHandler={selectGeneHandler}
                    ref={geneSelectRef}
                />
            </div>}
        </div>
    );
};

export default ColorBy;

ColorBy.propTypes = {
    colorByFeature: PropTypes.string,
    colorByFeatureSelection: PropTypes.object,
    colorByHandler: PropTypes.func,
    colorBySelectValue: PropTypes.object,
    defaultFeatureValueOptions: PropTypes.array,
    selectGeneHandler: PropTypes.func,
};
