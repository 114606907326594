import React from 'react';
import { useSelector } from 'react-redux';

import ErrorReport from '../../components/error-report';

export default function ErrorReportContainer() {
    const errors = useSelector(state => state.errors);

    return (
        <ErrorReport
            errors={errors}
        />
    );
}
