import assign from 'lodash/assign';

import { GRID_ROW_HOVER } from '../actions/grid-actions';

const initialState = {
    hoveredRow: null,
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 *
 * @returns {function} (state: object, action: object) => object
 */
export default function createGridReducer() {
    return (state = initialState, action) => {
        switch (action.type) {
            case GRID_ROW_HOVER:
                return assign({}, state, { hoveredRow: action.rowLabel });

            default:
                return state;
        }
    };
}
