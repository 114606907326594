import assign from 'lodash/assign';

import { RECEIVE_CLUSTER_COUNTS } from '../actions/fetch-actions';
import { COLUMN_PROPERTY } from '../constants';

const receiveClusterCountsReducer = (state, action) => {
    const {
        data: { clusterCounts },
        metadata: { selectedDataset },
    } = action;

    const clusterLabelToCountMap = clusterCounts.reduce((acc, { count, properties }) => {
        const { value } = properties.find(({ property }) => property === COLUMN_PROPERTY);
        acc[value] = count;

        return acc;
    }, {});

    return assign({}, state,
        {
            [selectedDataset]: clusterLabelToCountMap,
        },
    );
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 * Dataset specific store items are created lazily when set into state
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createClusterCountsReducer() {
    return (state = {}, action) => {
        switch (action.type) {
            case RECEIVE_CLUSTER_COUNTS:
                return receiveClusterCountsReducer(state, action);

            default:
                return state;
        }
    };
}
