/**
 * Move an item of an array to another index
 *
 * @param {*[]} arr -- array to move an item for
 * @param {Number} from -- index of array to move from
 * @param {Number} to -- index or array to move to
 */
export function arrayMove(arr, from, to) {
    // copy
    const newArr = arr.slice();

    if (newArr.length < 1 || from > newArr.length) {
        return newArr;
    }

    // extract
    const [element] = newArr.splice(from, 1);
    // insert
    newArr.splice(to, 0, element);

    return newArr;
}

export default {
    arrayMove,
};
