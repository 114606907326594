// fetch polyfill
import 'whatwg-fetch';
// CustomEvent polyfill (ie11)
import 'custom-event-polyfill';
// childNode.remove polyfill (ie11)
import './utils/child-node-remove-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import reportPageView from './analytics';
import Routes from './routes';
import createStoreWithMetadata, { fetchStoreMetadata } from './store';

import { APPLICATION_VERSION } from './constants';
import ErrorBoundary from './components/error-boundary';
import './styles/index.scss';

// Report that a user has loaded the page.
reportPageView(window.location.pathname + window.location.search);

const App = () => {
    // eslint-disable-next-line no-console
    setTimeout(console.log.bind(console, `Transcriptomics Explorer ${APPLICATION_VERSION}`));

    return (
        <ErrorBoundary>
            <Router>
                <Routes />
            </Router>
        </ErrorBoundary>
    );
};

fetchStoreMetadata()
    .then((metadata) => {
        const store = createStoreWithMetadata(metadata);

        // Render app with redux store
        ReactDOM.render(
            <Provider store={store}>
                <App/>
            </Provider>,
            document.getElementById('root')
        );
    })
    .catch((e) => {
        // Render app with error page
        ReactDOM.render(
            <ErrorBoundary error={e} />,
            document.getElementById('root')
        );
    });
