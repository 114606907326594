import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Application from '../../components/application';
import { browserIncompatibilityErrorAction } from '../../actions/errors-actions';
import { vertexArrayObjectIsSupported } from '../../utils/browser-utils';
import getLocalStorage from '../../utils/get-from-local-storage';
import { getMsDifferenceInDays } from '../../utils/time-utils';
import postTopWindow from '../../utils/post-top-window-dataset-change';
import {
    VOA_MISSING_MESSAGE,
    BROWSER_INCOMPATIBILITY_WARNING_GIVEN_DATE_KEY,
    WARNING_DURATION_DAYS,
} from '../../constants';
import {
    getSelectedVisualization,
    getSelectedDataset,
} from '../../selectors';
import { getColorByFeature, getColorByFeatureValue } from '../../selectors/color-by-selectors';

const ApplicationContainer = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const selectedVisualization = useSelector(getSelectedVisualization);
    const colorByFeature = useSelector(getColorByFeature);
    const colorByFeatureValue = useSelector(getColorByFeatureValue);
    // Dataset is initialized in state on store creation via URL path
    const dataset = useSelector(getSelectedDataset);

    useEffect(() => {
        // check if we should warn user their browser is incompatible
        const currentTime = Date.now();
        const priorWarningTime = getLocalStorage(BROWSER_INCOMPATIBILITY_WARNING_GIVEN_DATE_KEY);
        const warningExpired = !!priorWarningTime && getMsDifferenceInDays(priorWarningTime, currentTime) > WARNING_DURATION_DAYS;
        const shouldCheckBrowser = !priorWarningTime || warningExpired;

        if (shouldCheckBrowser && !vertexArrayObjectIsSupported()) {
            dispatch(browserIncompatibilityErrorAction(new Error(VOA_MISSING_MESSAGE), currentTime));
        }
    }, [dispatch]);

    useEffect(() => {
        const params = new URLSearchParams();
        if (selectedVisualization) {
            params.append('selectedVisualization', selectedVisualization);
        }

        if (colorByFeature) {
            params.append('colorByFeature', colorByFeature);
        }

        if (colorByFeatureValue) {
            params.append('colorByFeatureValue', colorByFeatureValue);
        }

        const updatedParams = { search: params.toString() };
        history.push(updatedParams);
        postTopWindow(updatedParams);
    }, [selectedVisualization, colorByFeature, colorByFeatureValue, history]);

    return (
        <Application history={history} dataset={dataset} />
    );
};

export default ApplicationContainer;

ApplicationContainer.propTypes = {
    history: PropTypes.object,
    dataset: PropTypes.object,
};
