import TagManager from 'react-gtm-module';
import { ENV_CONSTANTS, GOOGLE_TAG_MANAGER_ID } from '../constants';

/**
 * Add custom event, 'Delete user gene' to Google Tag Manager's DataLayer
 *
 * @param {string} dataset
 * @param {string} visualizationKey - 'HEATMAP'
 * @param {string} geneSymbol - name of gene
 */
const eventDeleteUserGene = (dataset, visualizationKey, geneSymbol) => {
    const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_ID,
        auth: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_AUTH,
        preview: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_ENVIRONMENT,
        dataLayer: {
            event: 'Delete user gene',
            dataset,
            visualization: visualizationKey,
            geneSymbol
        }
    };

    TagManager.initialize(tagManagerArgs);
};

export default eventDeleteUserGene;
