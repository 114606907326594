import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import './style.scss';

const TooltipContent = (props) => {
    const { data } = props;
    const tooltipInfo = {
        'Cell Type': data.columnLabel,
        [data.labelHeader]: data.rowLabel,
    };

    return (
        <div className='sampling-strategy__tooltip'>
            {map(tooltipInfo, (value, key) => (
                <div key={`${value}:${key}`}>
                    <span className='sampling-strategy__tooltip--bold'>{key}: </span> {value}
                </div>
            ))}
            <div className='sampling-strategy__tooltip--spacer'>&nbsp;</div>
            <div>
                {data.count} cells of {data.totalCellsInCluster} total
            </div>
            {data.totalCellsInCluster !== 0 && <div>
                {data.percentCellsInCluster}% of cells of this type
            </div>}
        </div>
    );
};

export default TooltipContent;

TooltipContent.propTypes = {
    data: PropTypes.object,
};
