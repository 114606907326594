import isNil from 'lodash/isNil';
import flatMap from 'lodash/flatMap';
import isString from 'lodash/isString';

import getConsecutiveRanges from './get-consecutive-ranges';
import getMatrixWidth from './get-matrix-width';
import { HUMAN_AD_DATASET_NAME } from '../constants';

/**
 * Calculate the column width of a matrix to to allow it to
 * fit into a particular screen width. Calculation constrained by
 * specified minimum column width.
 *
 * @param {number} matrixScreenWidth - how much screen width occupied by matrix
 * @param {Object[][]} matrix - matrix object
 * @param {number} minColWidth - minimum column width
 * @returns {number} calculated matrix column width
 */
export const computeMatrixColumnWidth = (matrixScreenWidth, matrix, minColWidth) => {
    const numberOfColumns = getMatrixWidth(matrix);

    if (!numberOfColumns) {
        return minColWidth;
    }

    const columnWidth = Math.floor(matrixScreenWidth / numberOfColumns);

    return Math.max(columnWidth, minColWidth);
};

/**
 * Some matrix rows are empty placeholders,
 * Builds ranges of non-empty rows, because some matrix rows are
 * empty placeholders. This is useful for creating backgrounds
 * behind sparsely rendered cells
 *
 * @param {Object[][]} matrix
 * @returns {null|number[][]}
 */
export const buildMatrixIndexRangesWithData = (matrix, selectedDataset) => {
    if (isNil(matrix)) {
        return null;
    }
    //Human AD has specific configuration to account for removal of
    //Biological Sex and Cortical Layer rows
    if (selectedDataset === HUMAN_AD_DATASET_NAME) {
        return [[1, 2], [3, 6], [7, 31]];
    }

    const nonEmptyRows = matrix.reduce((acc, row, i) => {
        if (row.length) {
            acc.push(i);
        }
        return acc;
    }, []);

    return getConsecutiveRanges(nonEmptyRows);
};

/**
 * Build flat & sparse version of matrix.
 * Only cell values larger than the minimum are included.
 *
 * @param {Object[][]} matrix
 * @param {Object} featureSetRange - {min: <number>, max: <number>}
 * @returns {*|Array}
 */
export const buildSparseFlatFeatureSetMatrix = (matrix, featureSetRange) => (
    matrix &&
    featureSetRange &&
    flatMap(matrix, (row, rowIndex) => row.reduce(
        (acc, cell, columnIndex) => {
            // only include cells with values greater than min
            if (cell.value > featureSetRange.min || isString(cell.value)) {
                acc.push(Object.assign({}, cell, {
                    columnIndex,
                    rowIndex,
                }));
            }

            return acc;
        },
        [],
    ))
);
