import eventMoveGene from '../analytics/event-move-gene';

export const HEATMAP_FETCH_MARKER_GENES = 'HEATMAP_FETCH_MARKER_GENES';
export const HEATMAP_RECEIVE_MARKER_GENES = 'HEATMAP_RECEIVE_MARKER_GENES';
export const HEATMAP_ERROR_MARKER_GENES = 'HEATMAP_ERROR_MARKER_GENES';

export const HEATMAP_FETCH_LEAF_CLUSTER_LABELS = 'HEATMAP_FETCH_LEAF_CLUSTER_LABELS';
export const HEATMAP_RECEIVE_LEAF_CLUSTER_LABELS = 'HEATMAP_RECEIVE_LEAF_CLUSTER_LABELS';
export const HEATMAP_ERROR_LEAF_CLUSTER_LABELS = 'HEATMAP_ERROR_LEAF_CLUSTER_LABELS';

export const HEATMAP_FETCH_MARKER_GENE_MATRIX = 'HEATMAP_FETCH_MARKER_GENE_MATRIX';
export const HEATMAP_RECEIVE_MARKER_GENE_MATRIX = 'HEATMAP_RECEIVE_MARKER_GENE_MATRIX';
export const HEATMAP_ERROR_MARKER_GENE_MATRIX = 'HEATMAP_ERROR_MARKER_GENE_MATRIX';

export const HEATMAP_FETCH_USER_GENE_MATRIX = 'HEATMAP_FETCH_USER_GENE_MATRIX';
export const HEATMAP_RECEIVE_USER_GENE_MATRIX = 'HEATMAP_RECEIVE_USER_GENE_MATRIX';
export const HEATMAP_ERROR_USER_GENE_MATRIX = 'HEATMAP_ERROR_USER_GENE_MATRIX';

export const HEATMAP_UPDATE_ROW_LABEL_MENU = 'HEATMAP_UPDATE_ROW_LABEL_MENU';

export const HEATMAP_HIDE_MARKER_GENE = 'HEATMAP_HIDE_MARKER_GENE';
export const HEATMAP_HIDE_ALL_MARKER_GENES = 'HEATMAP_HIDE_ALL_MARKER_GENES';
export const HEATMAP_UNHIDE_ALL_MARKER_GENES = 'HEATMAP_UNHIDE_ALL_MARKER_GENES';

export const HEATMAP_MOVE_USER_GENE = 'HEATMAP_MOVE_USER_GENE';
export const HEATMAP_MOVE_MARKER_GENE = 'HEATMAP_MOVE_MARKER_GENE';

export const HEATMAP_FETCH_FEATURE_SET_RANGE = 'HEATMAP_FETCH_FEATURE_SET_RANGE';
export const HEATMAP_RECEIVE_FEATURE_SET_RANGE = 'HEATMAP_RECEIVE_FEATURE_SET_RANGE';
export const HEATMAP_ERROR_FEATURE_SET_RANGE = 'HEATMAP_ERROR_FEATURE_SET_RANGE';

export const updateLabelMenuDataAction = (labelMenuData = null) => ({
    type: HEATMAP_UPDATE_ROW_LABEL_MENU,
    labelMenuData,
});

export const hideMarkerGeneAction = (selectedDataset, geneSymbol) => ({
    type: HEATMAP_HIDE_MARKER_GENE,
    geneSymbol,
    selectedDataset,
});

export const hideAllMarkerGenesAction = (selectedDataset) => ({
    type: HEATMAP_HIDE_ALL_MARKER_GENES,
    selectedDataset,
});

export const unhideAllMarkerGenesAction = (selectedDataset) => ({
    type: HEATMAP_UNHIDE_ALL_MARKER_GENES,
    selectedDataset,
});

export const moveUserGene = (selectedVisualization, selectedDataset, geneSymbol, delta) => {
    eventMoveGene({
        dataset: selectedDataset,
        delta,
        geneSymbol,
        visualization: selectedVisualization,
    });

    return {
        type: HEATMAP_MOVE_USER_GENE,
        selectedVisualization,
        selectedDataset,
        geneSymbol,
        delta,
    };
};

export const moveMarkerGene = (selectedVisualization, selectedDataset, geneSymbol, delta) => {
    eventMoveGene({
        dataset: selectedDataset,
        delta,
        geneSymbol,
        isMarkerGene: true,
        visualization: selectedVisualization,
    });

    return {
        type: HEATMAP_MOVE_MARKER_GENE,
        selectedVisualization,
        selectedDataset,
        geneSymbol,
        delta
    };
};
