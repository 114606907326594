import { GENERIC_FETCH_ERROR } from './errors-actions';

export const GENE_VALIDATION_FETCH = 'GENE_VALIDATION_FETCH';
export const GENE_VALIDATION_RECEIVE = 'GENE_VALIDATION_RECEIVE';
export const GENE_VALIDATION_ERROR = GENERIC_FETCH_ERROR;
export const GENE_VALIDATION_SOFT_RESET = 'GENE_VALIDATION_SOFT_RESET';
export const GENE_VALIDATION_INPUT_UPDATE = 'GENE_VALIDATION_INPUT_UPDATE';
export const geneValidationInputUpdate = (inputText, dataset, visualization) => ({
    type: GENE_VALIDATION_INPUT_UPDATE,
    inputText,
    dataset,
    visualization,
});
