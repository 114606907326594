import assign from 'lodash/assign';

import {
    SAMPLING_STRATEGY_FETCH_SPECIMEN_AGGREGATE,
    SAMPLING_STRATEGY_RECEIVE_SPECIMEN_AGGREGATE,
    SAMPLING_STRATEGY_ERROR_SPECIMEN_AGGREGATE,
} from '../actions/sampling-strategy-actions';
import { CHANGE_DATASET, CHANGE_VISUALIZATION } from '../actions';
import { FETCH_STATE } from '../constants';

const initialState = {
    specimenAggregateFetchState: FETCH_STATE.INIT,
};

const receiveSpecimenAggregateReducer = (state, action) => {
    const {
        data,
        metadata: {
            selectedDataset,
        },
        status,
    } = action;

    return assign({}, state, {
        specimenAggregateFetchState: status,
        specimenAggregate: {
            [selectedDataset]: data,
        },
    });
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 * Dataset specific store items are created lazily when setting into state
 *
 * @returns {function} (state: object, action: object) => object
 */
export default function createSamplingStrategyReducer(metadata) {
    return (state = initialState, action) => {
        switch (action.type) {
            case SAMPLING_STRATEGY_FETCH_SPECIMEN_AGGREGATE:
                return assign({}, state, { specimenAggregateFetchState: action.status });

            case SAMPLING_STRATEGY_RECEIVE_SPECIMEN_AGGREGATE:
                return receiveSpecimenAggregateReducer(state, action);

            case CHANGE_DATASET:
                // Clear state of big state values.
                // We rely on Apollo to cache the items and can retrieve them if the dataset is revisited.
                return initialState;

            case SAMPLING_STRATEGY_ERROR_SPECIMEN_AGGREGATE:
                return assign({}, state, { specimenAggregateFetchState: action.status });

            case CHANGE_VISUALIZATION:
                // Clear state of big state values.
                // We rely on Apollo to cache the items and can retrieve them if the visualization is revisited.
                return initialState;

            default:
                return state;
        }
    };
}
