import {
    interpolateOrGyBl,
    interpolateWtRd,
    interpolateWtBl,
} from '../utils/color-scales';

export const BLUE_TO_RED = 'BLUE_TO_RED';
export const WHITE_TO_RED = 'WHITE_TO_RED';
export const WHITE_TO_BLUE = 'WHITE_TO_BLUE';

export default {
    [BLUE_TO_RED]: interpolateOrGyBl,
    [WHITE_TO_RED]: interpolateWtRd,
    [WHITE_TO_BLUE]: interpolateWtBl,
};
