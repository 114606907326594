import { createSelector } from 'reselect';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

export const getAvailableDatasets = state => state.availableDatasets;
export const getSelectedDataset = state => state.selectedDataset;
export const getSelectedDatasetName = state => get(state, ['selectedDataset', 'name']);
export const getSelectedDatasetScatterPlotName = state => get(state, ['selectedDataset', 'scatterPlotNames', 0, 'name']);
export const getSelectedVisualization = state => state.selectedVisualization;
export const getClusterSelections = state => state.clusterSelection;
export const getMarkerGenes = state => state.markerGenes;
export const clusterCounts = state => state.clusterCounts;

export const getMarkerGeneList = createSelector(
    [getSelectedDatasetName, getMarkerGenes],
    (dataset, markerGenes) => get(markerGenes, dataset, [])
);

export const getSelectedClustersByDataset = createSelector(
    [getSelectedDatasetName, getClusterSelections],
    (dataset, clusterSelectionsByDataset) => get(clusterSelectionsByDataset, dataset, null),
);

export const getClusterCounts = createSelector(
    [getSelectedDatasetName, clusterCounts],
    (dataset, counts) => get(counts, dataset, null)
);

export const getClusterCountTotal = createSelector(
    [getClusterCounts],
    clusterCounts => reduce(clusterCounts, (sum, count) => sum + count, 0),
);

export const getClusterCountMax = createSelector(
    [getClusterCounts],
    clusterCounts => reduce(clusterCounts, (max, count) => Math.max(max, count), 0)
);
