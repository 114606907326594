import assign from 'lodash/assign';

/********************
 * Guidelines
 *
 * Both DEV_URLS and PROD_URLS must have an instance of each url property.
 * URLS should begin with a double slash rather than http/https
 * URLS should contain a trailing slash, except where a fragment or query string prevents this.
 * Links to the test portal site require a sharing_token
 ********************/

// Non-Production Google Tag Manager
const DEV_GOOGLE_TAG_CONFIG = {
    GOOGLE_TAG_MANAGER_AUTH: 'ZG1gKROLfz6L3fFD8y2VSA',
    GOOGLE_TAG_MANAGER_ENVIRONMENT: 'env-5',
};

// Production Google Tag Manager
const PROD_GOOGLE_TAG_CONFIG = {
    GOOGLE_TAG_MANAGER_AUTH: '_UkA4uwOOUBnz9EhkHD2BQ',
    GOOGLE_TAG_MANAGER_ENVIRONMENT: 'env-2',
};

const DEV_URLS = {
    BRAIN_MAP: '//brain-map-portal-stage.us.aldryn.io/',
    RNASEQ: '//brain-map-portal-stage.us.aldryn.io/atlases-and-data/rnaseq',
    HELP: '//community.brain-map.org/c/transcriptomics-viewer/',
    AIBS_FOOTER: '//brain-map-portal-stage.us.aldryn.io/footer-page?sharing_token=74883e1353e2bee1531b11e4c3ccfd7a12bc6c008de7b9914c013613207f9244',
};

const PROD_URLS = {
    BRAIN_MAP: '//portal.brain-map.org/',
    RNASEQ: '//portal.brain-map.org/atlases-and-data/rnaseq',
    HELP: '//community.brain-map.org/c/transcriptomics-viewer/',
    AIBS_FOOTER: '//portal.brain-map.org/footer-page',
};

const ENV_CONSTANTS = (() => {
    switch (process.env.REACT_APP_BUILD_ENV) {
        case 'production':
            return assign({}, PROD_URLS, PROD_GOOGLE_TAG_CONFIG);
        case 'stage':
            return assign({}, PROD_URLS, DEV_GOOGLE_TAG_CONFIG);
        default:
            return assign({}, DEV_URLS, DEV_GOOGLE_TAG_CONFIG);
    }
})();

export const PROD_ERROR_MESSAGE = null;

export default ENV_CONSTANTS;
