const SPECIMEN_AGGREGATE_PROPERTIES = [
    {
        alias: 'region',
        property: 'region.name',
        displayName: 'Brain Region',
        sortBy: 'region.name',
        groupBy: ['region.name', 'cluster.name'],
    },
    {
        alias: 'corticalLayer',
        property: 'corticalLayer.name',
        displayName: 'Cortical Layer',
        sortBy: 'corticalLayer.name',
        groupBy: ['corticalLayer.name', 'cluster.name'],
    },
    {
        alias: 'donor',
        property: 'donor.sex',
        displayName: 'Biological Sex',
        sortBy: 'donor.sex',
        groupBy: ['donor.sex', 'cluster.name'],
    },
    {
        alias: 'broadClass',
        property: 'broadClass.name',
        displayName: 'Cell Type Broad Class',
        sortBy: 'broadClass.order',
        groupBy: ['broadClass.name', 'cluster.name', 'broadClass.order'],
    },
    {
        alias: 'subclass',
        property: 'subclass.name',
        displayName: 'Cell Type Subclass',
        sortBy: 'subclass.order',
        groupBy: ['subclass.name', 'cluster.name', 'subclass.order'],
    },
];

export default SPECIMEN_AGGREGATE_PROPERTIES;
