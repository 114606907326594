export { default as fetchData } from './fetch-data';

/**
 * Action Types
 */

export const CHANGE_DATASET = 'CHANGE_DATASET';
export const CHANGE_VISUALIZATION = 'CHANGE_VISUALIZATION';
export const CHANGE_CLUSTER_SELECTION = 'CHANGE_CLUSTER_SELECTION';

/**
 * Action Creators
 */
export const changeClusterSelection = (clusterSelection, selectedDataset) => ({
    type: CHANGE_CLUSTER_SELECTION,
    clusterSelection,
    selectedDataset,
});

export const removeClusterSelection = (selectedDataset) => ({
    type: CHANGE_CLUSTER_SELECTION,
    clusterSelection: null,
    selectedDataset,
});

export const changeDataset = selectedDataset => ({
    type: CHANGE_DATASET,
    selectedDataset
});

export const changeVisualization = selectedVisualization => ({
    type: CHANGE_VISUALIZATION,
    selectedVisualization
});
