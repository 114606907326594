import assign from 'lodash/assign';
import getFromSession from '../utils/get-from-session-storage';
import setSessionStorage from '../utils/set-session-storage';

import {
    VISUALIZATIONS,
    BLUE_TO_RED,
    WHITE_TO_RED,
    WHITE_TO_BLUE,
} from '../constants';
import { UPDATE_COLOR_SCALE } from '../actions/color-scale-actions';


const initialState = {
    [VISUALIZATIONS.HEATMAP]: getFromSession(
        `${VISUALIZATIONS.HEATMAP}:COLOR_SCALE`,
        BLUE_TO_RED,
        false,
    ),
    [VISUALIZATIONS.SCATTER_PLOT]: getFromSession(
        `${VISUALIZATIONS.SCATTER_PLOT}:COLOR_SCALE`,
        WHITE_TO_RED,
        false
    ),
    [VISUALIZATIONS.SAMPLING_STRATEGY]: getFromSession(
        `${VISUALIZATIONS.SAMPLING_STRATEGY}:COLOR_SCALE`,
        WHITE_TO_BLUE,
        false
    ),
};

const changeColorScaleReducer = (state, { visualization, colorScaleOption }) => {
    setSessionStorage(`${visualization}:COLOR_SCALE`, colorScaleOption);

    return assign({}, state, {
        [visualization]: colorScaleOption
    });
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createColorScaleReducer() {
    return (state = initialState, action) => {
        switch (action.type) {
            case UPDATE_COLOR_SCALE:
                return changeColorScaleReducer(state, action);

            default:
                return state;
        }
    };
}
