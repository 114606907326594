export const UPDATE_TOOLTIP = 'UPDATE_TOOLTIP';
export const HIDE_TOOLTIP = 'HIDE_TOOLTIP';

export const tooltipUpdate = (config) => ({
    type: UPDATE_TOOLTIP,
    config,
});

export const tooltipHide = () => ({
    type: HIDE_TOOLTIP,
});
