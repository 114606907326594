import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './style.scss';

const TOP_MARGIN = 20;
const BOTTOM_MARGIN = 20;
const LEFT_MARGIN = 20;
const RIGHT_MARGIN = 20;

const computeStyle = (tooltipRight, tooltipBelow, config, rect) => {
    const top = tooltipBelow ? config.y + TOP_MARGIN : config.y - BOTTOM_MARGIN - rect.height;
    const left = tooltipRight ? config.x + LEFT_MARGIN : config.x - RIGHT_MARGIN - rect.width;

    return { top, left };
};

const TooltipContainer = () => {
    const [tooltipRight, setTooltipRight] = useState(true);
    const [tooltipBelow, setTooltipBelow] = useState(true);
    const [style, setStyle] = useState(null);
    const isOpen = useSelector(state => state.tooltip.isOpen);
    const config = useSelector(state => state.tooltip.config);
    const tooltipRef = useRef(null);
    const currentRef = tooltipRef.current;

    useEffect(() => {
        // compute if tooltip is on the right or left of the mouse cursor
        if (currentRef && config) {
            const rect = currentRef.getBoundingClientRect();
            const tooltipRightSideCheck = config.x + rect.width + RIGHT_MARGIN + LEFT_MARGIN;

            if (tooltipRight && (tooltipRightSideCheck > window.innerWidth)) {
                setTooltipRight(false);
            } else if (!tooltipRight && (tooltipRightSideCheck < window.innerWidth)) {
                setTooltipRight(true);
            }
        }
    }, [config, tooltipRight, currentRef]);

    useEffect(() => {
        // compute if tooltip is below or above the mouse cursor
        if (currentRef && config) {
            const rect = currentRef.getBoundingClientRect();
            const tooltipBottomSideCheck = config.y + rect.height + TOP_MARGIN + BOTTOM_MARGIN;

            if (tooltipBelow && (tooltipBottomSideCheck > window.innerHeight)) {
                setTooltipBelow(false);
            } else if (!tooltipBelow && (tooltipBottomSideCheck < window.innerHeight)) {
                setTooltipBelow(true);
            }
        }
    }, [config, tooltipBelow, currentRef]);

    useEffect(() => {
        // compute position style
        if (currentRef && config) {
            const rect = currentRef.getBoundingClientRect();

            setStyle(computeStyle(tooltipRight, tooltipBelow, config, rect));
        }
    }, [config, tooltipRight, tooltipBelow, currentRef]);

    if (!isOpen) {
        // if not open, then render a hidden div. This prevents some jittering effect.
        return (
            <div className='abe-tooltip--closed' style={style} ref={tooltipRef} />
        );
    }

    const ContentComponent = config.contentComponent;

    return (
        <div className='abe-tooltip' style={style} ref={tooltipRef} >
            <ContentComponent {...config.contentProps}/>
        </div>
    );
};

TooltipContainer.propTypes = {};

export default TooltipContainer;
