import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import maxBy from 'lodash/maxBy';

import { scaleLinear } from 'd3-scale';

const Histogram = ({
    binData,
    width,
    height,
    maxValue,
    minValue,
    onHoverCB,
    onLeaveCB,
    svgClassName,
    numberOfBins,
}) => {
    const xScale = useMemo(() => scaleLinear().domain([minValue, maxValue]).range([0, width]), [minValue, maxValue, width]);
    const yScale = useMemo(() => scaleLinear().domain([0, maxBy(binData, 'frequency').frequency]).range([height, 0]), [binData, height]);
    const rectWidth = width / numberOfBins - 1;

    return (
        <svg
            className={svgClassName}
            width={width}
            height={height}
        >
            {binData.map(
                (d, index) => (
                    <rect
                        shapeRendering='crispEdges'
                        key={`${d.threshold}:${index}`}
                        x={xScale(d.threshold)}
                        width={rectWidth}
                        y={yScale(d.frequency)}
                        height={yScale(0) - yScale(d.frequency)}
                        fill={d.color}
                        onMouseOver={() => onHoverCB(d, index)}
                        onMouseLeave={onLeaveCB}
                    />
                )
            )}
        </svg>
    );
};

Histogram.propTypes = {
    /**
     * Width of svg element.
     */
    width: PropTypes.number.isRequired,

    /**
     * Height of svg element.
     */
    height: PropTypes.number.isRequired,

    /**
     * Maximum value to use on x-scale.
     */
    maxValue: PropTypes.number.isRequired,

    /**
     * Minimum value to use on x-scale.
     */
    minValue: PropTypes.number.isRequired,

    /**
     * Total number of bins to compute rectangle width.
     */
    numberOfBins: PropTypes.number.isRequired,

    binData: PropTypes.arrayOf(PropTypes.shape({
        /**
         * Frequencies for each threshold bin.
         * These values are mapped to the range determined by svg height.
         */
        frequency: PropTypes.number,
        /**
         * Values indicating the start of a histogram bin.
         * These values are mapped to the range determined by svg width.
         */
        threshold: PropTypes.number,
        /**
         * Values indicating the average value of the bin.
         * For metadata purposes.
         */
        average: PropTypes.number,
        /**
         * Colors to apply to each bin rectagle.
         */
        color: PropTypes.string,
    })).isRequired,

    /**
     * When mouse over a rectangle:
     * (binDatum, index) => void
     */
    onHoverCB: PropTypes.func,

    /**
     * When mouse leave a rectangle
     * () => void
     */
    onLeaveCB: PropTypes.func,

    svgClassName: PropTypes.string,
};

export default Histogram;
