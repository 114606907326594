import flatten from 'lodash/flatten';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import { createSelector } from 'reselect';
import {
    TAXONOMY_ID_ACCESSOR,
    TAXONOMY_LABEL_ACCESSOR,
} from '../constants';
import {
    getSelectedDatasetName,
    getSelectedClustersByDataset,
} from '.';
import getConsecutiveRanges from '../utils/get-consecutive-ranges';

export const getTaxonomy = state => state.taxonomy;

export const getTaxonomyByDataset = createSelector(
    [getTaxonomy, getSelectedDatasetName],
    (taxonomy, selectedDataset) => get(taxonomy, selectedDataset, null)
);

export const getTaxonomyLeaves = createSelector(
    [getTaxonomyByDataset],
    taxonomyHierarchy => taxonomyHierarchy ? taxonomyHierarchy.leaves() : [],
);

export const getLeafData = createSelector(
    [getTaxonomyLeaves],
    taxonomyLeaves => taxonomyLeaves.map(taxon => taxon.data),
);

export const getLeafLabels = createSelector(
    [getLeafData],
    taxonomyLeafData => taxonomyLeafData.map(taxon => taxon[TAXONOMY_LABEL_ACCESSOR]),
);

export const getTaxonomyLeafMap = createSelector(
    [getTaxonomyLeaves],
    taxonomyLeaves => keyBy(taxonomyLeaves.map(taxon => taxon.data), TAXONOMY_ID_ACCESSOR),
);

export const getNodeByFunction = createSelector(
    [getTaxonomyByDataset],
    taxonomy => (value, path) => taxonomy && value && taxonomy.descendants().find(d => get(d, path) === value),
);

export const getSelectedTaxonomyNodes = createSelector(
    [getSelectedClustersByDataset],
    selectedNodes => selectedNodes && flatMap(selectedNodes, node => node.descendants()),
);

export const getSelectedTaxonomyNodeDescendantMap = createSelector(
    [getSelectedTaxonomyNodes],
    selectedNodes => selectedNodes && selectedNodes.reduce((hash, node) => {
        node.descendants().forEach(d => {
            hash[d[TAXONOMY_ID_ACCESSOR]] = d;
        });

        return hash;
    }, {}),
);

export const getSelectedLeafIdMap = createSelector(
    [getSelectedTaxonomyNodeDescendantMap],
    selectedDescendantsMap => pickBy(selectedDescendantsMap, d => isEmpty(d.children)),
);

// Gets the ranges of each non-consecutive selected leaf nodes
// eg: if [5, 6, 7, 12, 13] are selected,
//      the ranges would be: (5 - 7) and (12 - 13).
//
//      since we want to draw at the end of the ranges
//      we'd get the values [[5, 8], [12, 14]].
//
//      the renderer prefers them flattened
//      so the end result is [5, 8, 12, 14]
// These ranges are helpful for rendering grid selection lines
export const getSelectedLeafIndexRangePairs = createSelector(
    [getLeafData, getSelectedTaxonomyNodeDescendantMap],
    (leaves, selectedLeafMap) => {
        if (!selectedLeafMap) {
            return null;
        }

        const selectedIndices = leaves.reduce((acc, leaf, i) => {
            if (has(selectedLeafMap, leaf.id)) {
                acc.push(i);
            }
            return acc;
        }, []);

        return getConsecutiveRanges(selectedIndices);
    },
);

export const getSelectedLeafIndexRangePairsFlat = createSelector(
    [getSelectedLeafIndexRangePairs],
    flatten,
);

// Gets the ranges of each non-consecutive non-selected leaf nodes
// eg: if [5,6] are selected, we'd get the ranges [[0, 5], [7, <length>]]
// These ranges are helpful for rendering grid selections
export const getNonSelectedLeafIndexRangePairs = createSelector(
    [getLeafData, getSelectedTaxonomyNodeDescendantMap],
    (leaves, selectedLeafMap) => {
        if (isEmpty(selectedLeafMap)) {
            return null;
        }

        const nonSelectedIndices = leaves.reduce((acc, leaf, i) => {
            if (!has(selectedLeafMap, leaf.id)) {
                acc.push(i);
            }
            return acc;
        }, []);

        return getConsecutiveRanges(nonSelectedIndices);
    },
);
