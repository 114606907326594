import get from 'lodash/get';

// actions
import {
    DISMISS_ERROR,
    GENERIC_FETCH_ERROR,
    MISSING_TECHNOLOGY_WARNING,
} from '../actions/errors-actions';
import {
    HEATMAP_ERROR_LEAF_CLUSTER_LABELS,
    HEATMAP_ERROR_MARKER_GENES,
    HEATMAP_ERROR_MARKER_GENE_MATRIX,
    HEATMAP_ERROR_USER_GENE_MATRIX,
    HEATMAP_ERROR_FEATURE_SET_RANGE,
} from '../actions/heatmap-actions';
import {
    SCATTER_PLOT_ERROR_POINT_DATA,
} from '../actions/scatter-plot-actions';
import { SAMPLING_STRATEGY_ERROR_SPECIMEN_AGGREGATE } from '../actions/sampling-strategy-actions';
import {
    FRIENDLY_BROWSER_INCOMPATIBILITY_MESSAGE,
} from '../constants';

// utils
import getGenericFriendlyErrorMessage from '../utils/get-generic-friendly-error-message';

const initialState = [];

const errorReducer = (state, action, friendlyMessage) => {
    const originalMessage = get(action, ['error', 'message'], null);
    const selfDismissTimeout = get(action, ['metadata', 'selfDismissTimeout'], null);
    return [...state, { friendlyMessage, originalMessage, selfDismissTimeout }];
};

const dismissErrorReducer = (state, action) => {
    return state.filter((_, index) => index !== action.index);
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 *
 * @returns {function} (state: object, action: object) => object
 */
export default function createErrorsReducer() {
    return (state = initialState, action) => {
        switch (action.type) {
            case DISMISS_ERROR:
                return dismissErrorReducer(state, action);

            case MISSING_TECHNOLOGY_WARNING:
                return errorReducer(state, action, FRIENDLY_BROWSER_INCOMPATIBILITY_MESSAGE);

            case SCATTER_PLOT_ERROR_POINT_DATA:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Scatter Plot coordinates'));

            case HEATMAP_ERROR_LEAF_CLUSTER_LABELS:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Heatmap column labels'));

            case HEATMAP_ERROR_MARKER_GENES:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Heatmap row labels'));

            case HEATMAP_ERROR_MARKER_GENE_MATRIX:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Heatmap marker gene matrix data'));

            case HEATMAP_ERROR_USER_GENE_MATRIX:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Heatmap user entered gene matrix data'));

            case HEATMAP_ERROR_FEATURE_SET_RANGE:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Heatmap feature set range'));

            case SAMPLING_STRATEGY_ERROR_SPECIMEN_AGGREGATE:
                return errorReducer(state, action, getGenericFriendlyErrorMessage('Sampling Strategy'));

            case GENERIC_FETCH_ERROR:
                return errorReducer(state, action, getGenericFriendlyErrorMessage());

            default:
                return state;
        }
    };
}
