import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import ApplicationContainer from './containers/application';
import NotFound from './components/not-found';
import TranscriptomicsDataset from './model/transcriptomics-dataset';
import { getAvailableDatasets } from './selectors';
import { TEMP_LEGACY_ROUTES } from './constants';

const Routes = () => {
    const datasets = useSelector(getAvailableDatasets);
    const [defaultDataset] = datasets;

    // TODO: THIS IS A HACK TO SUPPORT NEW & LEGACY ROUTES
    // TODO: REMOVE WHEN CELL TYPES IS RELEASED
    const tempDatasets = datasets.reduce((acc, dataset) => {
        const legacyURIS = get(TEMP_LEGACY_ROUTES, [dataset.name], []);

        legacyURIS.forEach((uri) => {
            const legacyDataset = new TranscriptomicsDataset(dataset);
            legacyDataset.setURI(uri);
            acc.push(legacyDataset);
        });

        return acc;
    }, datasets.slice());

    return (
        <Switch>
            {/* TODO: UPDATE TO datasets.map AFTER CELL TYPES RELEASE */}
            {tempDatasets.map((dataset) => (
                <Route
                    exact
                    key={dataset.name}
                    path={dataset.getURI()}
                    render={props => <ApplicationContainer {...props} dataset={dataset} />}
                />
            ))}

            {/* When a user arrives at the root, redirect them to the default */}
            <Redirect path='/' exact to={defaultDataset.getURI()} />
            <Redirect path='/index.html' exact to={defaultDataset.getURI()} />

            {/* When a user enters an invalid path, redirect them to the 404 */}
            <Route render={props => <NotFound {...props} datasets={datasets} />} />
        </Switch>
    );
};

export default Routes;
