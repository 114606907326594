import { useRef, useEffect } from 'react';

/**
 * Uses a ref to update values without triggering re-renders in hook deps.
 *
 * @param {*} value
 */
export default function useReferenceWrapper(value) {
    // keep same reference
    const referenceWrapper = useRef(value);

    useEffect(() => {
        // update key field when it changes
        referenceWrapper.current = value;
    }, [value]);

    return referenceWrapper;
}
