import gql from 'graphql-tag';

import { HEATMAP_FEATURE_SET_GROUP_BY } from '../constants';

const query = gql`
    query(
        $dataset: String
        $operator: MatrixAggregationOperator
        $groupBy: MatrixAggregationCellMetadata
    ){
        featureSetRange: aggregateRowsOnFeatureMatrix(
            features: []
            rows: []
            dataset: $dataset
            operator: $operator
            groupBy: $groupBy
        ){
            min
            max
        }
    }
`;

/**
 * Create a query object for fetching feature set range
 * @param {object} dataset
 */
export default function getFeatureSetRangeQuery(dataset) {
    const operator = dataset.getDefaultCentralMeasure();

    const variables = {
        dataset: dataset.name,
        operator,
        groupBy: HEATMAP_FEATURE_SET_GROUP_BY,
    };

    return { query, variables };
}
