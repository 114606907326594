import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const CellCountItem = ({ color, count, alias }) => {
    const colorStyle = useMemo(() => ({ backgroundColor: color }), [color]);

    return (
        <>
            <div className='cell-count-item__color' style={colorStyle}/>
            <div className='cell-count-item__count'>{count}</div>
            <div>{alias}</div>
        </>
    );
};

CellCountItem.propTypes = {
    color: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    alias: PropTypes.string.isRequired,
};

export default CellCountItem;
