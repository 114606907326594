import {
    COORDINATE_SYSTEM,
    CompositeLayer,
} from '@deck.gl/core';
import { SolidPolygonLayer } from '@deck.gl/layers';
import RectangleScatterPlotLayer from './rectangle-scatterplot-layer';
import {
    getDampeningLayer,
    getDampeningLineLayer,
} from './grid-dampening-layers';
import { getNumberArrayFromRgb } from '../utils/color-utils';
import rectPolygon from '../utils/rect-polygon';
import {
    SPARSE_HEATMAP_ZERO_VALUE_BACKGROUND,
    SPARSE_HEATMAP_GRID,
} from '../constants';

// in rows where there is data, this layer uses the minimum color value
// to color the background so only cells with data must be drawn individually
const getSparseHeatmapBackgroundLayer = props => new SolidPolygonLayer({
    id: `${props.id}_${SPARSE_HEATMAP_ZERO_VALUE_BACKGROUND}`,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.matrixRowIndexRangesWithData,
    getPolygon: ([startIndex, endIndex]) => rectPolygon({
        left: 0,
        right: props.matrixWidth * props.columnWidth,
        bottom: endIndex * props.rowHeight,
        top: startIndex * props.rowHeight,
    }),
    getFillColor: () => {
        const color = getNumberArrayFromRgb(props.minColor);
        return color;
    },
    updateTriggers: {
        getFillColor: [props.getColor],
        getPosition: [props.columnWidth, props.rowHeight],
        getPolygon: [props.columnWidth, props.rowHeight],
    },
});

// draws rectangle cells for every cell in a sparse matrix with a value
// greater than the minimum value.
const getSparseHeatmapGridLayer = props => new RectangleScatterPlotLayer({
    id: `${props.id}_${SPARSE_HEATMAP_GRID}`,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.sparseMatrix,
    getFillColor: d => getNumberArrayFromRgb(props.getColor(d.value, d.valueExtent)),
    getPosition: d => [
        d.columnIndex * props.columnWidth,
        d.rowIndex * props.rowHeight,
    ],
    getRadius: Math.max(props.columnWidth, props.rowHeight),
    rectAspectRatio: props.columnWidth / props.rowHeight,
    updateTriggers: {

        getFillColor: [props.getColor],
        getPosition: [props.columnWidth, props.rowHeight],
    },
});

// composite heatmap grid layer
export default class HeatmapGridLayer extends CompositeLayer {
    renderLayers() {
        return [
            getSparseHeatmapBackgroundLayer(this.props),
            getSparseHeatmapGridLayer(this.props),
            getDampeningLayer(this.props),
            getDampeningLineLayer(this.props),
        ];
    }
}
