import TagManager from 'react-gtm-module';
import {
    ENV_CONSTANTS,
    GOOGLE_TAG_MANAGER_ID,
} from '../constants';

export default function eventMoveGene({
    geneSymbol,
    delta,
    dataset,
    visualization,
    isMarkerGene = false,
}) {
    const direction = delta < 0 ? 'up' : 'down';
    const distance = Math.abs(delta);

    const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_ID,
        auth: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_AUTH,
        preview: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_ENVIRONMENT,
        dataLayer: {
            event: 'Move gene',
            dataset,
            visualization,
            geneSymbol,
            direction,
            distance,
            isMarkerGene,
        }
    };

    TagManager.initialize(tagManagerArgs);
}
