import React from 'react';
import PropTypes from 'prop-types';

import ApplicationFooter from './application-footer';
import VisualizationContainer from '../../containers/visualization';
import PortalHeader from './portal-header';

import './style.scss';

const Application = (props) => {
    const { history, dataset } = props;

    return (
        <div className='application'>
            <PortalHeader/>
            <VisualizationContainer history={history} />
            <ApplicationFooter dataset={dataset} />
        </div>
    );
};

export default Application;

Application.propTypes = {
    history: PropTypes.object,
    dataset: PropTypes.object,
};
