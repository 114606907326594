export const GENERIC_FRIENDLY_ERROR_MESSAGE = 'Data is temporarily unavailable at this time.';

export const FRIENDLY_BROWSER_INCOMPATIBILITY_MESSAGE = [
    'Technical limitations of this browser may affect your experience with this tool. Some functionality may not work as desired.',
    'We recommend using the latest version of Chrome, Firefox, Safari, or Edge.',
];

export const VOA_MISSING_MESSAGE = 'Vertex array object is not supported by this browser.';
export const BROWSER_INCOMPATIBILITY_WARNING_GIVEN_DATE_KEY = 'BROWSER_INCOMPATIBILITY_WARNING_GIVEN_DATE_KEY';
export const WARNING_DURATION_DAYS = 28;
export const WARNING_SELF_DISMISS_TIME = 15000;
