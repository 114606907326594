import gql from 'graphql-tag';

const query = gql`
    query MarkerGenes($dataSet: String!) {
        markerGenes: getTranscriptomicDataSet(DataSet: $dataSet) {
            markers {
                symbol
            }
        }
    }
`;


/**
 * Construct marker gene query given the dataset in dataSet
 * @param {string} dataSet - name of selected dataset, 'MOUSE' | 'HUMAN'
 */
const getMarkerGeneListQuery = (dataSet) => {
    const variables = { dataSet };

    return { query, variables };
};

export default getMarkerGeneListQuery;
