import pako from 'pako';

function pakoInflate(d) {
    return pako.inflate(d, { to: 'string' });
}

function jsonParseData(stringData) {
    let parsedData;
    try {
        parsedData = JSON.parse(stringData);
    } catch (e) {
        console.error(e);
        throw e;
    }
    return parsedData;
}

export default function unpackCompressedData(compressedData) {
    const stringData = pakoInflate(compressedData);
    const parsedData = jsonParseData(stringData);
    return parsedData;
}
