import { SCROLL_INCREMENT, ARROW_UP, ARROW_DOWN, ARROW_RIGHT, ARROW_LEFT } from '../constants/index';
import stopEventPropogation from './stop-event-propogation';

export default function arrowScrollHandler(ref) {
    return e => {
        const { keyCode } = e;
        const { current } = ref;
        const scrollLeft = current.getScrollLeft();
        const scrollTop = current.getScrollTop();

        switch (keyCode) {
            case ARROW_UP:
                stopEventPropogation(e);
                current.scrollTop(scrollTop - SCROLL_INCREMENT);
                break;
            case ARROW_DOWN:
                stopEventPropogation(e);
                current.scrollTop(scrollTop + SCROLL_INCREMENT);
                break;
            case ARROW_RIGHT:
                stopEventPropogation(e);
                current.scrollLeft(scrollLeft + SCROLL_INCREMENT);
                break;
            case ARROW_LEFT:
                stopEventPropogation(e);
                current.scrollLeft(scrollLeft - SCROLL_INCREMENT);
                break;
            default:
                break;
        }
    };
}
