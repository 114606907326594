import assign from 'lodash/assign';
import clamp from 'lodash/clamp';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import mergeWith from 'lodash/mergeWith';

import {
    HEATMAP_FETCH_MARKER_GENES,
    HEATMAP_RECEIVE_MARKER_GENES,
    HEATMAP_ERROR_MARKER_GENES,
    HEATMAP_UPDATE_ROW_LABEL_MENU,
    HEATMAP_HIDE_ALL_MARKER_GENES,
    HEATMAP_HIDE_MARKER_GENE,
    HEATMAP_UNHIDE_ALL_MARKER_GENES,
    HEATMAP_FETCH_MARKER_GENE_MATRIX,
    HEATMAP_ERROR_MARKER_GENE_MATRIX,
    HEATMAP_RECEIVE_MARKER_GENE_MATRIX,
    HEATMAP_FETCH_USER_GENE_MATRIX,
    HEATMAP_RECEIVE_USER_GENE_MATRIX,
    HEATMAP_ERROR_USER_GENE_MATRIX,
    HEATMAP_FETCH_FEATURE_SET_RANGE,
    HEATMAP_ERROR_FEATURE_SET_RANGE,
    HEATMAP_RECEIVE_FEATURE_SET_RANGE,
    HEATMAP_MOVE_MARKER_GENE,
    HEATMAP_MOVE_USER_GENE,
} from '../actions/heatmap-actions';
import { CHANGE_DATASET, CHANGE_VISUALIZATION, CHANGE_CLUSTER_SELECTION } from '../actions';
import { ADD_USER_GENES, DELETE_USER_GENE } from '../actions/gene-search-actions';
import {
    FETCH_STATE,
    MARKER_GENE_ACCESSOR,
} from '../constants';
import { arrayMove } from '../utils/array-utils';

const initialState = {
    userGeneMatrixFetchState: FETCH_STATE.INIT,
    markerGeneMatrixFetchState: FETCH_STATE.INIT,
    featureSetRangeFetchState: FETCH_STATE.INIT,
    labelMenuData: null,
    markerGenesFetchState: FETCH_STATE.INIT,
};

const receiveMarkerGenesReducer = (state, action) => {
    const {
        data: {
            markerGenes: {
                markers,
            },
        },
        metadata: {
            selectedDataset,
        },
        status,
    } = action;

    const nextMarkerGenes = assign({}, state.markerGenes,
        {
            [selectedDataset]: markers.map(d => d[MARKER_GENE_ACCESSOR]),
        },
    );

    return assign({}, state, {
        markerGenesFetchState: status,
        markerGenes: nextMarkerGenes,
    });
};

const receiveFeatureSetRangeReducer = (state, action) => {
    const {
        data: {
            featureSetRange,
        },
        metadata: {
            selectedDataset,
        },
        status,
    } = action;

    const nextFeatureSetRange = assign({}, state.featureSetRange,
        {
            [selectedDataset]: featureSetRange,
        },
    );

    return assign({}, state, {
        featureSetRangeFetchState: status,
        featureSetRange, nextFeatureSetRange,
    });
};

const formatAggregateRowsOnFeatureMatrix = aggregateRowsOnFeatureMatrix => aggregateRowsOnFeatureMatrix
    .reduce((expressionRows, { row, featureResults }) => {
        const rowExpression = featureResults.reduce((expressionCols, { feature, value }) => {
            expressionCols[feature] = value;
            return expressionCols;
        }, {});

        expressionRows[row] = rowExpression;
        return expressionRows;
    }, {});

const receiveMarkerGeneMatrixReducer = (state, action) => {
    const {
        data: {
            aggregateRowsOnFeatureMatrix: { groupByResults },
        },
        metadata: {
            selectedDataset,
        },
        status,
    } = action;

    const featureSetData = formatAggregateRowsOnFeatureMatrix(groupByResults);

    const nextMarkerGeneMatrix = assign({}, state.markerGeneMatrix,
        {
            [selectedDataset.name]: featureSetData,
        },
    );

    return assign({}, state, {
        markerGeneMatrixFetchState: status,
        markerGeneMatrix: nextMarkerGeneMatrix,
    });
};

const receiveUserGeneMatrixReducer = (state, action) => {
    const {
        data: {
            aggregateRowsOnFeatureMatrix: { groupByResults },
        },
        metadata: {
            selectedDataset,
            features: userGenesFetched,
        },
        status,
    } = action;

    const newFeatureSetData = formatAggregateRowsOnFeatureMatrix(groupByResults);
    const currentFeatureSetData = assign({}, get(state, ['userGeneMatrix', selectedDataset.name], {}));

    const mergedFeatureSetData = mergeWith(
        currentFeatureSetData,
        newFeatureSetData,
        (objValue, srcValue) => {
            return assign({}, objValue, srcValue);
        }
    );

    const nextUserGeneMatrix = assign({}, state.userGeneMatrix,
        {
            [selectedDataset.name]: mergedFeatureSetData,
        },
    );

    const prevFetchedUserGenes = state.fetchedUserGenes || [];
    const nextFetchedUserGenes = [...prevFetchedUserGenes, ...userGenesFetched];

    return assign({}, state, {
        fetchedUserGenes: nextFetchedUserGenes,
        userGeneMatrixFetchState: status,
        userGeneMatrix: nextUserGeneMatrix,
    });
};

const setLabelMenuData = (state, labelMenuData) => assign({}, state, { labelMenuData });

const setHiddenMarkerGene = (state, action) => {
    const { geneSymbol, selectedDataset } = action;

    const hiddenMarkerGenes = get(state, ['hiddenMarkerGenes', selectedDataset], []);

    const nextHiddenMarkerGenes = assign({}, state.hiddenMarkerGenes,
        {
            [selectedDataset]: [...hiddenMarkerGenes, geneSymbol],
        },
    );

    return assign({},
        setLabelMenuData(state, null),
        {
            hiddenMarkerGenes: nextHiddenMarkerGenes,
        },
    );
};

const hideAllMarkerGenes = (state, action) => {
    const { selectedDataset } = action;

    const nextHiddenMarkerGenes = assign({}, state.hiddenMarkerGenes,
        {
            [selectedDataset]: state.markerGenes[selectedDataset],
        },
    );

    return assign({},
        setLabelMenuData(state, null),
        {
            hiddenMarkerGenes: nextHiddenMarkerGenes,
        },
    );
};

const unhideAllMarkerGenes = (state, action) => {
    const { selectedDataset } = action;

    const nextHiddenMarkerGenes = assign({}, state.hiddenMarkerGenes,
        {
            [selectedDataset]: [],
        },
    );

    return assign({},
        setLabelMenuData(state, null),
        {
            hiddenMarkerGenes: nextHiddenMarkerGenes,
        },
    );
};

const softReset = (state) => assign({}, state, initialState);

function reorderMarkerGene(state, action) {
    // current state properties
    const currentMarkerGenes = get(state, ['markerGenes', action.selectedDataset], []);
    const currentHiddenMarkerGenes = get(state, ['hiddenMarkerGenes', action.selectedDataset], []);

    // index to reorder from
    const fromIndex = currentMarkerGenes.indexOf(action.geneSymbol);

    // skip over any hidden marker genes when assessing where to place reordered gene.
    let { delta } = action;
    let toIndex;
    while (isNil(toIndex)) {
        const checkIndex = clamp(fromIndex + delta, 0, currentMarkerGenes.length - 1);
        const currentGeneIsHidden = currentHiddenMarkerGenes.includes(currentMarkerGenes[checkIndex]);
        const roomToMove = checkIndex !== 0 && checkIndex !== currentMarkerGenes.length - 1;

        if (currentGeneIsHidden && roomToMove) {
            // move to next index in direction of delta
            delta = delta > 0 ? delta + 1 : delta - 1;
        } else {
            // assign and end loop
            toIndex = checkIndex;
        }
    }

    const nextMarkerGenes = assign({}, state.markerGenes, {
        [action.selectedDataset]: arrayMove(currentMarkerGenes, fromIndex, toIndex),
    });

    return assign({},
        setLabelMenuData(state, null),
        {
            markerGenes: nextMarkerGenes,
        },
    );
}

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 * Dataset specific store items are created lazily when set into state
 *
 * @returns {function} (state: object, action: object) => object
 */
export default function createHeatmapReducer() {
    // TODO: CONSISTENT STRUCTURE OF STATE.
    // TODO: CURRENTLY IMPLICIT PROPERTIES HAVE THEIR OWN DATASET NESTING

    return (state = initialState, action) => {
        switch (action.type) {
            case HEATMAP_HIDE_ALL_MARKER_GENES:
                return hideAllMarkerGenes(state, action);

            case HEATMAP_HIDE_MARKER_GENE:
                return setHiddenMarkerGene(state, action);

            case HEATMAP_UNHIDE_ALL_MARKER_GENES:
                return unhideAllMarkerGenes(state, action);

            case DELETE_USER_GENE:
                // DELETE_USER_GENE is also handled in src/reducers/gene-search-reducer.js
                return setLabelMenuData(state, null);

            case HEATMAP_MOVE_USER_GENE:
                // HEATMAP_MOVE_USER_GENE is also handled in src/reducers/gene-search-reducer.js
                return setLabelMenuData(state, null);

            case HEATMAP_UPDATE_ROW_LABEL_MENU:
                return setLabelMenuData(state, action.labelMenuData);

            case CHANGE_CLUSTER_SELECTION:
                return setLabelMenuData(state, null);

            case HEATMAP_FETCH_MARKER_GENES:
                return assign({}, state, { markerGenesFetchState: action.status });

            case HEATMAP_ERROR_MARKER_GENES:
                return assign({}, state, { markerGenesFetchState: action.status });

            case HEATMAP_RECEIVE_MARKER_GENES:
                return receiveMarkerGenesReducer(state, action);

            case HEATMAP_FETCH_MARKER_GENE_MATRIX:
                return assign({}, state, { markerGeneMatrixFetchState: action.status });

            case HEATMAP_ERROR_MARKER_GENE_MATRIX:
                return assign({}, state, { markerGeneMatrixFetchState: action.status });

            case HEATMAP_RECEIVE_MARKER_GENE_MATRIX:
                return receiveMarkerGeneMatrixReducer(state, action);

            case HEATMAP_FETCH_USER_GENE_MATRIX:
                return assign({}, state, { userGeneMatrixFetchState: action.status });

            case HEATMAP_RECEIVE_USER_GENE_MATRIX:
                return receiveUserGeneMatrixReducer(state, action);

            case HEATMAP_ERROR_USER_GENE_MATRIX:
                return assign({}, state, { userGeneMatrixFetchState: action.status });

            case HEATMAP_FETCH_FEATURE_SET_RANGE:
                return assign({}, state, { featureSetRangeFetchState: action.status });

            case HEATMAP_ERROR_FEATURE_SET_RANGE:
                return assign({}, state, { featureSetRangeFetchState: action.status });

            case HEATMAP_RECEIVE_FEATURE_SET_RANGE:
                return receiveFeatureSetRangeReducer(state, action);

            case CHANGE_DATASET:
                // Clear state of big state values.
                // We rely on Apollo to cache the items and can retrieve them if the dataset is revisited.
                return softReset(state);

            case ADD_USER_GENES:
                return softReset(state);

            case HEATMAP_MOVE_MARKER_GENE:
                return reorderMarkerGene(state, action);

            case CHANGE_VISUALIZATION:
                // Clear state of big state values.
                // We rely on Apollo to cache the items and can retrieve them if the visualization is revisited.
                return softReset(state);

            default:
                return state;
        }
    };
}
