import React from 'react';
import PropTypes from 'prop-types';

import { getClassName } from '../../utils';
import './style.scss';

const Header = props => {
    const className = getClassName('header', props.className);

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default Header;
