import {
    SPECIES_MOUSE,
    SPECIES_HUMAN,
} from './dataset-constants';

export {
    default as ENV_CONSTANTS,
    PROD_ERROR_MESSAGE,
} from './environment-constants';
export {
    default as COLOR_SCALE_OPTIONS,
    BLUE_TO_RED,
    WHITE_TO_RED,
    WHITE_TO_BLUE,
} from './color-scale-constants';
export * from './errors-constants';
export * from './regex';
export * from './layer-constants';
export * from './dataset-constants';
export * from './central-tendency-constants';
export { default as SPECIMEN_AGGREGATE_PROPERTIES } from './specimen-aggregate-properties';

export const APPLICATION_NAME = 'Transcriptomics Explorer';

// graphql url comes from env var (prefix REACT_APP_ is mandatory) or defaults to intranet service
export const GRAPH_SERVICE = process.env.REACT_APP_GRAPHQL_URL || 'https://idf-api-prod.aibs-idk-prod.net/';
export const { APPLICATION_VERSION } = process.env;

export const FETCH_STATE = {
    INIT: 'INIT',
    FETCHING: 'FETCHING',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR'
};

export const COLOR_BY_OPTIONS = {
    CELL_TYPE: 'Cell Type',
    GENE_EXPRESSION: 'Gene Expression',
};

export const VISUALIZATIONS = {
    SAMPLING_STRATEGY: 'Sampling Strategy',
    SCATTER_PLOT: 'Scatter Plot',
    HEATMAP: 'Heatmap',
};

export const VISUALIZATIONS_MAP = {
    [VISUALIZATIONS.SAMPLING_STRATEGY]: 'SAMPLING_STRATEGY',
    [VISUALIZATIONS.SCATTER_PLOT]: 'SCATTER_PLOT',
    [VISUALIZATIONS.HEATMAP]: 'HEATMAP',
};

export const VISUALIZATIONS_PREFIX = 'Visualizations:';

export const GENE_SEARCH = {
    TEXT_LABEL: 'Add Genes',
    PLACEHOLDER: 'Search for gene symbols',
    NOTHING_FOUND: 'No genes found'
};

// Gene validation
export const DEFAULT_GENE_SEARCH_LIMIT = 10;
export const GENE_SEARCH_API_MAX_LIMIT = 50;
export const DEFAULT_GENE_VALIDATION_DELIMITER = '\n';
export const DEFAULT_DEBOUNCE = 100;

// list of visualizations where Add Genes is appropriate to display
export const GENE_SEARCH_VISUALIZATIONS = [VISUALIZATIONS.HEATMAP];
export const CENTRAL_TENDENCY_VISUALIZATIONS = [VISUALIZATIONS.HEATMAP];
export const COLOR_BY_VISUALIZATIONS = [VISUALIZATIONS.SCATTER_PLOT];

// Taxonomy/Dendrogram Accessors
export const TAXONOMY_ID_ACCESSOR = 'id';
export const TAXONOMY_LABEL_ACCESSOR = 'alias';
export const TAXONOMY_COLOR_ACCESSOR = 'color';
export const TAXONOMY_ACCESSION_ACCESSOR = 'id';
export const TAXONOMY_DESIGNATION_ACCESSOR = 'label';
export const TAXONOMY_CELL_TYPE_ACCESSION_ID = 'Cell Type Accession ID';
export const TAXONOMY_CELL_TYPE_ALIAS = 'Cell Type Alias';
export const TAXONOMY_CELL_TYPE_LABEL = 'Cell Type Label';
export const TAXONOMY_CELL_COUNT = 'Cell Count';
export const TAXONOMY_CELL_PERCENT = 'Percent of Dataset';
export const TAXONOMY_TOOLTIP_TITLES = [
    TAXONOMY_CELL_TYPE_ACCESSION_ID,
    TAXONOMY_CELL_TYPE_ALIAS,
    TAXONOMY_CELL_TYPE_LABEL,
];
export const TAXONOMY_INFO_CONTENT_TITLES = [
    TAXONOMY_CELL_TYPE_ALIAS,
    TAXONOMY_CELL_TYPE_LABEL,
];

// Heat Map Data Accessors
export const MARKER_GENE_ACCESSOR = 'symbol';
export const HEATMAP_FEATURE_SET_GROUP_BY = 'CLUSTER_LABEL';
export const NO_LABEL_DEFAULT = 'No label';
export const HEATMAP_COLUMN_LABEL_ACCESSOR = 'clusterLabel';
export const HEATMAP_ROW_LABEL_ACCESSOR = 'geneLabel';

// Heat Map label types
export const MARKER_GENE_ROW_LABEL_HEADER = 'Marker Genes';
export const MARKER_GENE_ROW_LABEL = 'MARKER_GENE_ROW_LABEL';
export const USER_GENE_ROW_LABEL = 'USER_GENE_ROW_LABEL';

export const LABEL_MENU_HIDE_ALL = 'Hide All';
export const LABEL_MENU_HIDE = 'Hide';
export const LABEL_MENU_UNHIDE_ALL = 'Unhide All';
export const LABEL_MENU_DELETE = 'Delete';
export const LABEL_MENU_VIEW_IN_MOUSE_ATLAS = 'View in Mouse Brain Atlas';
export const LABEL_MENU = 'Show all menu options';
export const LABEL_MENU_UP = 'Move up';
export const LABEL_MENU_DOWN = 'Move down';
export const LABEL_MENU_PLOT = 'Plot';

export const ICON_COLOR_SCATTER_PLOT = 'fas fa-circle';
export const ICON_DELETE = 'fas fa-minus';
export const ICON_DOWN = 'fas fa-chevron-down';
export const ICON_HIDE = 'far fa-eye-slash';
export const ICON_UNHIDE = 'far fa-eye';
export const ICON_LINK = 'fas fa-link';
export const ICON_MENU = 'fas fa-ellipsis-h';
export const ICON_UP = 'fas fa-chevron-up';

export const HEATMAP_SCALE_BAR_TITLE = 'LOG2(CPM + 1)';

// Sampling Strategy
export const SAMPLING_STRATEGY_SCALE_BAR_TITLE = 'Cell Count Percentage';
export const COLUMN_PROPERTY = 'cluster.name';
export const SAMPLING_STRATEGY_COLUMN_LABEL_ACCESSOR = 'columnLabel';
export const SAMPLING_STRATEGY_ROW_LABEL_ACCESSOR = 'rowLabel';
export const SAMPLING_STRATEGY_FEATURE_SET_RANGE = { min: 0, max: 100 };
export const SAMPLING_STRATEGY_RANGE_ARRAY = [0, 100];
// Environment agnostic constants
export const GOOGLE_ANALYTICS_ID = 'UA-91694266-1';
export const GOOGLE_TAG_MANAGER_ID = 'GTM-PSVF434';

export const BRAIN_MAP_URL = '//brain-map.org';

// A Static map of hierarchy thresholds in which labels should be displayed by the dendrogram by species
// Hopefully, we come up with a more dynamic way to do this in the future
export const LABEL_HEIGHT_LIMIT_MAP = {
    [SPECIES_HUMAN]: {
        [VISUALIZATIONS.HEATMAP]: 3,
        [VISUALIZATIONS.SAMPLING_STRATEGY]: 3,
    },
    [SPECIES_MOUSE]: {
        [VISUALIZATIONS.HEATMAP]: 4,
        [VISUALIZATIONS.SAMPLING_STRATEGY]: 4,
    },
};

// Scroll increment for Content Grid
export const SCROLL_INCREMENT = 20;

// key codes
export const ARROW_LEFT = 37;
export const ARROW_UP = 38;
export const ARROW_RIGHT = 39;
export const ARROW_DOWN = 40;
export const ENTER_KEY = 13;

// numbers
export const ZERO = 0;

export const NOT_AVAILABLE = 'N/A';

//Removal of scientifically inaccurate categories in Human AD
export const HUMAN_AD_ROWS_TO_REMOVE = ['Cortical Layer', 'all', 'L5', 'Biological Sex', 'M', 'F'];
export const HUMAN_AD_DATASET_NAME = 'Human-MTG-10x_SEA-AD';
