import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_GENE_SEARCH_LIMIT } from '../constants';

const query = gql`query GeneSearch (
    $dataset: String!
    $searchString: String!
    $limit: Int
    $exact: Boolean
) {
    geneArray: searchFeaturesInDataSet(
        DataSet: $dataset
        text: $searchString
        limit: $limit
        exact: $exact
        sort: [{
            field: "symbol"
            order: ASC
        }]
    ) {
        symbol
        entrezId
    }
}`;

/**
 * Construct gene symbol search query based on first char matches of search string
 *
 * @param {string} searchString
 * @param {string} dataset - name of selected dataset
 * @param {number} limit - max number of search results
 * @param {boolean} exact - true for exact match, else false for case-insensitive match
 * @returns {Object} GraphQL tag object made up of "query' and 'variables' properties, or null if searchString is empty
 */
const getGeneSymbolsQuery = (
    searchString,
    dataset,
    limit = DEFAULT_GENE_SEARCH_LIMIT,
    exact = false,
) => {
    if (isEmpty(searchString) || isEmpty(dataset)) {
        return null;
    }

    const variables = {
        dataset,
        searchString,
        limit,
        exact,
    };

    return { query, variables };
};

export default getGeneSymbolsQuery;
