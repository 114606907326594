import last from 'lodash/last';

/**
 * Given a sorted array of numbers,
 * returns an array of number[] where the [first, last + 1] consecutive values in the sequence.
 * ex: input [1, 2, 3, 5, 6, 7] => [[1, 4], [5, 8]]
 *
 * @param {Number[]} sortedArray
 * @returns {Number[][]}
 */
export default function getConsecutiveRanges(sortedArray) {
    return sortedArray.reduce((acc, currentValue, i, arr) => {
        const isFirst = i === 0;
        const isLast = i === arr.length - 1;
        const previousValue = arr[i - 1];
        const isConsecutive = previousValue === currentValue - 1;

        if (isFirst) {
            acc.push([currentValue]);
        }

        if (!isConsecutive && !isFirst && !isLast) {
            // get reference to unfinished pair
            const lastPairInAcc = last(acc);
            // complete the pair
            lastPairInAcc.push(previousValue + 1);
            // start next pair
            acc.push([currentValue]);
        } else if (!isConsecutive && !isFirst && isLast) {
            // get reference to unfinished pair
            const lastPairInAcc = last(acc);
            // complete the pair
            lastPairInAcc.push(previousValue + 1);
            // start & finish last pair
            acc.push([currentValue, currentValue + 1]);
        } else if (isLast) {
            // get reference to unfinished pair
            const lastPairInAcc = last(acc);
            // complete the pair
            lastPairInAcc.push(currentValue + 1);
        }

        return acc;
    }, []);
}
