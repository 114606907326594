import eventAddUserGenes from '../analytics/event-add-user-gene';
import eventDeleteUserGene from '../analytics/event-delete-user-gene';

export const ADD_USER_GENES = 'ADD_USER_GENES';
export const DELETE_USER_GENE = 'DELETE_USER_GENE';

/**
 * This is a Redux Action creator for DELETE_USER_GENE
 *
 * @param {string} dataset - name of data set
 * @param {string} visualizationKey - name of visualization
 * @param {string} geneSymbol - user-selected gene symbol
 * @returns {{dataset: *, type: *, geneSymbol: *, visualizationKey: *}}
 */
export const deleteUserGeneAction = (dataset, visualizationKey, geneSymbol) => {
    // for Google Tag Manager
    eventDeleteUserGene(dataset, visualizationKey, geneSymbol);

    return {
        type: DELETE_USER_GENE,
        geneSymbol,
        dataset,
        visualizationKey,
    };
};

/**
 * This is a Redux Action creator for ADD_USER_GENES
 *
 * @param {string} dataset - name of data set
 * @param {string} visualizationKey - name of visualization
 * @param {Object|Object[]} userGenes - user-selected genes, element contains {symbol: <string>, entrezId: <number>}
 * @returns {{datasetKey: *, userGenes: *, type: *, visualizationKey: *}}
 */
export const addUserGenesAction = (dataset, visualizationKey, userGenes) => {
    // for Google Tag Manager
    eventAddUserGenes(dataset, visualizationKey, userGenes);

    return ({
        type: ADD_USER_GENES,
        userGenes,
        dataset,
        visualizationKey,
    });
};
