import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

//UTILS
import getClassName from '../../utils/get-class-name';

// components
import Scalebar from './scale-bar';

// constants
const BOTTOM_OFFSET = 32;

export default function ScalebarMenu(props) {
    const bottom = props.scalebarProps.height + BOTTOM_OFFSET;

    return (
        <div className='scalebar-menu' style={{ bottom }}>
            {map(props.optionsMap, (option, optionKey) => (
                <div
                    className={getClassName('scalebar-menu__item', optionKey === props.selectedColorScaleKey && 'scalebar-menu__item__selected')}
                    key={optionKey}
                >
                    <Scalebar
                        {...props.scalebarProps}
                        colorScheme={option}
                        tickCount={0}
                        height={props.itemHeight}
                        onClick={() => props.onItemClick(optionKey)}
                    />
                </div>
            ))}
        </div>
    );
}

ScalebarMenu.propTypes = {
    // Props for nested scalebars
    scalebarProps: PropTypes.shape(Scalebar.propTypes),

    // height of each colorscale to display
    itemHeight: PropTypes.number,

    // Map of color scales to choose from
    // { [colorscaleKey]: colorscale }
    optionsMap: PropTypes.object,

    // Menu item onClick callback
    onItemClick: PropTypes.func,

    // selected color scale
    selectedColorScaleKey: PropTypes.string,
};
