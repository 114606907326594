// TODO: REMOVE HARD CODED DATASET NAMES AFTER CELL TYPES RELEASE
export const MOUSE_SMARTSEQ_DATASET_NAME = 'aibs_mouse_ctx-hip_smart-seq';
export const HUMAN_SMARTSEQ_DATASET_NAME = 'aibs_human_ctx_smart-seq';
export const SPECIES_MOUSE = 'MOUSE';
export const SPECIES_HUMAN = 'HUMAN';

export const TEMP_LEGACY_ROUTES = {
    [MOUSE_SMARTSEQ_DATASET_NAME]: ['/mouse', '/mouse-cortex-and-hippocampus'],
    [HUMAN_SMARTSEQ_DATASET_NAME]: ['/human', '/human-cortex'],
};
