import { createSelector } from 'reselect';
import get from 'lodash/get';
import first from 'lodash/first';

import { COLOR_BY_OPTIONS } from '../constants';

import { getSelectedDatasetName, getMarkerGeneList } from '.';

export const getColorBy = state => state.colorBy;

export const getColorByFeature = createSelector(
    [getSelectedDatasetName, getColorBy],
    (selectedDataset, colorBy) => {
        return get(colorBy, ['colorByFeature', selectedDataset], COLOR_BY_OPTIONS.CELL_TYPE);
    }
);

export const getColorByFeatureValue = createSelector(
    [getSelectedDatasetName, getColorBy, getMarkerGeneList],
    (selectedDataset, colorBy, markerGeneList) => {
        const colorByValue = get(colorBy, ['featureValue', selectedDataset], null);
        const defaultValue = first(markerGeneList);

        const value = colorByValue || defaultValue;

        return value;
    }
);

export const getColorBySelectValue = createSelector(
    [getColorByFeatureValue],
    (colorByFeatureValue) => ({ label: colorByFeatureValue, value: colorByFeatureValue })
);
