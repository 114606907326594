import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

// custom scrollbar thumb
function ScrollbarThumb(props) {
    return (
        <div
            className='scrollbar-thumb__div'
            {...props}
        />
    );
}

ScrollbarThumb.PropTypes = {
    /**
     * css styles
     */
    style: PropTypes.style,
};

export default ScrollbarThumb;
