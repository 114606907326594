import React from 'react';
import PropTypes from 'prop-types';

import roundDigits from '../../utils/round-digits';

import './style.scss';

const CellExpressionHeader = ({ countSum, numberOfCellTypes, maxExpression, colorByFeatureValue, itemsAverage }) => (
    <div className='info-box-header__row'>
        <div>
            <div className='info-box-header--xxs'>Selected</div>
            <div className='info-box-header--s'>{`${countSum} cells`}</div>
            <div className='info-box-header--xxs'>{`${numberOfCellTypes} cell types`}</div>
        </div>
        <div>
            <div className='info-box-header--xxs'>{`${colorByFeatureValue} Expression`}</div>
            <div className='info-box-header__row'>
                <div>
                    <div className='info-box-header--s'>{roundDigits(itemsAverage, 2)}</div>
                    <div className='info-box-header--xxs'>avg</div>
                </div>
                <div>
                    <div className='info-box-header--s'>{roundDigits(maxExpression, 2)}</div>
                    <div className='info-box-header--xxs'>max</div>
                </div>
            </div>
        </div>
    </div>
);

CellExpressionHeader.propTypes = {
    countSum: PropTypes.number.isRequired,
    numberOfCellTypes: PropTypes.number.isRequired,
    maxExpression: PropTypes.number.isRequired,
    colorByFeatureValue: PropTypes.string.isRequired,
    itemsAverage: PropTypes.number.isRequired,
};

export default CellExpressionHeader;
