import gql from 'graphql-tag';

const query = gql`
    query Taxonomy ($dataset: String) {
        getTranscriptomicDataSet(DataSet: $dataset) {
            cellTypeTaxonomy {
                taxonomy: nodes {
                    id: accessionId
                    parentId
                    color
                    order
                    alias
                    label
                }
            }
        }
    }
`;

export default function getTaxonomyQuery(selectedDataset) {
    const variables = { dataset: selectedDataset };

    return { query, variables };
}
