import { ScatterplotLayer } from '@deck.gl/layers';
import vertexShader from '../shaders/selection-optimized-scatterplot-layer.vs';

// Scatterplot that has been modified to incorporate optimizations toward selections.
// The aim is to have the GPU handle view-layer selection logic to free up the main thread on the cpu
// Main differences in this subclass:
// 1) addition of getSelection attribute
// 2) addition of uniforms to dictate the behavior for selection display
// 3) removal of accessors getLineColor & getRadius that are now handled in vertex shader
export default class SelectionOptimizedScatterPlotLayer extends ScatterplotLayer {
    draw({ uniforms }) {
        super.draw({
            uniforms: {
                ...uniforms,
                ...SelectionOptimizedScatterPlotLayer.defaultProps.uniforms,
                ...this.props.uniforms,
            },
        });
    }

    initializeState() {
        super.initializeState();
        this.state.attributeManager.addInstanced({
            instanceIsSelected: { size: 1, accessor: 'getSelected' }
        });
        this.state.attributeManager.remove({
            attributeNames: [
                'getLineColor',
                'getRadius'
            ],
        });
    }

    getShaders() {
        return {
            ...super.getShaders(),
            vs: vertexShader,
        };
    }
}

SelectionOptimizedScatterPlotLayer.defaultProps = {
    ...ScatterplotLayer.defaultProps,
    uniforms: {
        // Selection uniform
        selectionExists: 0,

        // Alpha uniform values
        alphaBase: 0.1,
        defaultAlphaIncrease: 0.4,
        selectedAlphaIncrease: 0.7,

        // Radius uniform values
        defaultRadius: 1,
        selectedRadiusIncrease: 2,
    },
    getSelected: { type: 'accessor', value: 0 }
};
