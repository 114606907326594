import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

// constants
import {
    FETCH_STATE,
    VISUALIZATIONS,
    COLUMN_PROPERTY,
} from '../../constants';

// actions
import { fetchData } from '../../actions';
import {
    FETCH_TAXONOMY,
    RECEIVE_TAXONOMY,
    TAXONOMY_FETCH_ERROR,
    FETCH_MARKER_GENES,
    RECEIVE_MARKER_GENES,
    ERROR_MARKER_GENES,
    FETCH_CLUSTER_COUNTS,
    RECEIVE_CLUSTER_COUNTS,
    ERROR_CLUSTER_COUNTS,
} from '../../actions/fetch-actions';

// queries
import {
    getSpecimenAggregateQuery,
    getMarkerGeneListQuery,
    getTaxonomyQuery,
} from '../../queries';

// components
import VisualizationHeader from '../visualization-header';
import ScatterPlotContainer from '../scatter-plot';
import HeatmapContainer from '../heatmap';
import SamplingStrategyContainer from '../sampling-strategy';
import Tooltip from '../tooltip';
import ErrorReportContainer from '../error-report';

// selectors
import { getSelectedVisualization, getSelectedDatasetName } from '../../selectors';

import './style.scss';

const visualizationRendererMap = {
    [VISUALIZATIONS.SAMPLING_STRATEGY]: SamplingStrategyContainer,
    [VISUALIZATIONS.SCATTER_PLOT]: ScatterPlotContainer,
    [VISUALIZATIONS.HEATMAP]: HeatmapContainer,
};

const fetchTaxonomy = (dispatch, selectedDataset) => {
    const dataset = selectedDataset;
    const graphqlQuery = getTaxonomyQuery(dataset);
    const metadata = { selectedDataset };
    dispatch(fetchData(
        graphqlQuery,
        FETCH_TAXONOMY,
        RECEIVE_TAXONOMY,
        TAXONOMY_FETCH_ERROR,
        metadata,
    ));
};

const fetchMarkerGeneList = (dispatch, selectedDataset) => {
    const graphqlQuery = getMarkerGeneListQuery(selectedDataset);
    const metadata = { selectedDataset };
    dispatch(fetchData(
        graphqlQuery,
        FETCH_MARKER_GENES,
        RECEIVE_MARKER_GENES,
        ERROR_MARKER_GENES,
        metadata,
    ));
};

const fetchClusterCounts = (dispatch, selectedDataset) => {
    const graphqlQuery = getSpecimenAggregateQuery([{
        alias: 'clusterCounts',
        dataset: selectedDataset,
        groupBy: [COLUMN_PROPERTY],
        sortBy: COLUMN_PROPERTY,
    }]);
    const metadata = { selectedDataset };
    dispatch(fetchData(
        graphqlQuery,
        FETCH_CLUSTER_COUNTS,
        RECEIVE_CLUSTER_COUNTS,
        ERROR_CLUSTER_COUNTS,
        metadata,
    ));
};

const VisualizationContainer = (props) => {
    const dispatch = useDispatch();
    const selectedVisualization = useSelector(getSelectedVisualization);
    const selectedDataset = useSelector(getSelectedDatasetName);
    const taxonomyFetchState = useSelector(state => state.fetch.taxonomyFetchState);
    const markerGeneFetchState = useSelector(state => state.fetch.markerGeneFetchState);
    const clusterCountsFetchState = useSelector(state => state.fetch.clusterCountsFetchState);

    const VisualizationComponent = get(visualizationRendererMap, selectedVisualization, null);
    const okToFetchTaxonomy = taxonomyFetchState === FETCH_STATE.INIT;
    const okToFetchMarkerGenes = markerGeneFetchState === FETCH_STATE.INIT;
    const okToFetchClusterCounts = clusterCountsFetchState === FETCH_STATE.INIT;
    const shouldRenderVisualization = taxonomyFetchState !== FETCH_STATE.ERROR && markerGeneFetchState !== FETCH_STATE.ERROR;

    // Fetch app metadata
    useEffect(
        () => {if (okToFetchTaxonomy) fetchTaxonomy(dispatch, selectedDataset);},
        [selectedDataset, dispatch, okToFetchTaxonomy]
    );

    useEffect(
        () => {if (okToFetchClusterCounts) fetchClusterCounts(dispatch, selectedDataset);},
        [selectedDataset, dispatch, okToFetchClusterCounts]
    );

    useEffect(
        () => {if (okToFetchMarkerGenes) fetchMarkerGeneList(dispatch, selectedDataset);},
        [selectedDataset, dispatch, okToFetchMarkerGenes]
    );

    return (
        <div className={'visualization'}>
            <Tooltip/>
            <VisualizationHeader history={props.history} />
            <ErrorReportContainer />
            {shouldRenderVisualization && <VisualizationComponent />}
        </div>
    );
};

export default VisualizationContainer;

VisualizationContainer.propTypes = {
    history: PropTypes.object,
};
