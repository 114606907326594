/**
 * Convenience function for rendering a rectangle polygon.
 */
export default function rectPolygon({
    left,
    right,
    top,
    bottom,
}) {
    return [
        [left, top],
        [right, top],
        [right, bottom],
        [left, bottom],
        [left, top],
    ];
}
