/* eslint-disable no-multi-spaces, array-element-newline */
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import has from 'lodash/has';
import {
    COORDINATE_SYSTEM,
    CompositeLayer,
} from '@deck.gl/core';
import {
    PathLayer,
    LineLayer,
    ScatterplotLayer,
} from '@deck.gl/layers';
import RectangleScatterPlotLayer from './rectangle-scatterplot-layer';
import { hexToNumericalRgb } from '../utils/color-utils';
import {
    NODE_LAYER,
    EDGE_LAYER,
    LEAF_LAYER,
    LEAF_BACKGROUND_LAYER,
    COUNT_BACKGROUND_LAYER,
    COUNT_LAYER,
    SELECTED_LEAF_OUTLINE,
    TAXONOMY_ID_ACCESSOR,
} from '../constants';

const SELECTED_OUTLINE_COLOR = [255, 255, 255];
const SELECTED_OUTLINE_LINE_WIDTH = 2;
const EDGE_LINE_WIDTH_SELECTED = 1.2;
const EDGE_LINE_WIDTH = 1;
const ROOT_RADIUS = 2.5;
const SELECTED_RADIUS = 3.5;
const SELECTED_RADIUS_CHILD = 2.5;
const LANDMARK_RADIUS = 2.5;
const RADIUS = LANDMARK_RADIUS / 1.75;
const OPACITY_FULL = 255;
const OPACITY_DIMMED = 102;
const BLACK = [0, 0, 0, OPACITY_FULL];
const BLACK_DIMMED = [0, 0, 0, OPACITY_DIMMED];
const POSITION_FORMAT = 'XY';
const WHITE_RGB_COLOR_FILL = [255, 255, 255];

const isSelected = (props, d) => {
    // handle node case
    const nodeId = get(d, [TAXONOMY_ID_ACCESSOR]);
    const isSelectedNode = get(props.selectedDescendants, nodeId);

    // handle edge source node case
    const edgeSourceId = get(d, ['source', TAXONOMY_ID_ACCESSOR]);
    const isSelectedEdge = get(props.selectedDescendants, edgeSourceId);

    return isSelectedNode || isSelectedEdge;
};

const shouldDim = (props, d) => {
    const selectionExists = !!props.selectedDescendants;

    return selectionExists && !isSelected(props, d);
};

const getNodeLayer = props => new ScatterplotLayer({
    id: NODE_LAYER,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    pickable: true,
    data: props.nodeData,
    getRadius(d) {
        const isSelectedNode = has(props.selectedDescendants, d);
        if (isSelectedNode) { return SELECTED_RADIUS; }

        const isSelectedChild = props.selectedDescendants && props.selectedDescendants[d.id];
        if (isSelectedChild) { return SELECTED_RADIUS_CHILD; }

        const isRoot = d.parent === null;
        if (isRoot) { return ROOT_RADIUS; }

        const isLandmarkNode = d.data[props.nodeLabelAccessor];
        if (isLandmarkNode) { return LANDMARK_RADIUS; }

        return RADIUS;
    },
    getFillColor: d => shouldDim(props, d) ? BLACK_DIMMED : BLACK,
    getPosition: d => [d.x, d.y + props.paddingTop],
    updateTriggers: {
        getRadius: [props.selectedDescendants],
        getFillColor: [props.selectedDescendants],
    }
});

const getEdgeLayer = props => new PathLayer({
    id: EDGE_LAYER,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.edgeData,
    getColor: d => shouldDim(props, d) ? BLACK_DIMMED : BLACK,
    getPath: d => [
        d.source.x, d.source.y + props.paddingTop, // source
        d.target.x, d.source.y + props.paddingTop, // elbow
        d.target.x, d.target.y + props.paddingTop, // target
    ],
    getWidth: d => isSelected(props, d) ? EDGE_LINE_WIDTH_SELECTED : EDGE_LINE_WIDTH,
    positionFormat: POSITION_FORMAT,
    updateTriggers: {
        getRadius: [props.selectedDescendants],
        getColor: [props.selectedDescendants],
        getWidth: [props.selectedDescendants],
    }
});

const getLeafLayer = props => new RectangleScatterPlotLayer({
    id: LEAF_LAYER,
    pickable: true,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.leafData,
    getFillColor(d) {
        const colorHex = get(d, ['data', props.nodeColorAccessor]);
        const rgb = hexToNumericalRgb(colorHex);
        const a = shouldDim(props, d) ? OPACITY_DIMMED : OPACITY_FULL;
        return [rgb[0], rgb[1], rgb[2], a];
    },
    getPosition: (_, { index }) => [
        props.columnWidth * index,
        props.height - props.leafRowHeight - props.countRowHeight,
    ],
    getRadius: Math.max(props.columnWidth, props.leafRowHeight),
    rectAspectRatio: props.columnWidth / props.leafRowHeight,
    updateTriggers: {
        getRadius: [props.columnWidth, props.leafRowHeight],
        rectAspectRatio: [props.columnWidth, props.leafRowHeight],
        getPosition: [props.columnWidth, props.leafRowHeight, props.countRowHeight],
        getFillColor: [props.selectedDescendants]
    }
});

// LEAF_BACKGROUND_LAYER provides an opaque background that covers CELL_COUNT_LAYER bars
const getLeafBackgroundLayer = props => new RectangleScatterPlotLayer({
    id: LEAF_BACKGROUND_LAYER,
    pickable: false,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.leafData,
    getFillColor() {
        return WHITE_RGB_COLOR_FILL;
    },
    getPosition: (_, { index }) => [
        props.columnWidth * index,
        props.height - props.leafRowHeight - props.countRowHeight,
    ],
    getRadius: Math.max(props.columnWidth, props.countRowHeight),
    rectAspectRatio: props.columnWidth / props.countRowHeight,
    updateTriggers: {
        getRadius: [props.columnWidth, props.countRowHeight],
        rectAspectRatio: [props.columnWidth, props.countRowHeight],
        getPosition: [props.columnWidth, props.countRowHeight],
    }
});

const getCellCountBackgroundLayer = props => new RectangleScatterPlotLayer({
    id: COUNT_BACKGROUND_LAYER,
    pickable: true,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.leafData,
    getFillColor() {
        return WHITE_RGB_COLOR_FILL;
    },
    getPosition: (_, { index }) => [
        props.columnWidth * index,
        props.height - props.countRowHeight,
    ],
    getRadius: Math.max(props.columnWidth, props.countRowHeight),
    rectAspectRatio: props.columnWidth / props.countRowHeight,
    updateTriggers: {
        getRadius: [props.columnWidth, props.countRowHeight],
        rectAspectRatio: [props.columnWidth, props.countRowHeight],
        getPosition: [props.columnWidth, props.countRowHeight],
    }
});

const getCellCountLayer = props => new RectangleScatterPlotLayer({
    id: COUNT_LAYER,
    pickable: true,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.leafData,
    getFillColor(d) {
        const rgb = [153, 153, 153];
        const a = shouldDim(props, d) ? OPACITY_DIMMED : OPACITY_FULL;
        rgb.push(a);

        return rgb;
    },
    getPosition: (d, { index }) => {
        const alias = get(d, ['data', 'alias']);
        const count = get(props, ['clusterCountData', alias], 0);

        // scale percent to proportion of maximum percentage
        const p = Math.sqrt(count / props.clusterCountMax);

        return [
            props.columnWidth * index,
            props.height - (props.countRowHeight * (2 - p)),
        ];
    },
    getRadius: Math.max(props.columnWidth, props.countRowHeight),
    rectAspectRatio: props.columnWidth / props.countRowHeight,
    updateTriggers: {
        getRadius: [props.columnWidth, props.countRowHeight],
        rectAspectRatio: [props.columnWidth, props.countRowHeight],
        getPosition: [props.columnWidth, props.countRowHeight, props.clusterCountData],
        getFillColor: [props.selectedDescendants]
    }
});

const getSelectedLeafOutlineLayer = props => new LineLayer({
    id: SELECTED_LEAF_OUTLINE,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.selectedLeafIndexRangePairs && flatMap(props.selectedLeafIndexRangePairs, ([startIndex, stopIndex]) => [
        {
            sourcePosition: [startIndex * props.columnWidth, props.height],                                                     // lower left
            targetPosition: [startIndex * props.columnWidth, props.height - props.leafRowHeight - props.countRowHeight],    // upper left
        },
        {
            sourcePosition: [startIndex * props.columnWidth, props.height - props.leafRowHeight - props.countRowHeight],    // upper left
            targetPosition: [stopIndex * props.columnWidth, props.height - props.leafRowHeight - props.countRowHeight],     // upper right
        },
        {
            sourcePosition: [stopIndex * props.columnWidth, props.height - props.leafRowHeight - props.countRowHeight],     // upper right
            targetPosition: [stopIndex * props.columnWidth, props.height],                                                      // lower right
        },
    ]),
    getColor: SELECTED_OUTLINE_COLOR,
    getWidth: SELECTED_OUTLINE_LINE_WIDTH,
    positionFormat: POSITION_FORMAT,
    updateTriggers: {
        getPath: [props.columnWidth, props.leafRowHeight, props.countRowHeight],
    }
});

export default class DendrogramLayer extends CompositeLayer {
    getPickingInfo({ info, sourceLayer }) {
        // https://deck.gl/#/documentation/developer-guide/writing-custom-layers/composite-layers?section=picking
        // override info.object
        if (info.object) {
            info.object.sourceLayerId = sourceLayer.id;
        }

        return info;
    }

    renderLayers() {
        return [
            getEdgeLayer(this.props),
            getNodeLayer(this.props),
            getCellCountBackgroundLayer(this.props),
            getCellCountLayer(this.props),
            getLeafBackgroundLayer(this.props),
            getLeafLayer(this.props),
            getSelectedLeafOutlineLayer(this.props),
        ];
    }
}
