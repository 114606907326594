import assign from 'lodash/assign';
import castArray from 'lodash/castArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { CHANGE_CLUSTER_SELECTION } from '../actions';

const updateClusterSelection = (state, action) => {
    const shouldClearSelection = isNil(action.clusterSelection);
    const currentSelections = state[action.selectedDataset];
    const currentSelectionsIsNull = isNil(currentSelections);
    let clusterSelection;

    if (shouldClearSelection) {
        clusterSelection = null;
    } else if (currentSelectionsIsNull) {
        clusterSelection = castArray(action.clusterSelection);
    } else {
        // filter any current selection that is the *parent* of the incoming node
        const incomingAncestors = action.clusterSelection.ancestors();
        const filteredByIncomingChild = currentSelections.filter(currentNode => !incomingAncestors.includes(currentNode));

        // filter any current selection that is a *child* of the incoming node
        const incomingDescendants = action.clusterSelection.descendants();
        const filteredByIncomingParent = filteredByIncomingChild.filter(currentNode => !incomingDescendants.includes(currentNode));

        // if incoming node did not filter current selections by being a *child* of a current node, include it
        const includeIncoming = filteredByIncomingChild.length === currentSelections.length;
        clusterSelection = includeIncoming ? filteredByIncomingParent.concat(action.clusterSelection) : filteredByIncomingParent;

        // if there are no selections, assign null
        clusterSelection = isEmpty(clusterSelection) ? null : clusterSelection;
    }

    return assign({}, state, { [action.selectedDataset]: clusterSelection });
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 * Dataset specific store items are created lazily when set into state
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createClusterSelectionReducer() {
    return (state = {}, action) => {
        switch (action.type) {
            case CHANGE_CLUSTER_SELECTION:
                return updateClusterSelection(state, action);
            default:
                return state;
        }
    };
}
