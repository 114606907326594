import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import ScrollBarThumb from './scroll-bar-thumb';
import FollowerWrapper from './windowed-follower-translate-wrapper';
import './style.scss';

/**
 * A convenience wrapper to "window" a large element, like a <canvas/>, and simulate a scroll.
 * This allows the <canvas/> to only show where it is "windowed" and remain only the size that is viewable.
 */
const WindowedTranslateWrapper = forwardRef(({
    children,
    childrenStationary,
    followerOnly = false,
    padding = 250,
    onClick,
    onMouseLeave,
    onMouseMove,
    onScroll,
    scrollLeft = 0,
    scrollTop = 0,
    totalHeight = 0,
    totalWidth = 0,
    windowedHeight = 0,
    windowedWidth = 0,
}, ref) => {
    const OuterWrapper = followerOnly ? FollowerWrapper : Scrollbars;

    return (
        <OuterWrapper
            className='outer-translate-wrapper__windowed'
            onScroll={onScroll}
            ref={ref}
            renderThumbHorizontal={ScrollBarThumb}
            renderThumbVertical={ScrollBarThumb}
            style={{
                width: windowedWidth,
                height: windowedHeight,
            }}
        >
            <div
                className='inner-translate-wrapper__total'
                style={{
                    width: totalWidth,
                    height: totalHeight,
                }}
            >
                {/**
                  * This covers the case for when a portion of the windowed group is not windowed
                  * eg: text labels on dendrogram nodes
                  */}
                {childrenStationary}
                <div
                    className='inner-translate-wrapper__windowed'
                    onClick={onClick}
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseLeave}
                    style={{
                        width: windowedWidth + (padding * 2),
                        height: windowedHeight + (padding * 2),
                        transform: `translate(${scrollLeft - padding}px, ${scrollTop - padding}px)`
                    }}
                >
                    {children}
                </div>
            </div>
        </OuterWrapper>
    );
});

WindowedTranslateWrapper.propTypes = {
    // react children
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    // element(s) that should be included for scrolling, but not be translated
    childrenStationary: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    // callback
    onClick: PropTypes.func,

    // condition: if the windowing is meant to only follow the scroll position
    // of another element & not scrollable itself.
    followerOnly: PropTypes.bool,

    // callback
    onMouseLeave: PropTypes.func,

    // callback
    onMouseMove: PropTypes.func,

    // callback
    onScroll: PropTypes.func,

    // padding renders some of the windowed
    // element offscreen so scrolling is smoother
    padding: PropTypes.number,

    // current left scroll position to translate by
    scrollLeft: PropTypes.number,

    // current top scroll position to translate by
    scrollTop: PropTypes.number,

    // total height of element being windowed
    totalHeight: PropTypes.number,

    // total width of element being windowed
    totalWidth: PropTypes.number,

    // windowed screen space height of element being windowed
    windowedHeight: PropTypes.number,

    // windowed screen space width of element being windowed
    windowedWidth: PropTypes.number,
};

export default WindowedTranslateWrapper;
