import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './style.scss';

const CLASS_NAME_PREFIX = 'aibs-select';

/**
 * optionSelected wraps the incoming onChange handler and calls it only when the appropriate action has taken place.
 *
 * @param {object} selection The user's selection
 * @param {object} options.action The action type that occurred
 * @param {*} wrappedOnChange The onChange callback to wrap.
 */
const optionSelected = (selection, { action }, wrappedOnChange) => {
    if (action === 'select-option') {
        wrappedOnChange(selection);
    }
};

/**
 * A higher order component wrapping react-select
 *
 * @param {object} options The selectable option populating this select box
 * @param {function} onChange A callback that will receive a user selected value
 */
const DropdownSelect = props => {
    const onChangeDI = (selection, action) => optionSelected(selection, action, props.onChangeHandler);

    return <Select {...props} ref={props.innerRef} classNamePrefix={CLASS_NAME_PREFIX} onChange={onChangeDI} />;
};

DropdownSelect.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
            label: PropTypes.string
        })
    ).isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    isSearchable: PropTypes.bool,
    innerRef: PropTypes.object
};

DropdownSelect.defaultProps = {
    isSearchable: false
};

export default DropdownSelect;
export { CLASS_NAME_PREFIX };
