import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

/**
 * Get the case-insensitive exact match for what the user typed as a gene symbol against the
 * GraphQL select_genes query results
 *
 * @param {Object[]} values- each element is {value: {symbol: <gene symbol>, entrezId: <number>}}, label: <gene symbol>}
 * @param {string} userGeneSymbol - user-entered gene symbol, can have embedded whitespace chars
 * @returns {Object} if match found, return element from 'values' array, otherwise return undefined
 */
const getExactGeneMatch = (values, userGeneSymbol) => {
    let matchingValue;

    if (!isEmpty(values)) {
        // remove embedded spaces and convert to uppercase
        const preppedUserGeneSymbol = userGeneSymbol.replace(/\s/g, '').toUpperCase();

        // try to find a match ('find' returns undefined if no match)
        matchingValue = find(values, (val) => {
            return (preppedUserGeneSymbol === val.value.symbol.toUpperCase());
        });
    }

    return matchingValue;
};

export default getExactGeneMatch;
