import gql from 'graphql-tag';
import sortBy from 'lodash/sortBy';

import { HEATMAP_FEATURE_SET_GROUP_BY } from '../constants';

const query = gql`
    query(
        $dataset: String
        $features: [String]
        $rows: [String]
        $operator: MatrixAggregationOperator
        $groupBy: MatrixAggregationCellMetadata
    ){
        aggregateRowsOnFeatureMatrix(
            dataset: $dataset
            features: $features
            rows: $rows
            operator: $operator
            groupBy: $groupBy
        ){
            groupByResults {
                row
                featureResults {
                    feature
                    value
                }
            }
        }
    }
`;

/**
 * Create a query object for fetching feature set Matrix
 * @param {string[]} featureLabels
 * @param {string[]} leafClusterLabels
 * @param {string} dataset
 */
const getFeatureSetQuery = (featureLabels, leafClusterLabels, dataset) => {
    const features = sortBy(featureLabels); // sorting allows the cache to match reordered features
    const rows = leafClusterLabels;

    const operator = dataset.getDefaultCentralMeasure();

    const variables = {
        rows,
        features,
        dataset: dataset.name,
        operator,
        groupBy: HEATMAP_FEATURE_SET_GROUP_BY,
    };

    return { query, variables };
};

export default getFeatureSetQuery;
