import React, {
    useCallback,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

// constants
import { DEFAULT_DEBOUNCE } from '../../constants';

// actions
import { fetchData } from '../../actions';
import {
    GENE_VALIDATION_ERROR,
    GENE_VALIDATION_FETCH,
    GENE_VALIDATION_RECEIVE,
    GENE_VALIDATION_SOFT_RESET,
    geneValidationInputUpdate,
} from '../../actions/gene-validation-actions';
import { addUserGenesAction } from '../../actions/gene-search-actions';

// selectors
import { getSelectedDatasetName } from '../../selectors';
import {
    getSelectedVisualizationKey,
    getInputText,
    getValidGenes,
    getGeneValidationReport,
} from '../../selectors/gene-validation-selectors';

// query
import getGeneSymbolValidationQuery from '../../queries/get-gene-symbols-validation-query';

// utils
import pluralizeString from '../../utils/pluralize-string';

export const GENE_CLASS_VALID = 'fas fa-check multi-gene-select__valid-symbol';
export const GENE_CLASS_INVALID = 'far fa-question-circle multi-gene-select__invalid-symbol';
export const MULTI_GENE_SEPARATOR_REGEX = /[\w|.|@|(|)-]+/g;

const updateValidation = debounce((dispatch, dataset, visualization, rawInputText) => {
    // inputGenes is used to compare initial unique list with valid list that returns from api
    const inputGenes = rawInputText.match(MULTI_GENE_SEPARATOR_REGEX);

    if (!inputGenes) {
        dispatch({
            type: GENE_VALIDATION_SOFT_RESET,
            dataset,
            visualization,
        });

        return;
    }

    const graphqlQuery = getGeneSymbolValidationQuery(inputGenes, dataset);

    const metadata = {
        inputGenes,
        dataset,
        visualization,
    };

    dispatch(fetchData(
        graphqlQuery,
        GENE_VALIDATION_FETCH,
        GENE_VALIDATION_RECEIVE,
        GENE_VALIDATION_ERROR,
        metadata,
    ));
}, DEFAULT_DEBOUNCE);

export default function MultiGeneSelectionFormContainer({ onSubmit }) {
    const dispatch = useDispatch();
    const textAreaRef = useRef(null);

    const selectedDataset = useSelector(getSelectedDatasetName);
    const selectedVisualization = useSelector(getSelectedVisualizationKey);
    const inputText = useSelector(getInputText);
    const validGenes = useSelector(getValidGenes);
    const validationReport = useSelector(getGeneValidationReport);

    const onTextChange = useCallback((event) => {
        const text = get(event, ['target', 'value']);
        // update user text box
        dispatch(geneValidationInputUpdate(text, selectedDataset, selectedVisualization));
        // fetch validation
        updateValidation(dispatch, selectedDataset, selectedVisualization, text);
    }, [dispatch, selectedDataset, selectedVisualization]);

    const onMultiGeneSubmit = useCallback(() => {
        // add genes
        dispatch(addUserGenesAction(selectedDataset, selectedVisualization, validGenes));
        // clear the textarea
        textAreaRef.current.value = '';
        // inform parent of submission
        onSubmit();
    }, [dispatch, onSubmit, selectedDataset, validGenes, selectedVisualization]);

    return (
        <div className='multi-gene-select__outer-container'>
            <div className='multi-gene-select__inner-container'>
                <div className='multi-gene-select__body'>
                    <textarea
                        autoFocus
                        className='multi-gene-select__text-area'
                        ref={textAreaRef}
                        onChange={onTextChange}
                        spellCheck={false}
                        value={inputText}
                    />
                    <div className='multi-gene-select__validation-report'>
                        {validationReport.map(({ label, isRecognized }, i) => (
                            <p key={`${label}-${i}`}>
                                {label}
                                <i
                                    title={isRecognized ? 'Recognized Gene' : 'Unrecognized Gene'}
                                    className={isRecognized ? GENE_CLASS_VALID : GENE_CLASS_INVALID}
                                />
                            </p>
                        ))}
                    </div>
                </div>
                <div className='multi-gene-select__footer-container'>
                    <button
                        className='multi-gene-select__add-button'
                        disabled={!validGenes.length}
                        onClick={onMultiGeneSubmit}
                    >
                        Add {pluralizeString(validGenes.length, 'gene')}
                    </button>
                </div>
            </div>
        </div>
    );
}

MultiGeneSelectionFormContainer.propTypes = {
    // Function called after gene submission
    // Default component behavior always happens prior to call
    onSubmit: PropTypes.func,
};
