import gql from 'graphql-tag';

const query = gql`
    query availableDatasets {
        listTranscriptomicDataSets {
            name
            displayName
            downloadPage
            protocolsUrl
            defaultCentralMeasure
            modality {
                name
            }
            scatterPlotNames: tSNEPlots {
                name
            }
        }
    }
`;

export default function getListTranscriptomicDatasetQuery() {
    return { query };
}
