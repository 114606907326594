import assign from 'lodash/assign';
import get from 'lodash/get';

import { COLOR_BY_OPTIONS } from '../constants';
import { CHANGE_COLOR_BY_FEATURE } from '../actions/color-by-actions';

const changeColorByFeatureReducer = (state, action) => {
    const {
        datasetKey,
        colorByFeature,
        featureValue,
    } = action;

    const nextColorByFeature = assign({}, state.colorByFeature, { [datasetKey]: colorByFeature });
    const nextFeatureValue = assign({}, state.featureValue, { [datasetKey]: featureValue });

    return assign({}, state, {
        colorByFeature: nextColorByFeature,
        featureValue: nextFeatureValue,
    });
};

/**
 * creates reducer with given metadata
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createColorByReducer(metadata) {
    const initialColorByFeature = metadata.colorByFeature || COLOR_BY_OPTIONS.CELL_TYPE;
    const initialFeatureValue = metadata.colorByFeatureValue || null;

    // TODO: CONSISTENT STRUCTURE OF STATE.
    // TODO: CURRENTLY EACH PROPERTY HAS ITS OWN DATASET NESTING. THIS COULD BE NESTED JUST ONCE
    const initialColorByFeatureState = metadata.datasets.reduce(
        (acc, dataset) => assign(acc, { [dataset.name]: initialColorByFeature }),
        {},
    );

    const initialFeatureValueState = metadata.datasets.reduce(
        (acc, dataset) => {
            if (dataset.name === get(metadata, ['selectedDataset', 'name'])) {
                return assign(acc, { [dataset.name]: initialFeatureValue });
            }
            return acc;
        },
        {},
    );

    const defaultState = {
        colorByFeature: initialColorByFeatureState,
        featureValue: initialFeatureValueState,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
            case CHANGE_COLOR_BY_FEATURE:
                return changeColorByFeatureReducer(state, action);

            default:
                return state;
        }
    };
}
