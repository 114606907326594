import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

/**
 * React hook for responsive component
 * https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
 */
export default function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        // debounce fixes bug where resize does not update component.
        const handleResize = debounce(() => setWidth(window.innerWidth), 100);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return width;
}
