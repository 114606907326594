import assign from 'lodash/assign';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

import {
    GENE_VALIDATION_FETCH,
    GENE_VALIDATION_RECEIVE,
    GENE_VALIDATION_SOFT_RESET,
    GENE_VALIDATION_INPUT_UPDATE,
} from '../actions/gene-validation-actions';
import { ADD_USER_GENES } from '../actions/gene-search-actions';
import {
    FETCH_STATE,
} from '../constants';

const updateUserInput = (state, { dataset, visualization, inputText }) => {
    const prevValidation = get(state, [dataset, visualization]);

    const newValidationState = assign({}, prevValidation, { inputText });

    return assign({}, state, {
        [dataset]: {
            [visualization]: newValidationState,
        }
    });
};

const computeValidGenes = (validGeneArray, inputGenes) => {
    if (isEmpty(validGeneArray)) {
        return validGeneArray;
    }

    // input gene symbols are not case sensitive
    const validGenesKeyedBySymbol = keyBy(validGeneArray, gene => gene.symbol.toLowerCase());

    const validGenes = inputGenes.reduce((acc, symbol) => {
        const keySymbol = symbol.toLowerCase();

        if (has(validGenesKeyedBySymbol, keySymbol)) {
            acc.push(validGenesKeyedBySymbol[keySymbol]);
        }

        return acc;
    }, []);

    return validGenes;
};

const receiveGeneValidationReducer = (state, action) => {
    const dataset = get(action, ['metadata', 'dataset']);
    const visualization = get(action, ['metadata', 'visualization']);
    const prevValidation = get(state, [dataset, visualization]);
    const inputGenes = get(action, ['metadata', 'inputGenes'], []);
    const validGeneArray = get(action, ['data', 'geneArray'], []);

    const validGenes = computeValidGenes(validGeneArray, inputGenes);

    const newValidationState = assign({}, prevValidation, {
        inputGenes,
        validGenes,
    });

    return assign({}, state, {
        geneValidationFetchState: action.status,
        [dataset]: {
            [visualization]: newValidationState,
        },
    });
};

const resetState = (defaultState, state, { dataset, visualizationKey, }) => {
    const resetValidation = get(defaultState, [dataset, visualizationKey]);

    return assign({}, state, {
        [dataset]: {
            [visualizationKey]: resetValidation,
        },
    });
};

const softReset = (defaultState, state, { dataset, visualization }) => {
    // Reset validation state while keeping current user input
    const resetValidation = get(defaultState, [dataset, visualization]);
    const currentInputText = get(state, [dataset, visualization, 'inputText']);
    const newValidationState = assign({}, resetValidation, { inputText: currentInputText });

    return assign({}, state, {
        [dataset]: {
            [visualization]: newValidationState,
        },
    });
};

/**
 * creates reducer with given metadata
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createGeneValidation(metadata) {
    // TODO: STATE REFACTOR PUT FETCH STATE IN FETCH STORE
    const defaultState = { geneValidationFetchState: FETCH_STATE.INIT };
    metadata.datasets.forEach((dataset) => {
        defaultState[dataset.name] = {
            // TODO: STATE STORE IMPROVE PATTERN
            HEATMAP: {
                inputGenes: [],
                validGenes: [],
                inputText: '',
            },
        };
    });

    return (state = defaultState, action) => {
        switch (action.type) {
            case GENE_VALIDATION_FETCH:
                return assign({}, state, { geneValidationFetchState: action.status });

            case GENE_VALIDATION_RECEIVE:
                return receiveGeneValidationReducer(state, action);

            case GENE_VALIDATION_SOFT_RESET:
                return softReset(defaultState, state, action);

            case ADD_USER_GENES:
                return resetState(defaultState, state, action);

            case GENE_VALIDATION_INPUT_UPDATE:
                return updateUserInput(state, action);

            default:
                return state;
        }
    };
}
