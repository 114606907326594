import { createSelector } from 'reselect';
import get from 'lodash/get';

import { getSelectedDatasetName } from '.';

export const getGeneSearch = state => state.geneSearch;

export const getUserGeneSymbols = createSelector(
    [getSelectedDatasetName, getGeneSearch],
    (selectedDataset, geneSearch) => {
        return get(geneSearch, [selectedDataset, 'HEATMAP', 'userGenes'], []).map(({ symbol }) => symbol);
    }
);
