export const SCATTER_PLOT_FETCH_POINT_DATA = 'SCATTER_PLOT_FETCH_POINT_DATA';
export const SCATTER_PLOT_RECEIVE_POINT_DATA = 'SCATTER_PLOT_RECEIVE_POINT_DATA';
export const SCATTER_PLOT_ERROR_POINT_DATA = 'SCATTER_PLOT_ERROR_POINT_DATA';

export const SCATTER_PLOT_FETCH_GENE_EXPRESSION = 'SCATTER_PLOT_FETCH_GENE_EXPRESSION';
export const SCATTER_PLOT_RECEIVE_GENE_EXPRESSION = 'SCATTER_PLOT_RECEIVE_GENE_EXPRESSION';
export const SCATTER_PLOT_ERROR_GENE_EXPRESSION = 'SCATTER_PLOT_ERROR_GENE_EXPRESSION';

export const SCATTER_PLOT_UPDATE_VIEW_STATE = 'SCATTER_PLOT_UPDATE_VIEW_STATE';

export const updateViewStateAction = (viewState, selectedDataset) => ({
    type: SCATTER_PLOT_UPDATE_VIEW_STATE,
    viewState,
    selectedDataset,
});
