import { GENERIC_FETCH_ERROR } from './errors-actions';

export const FETCH_TAXONOMY = 'FETCH_TAXONOMY';
export const RECEIVE_TAXONOMY = 'RECEIVE_TAXONOMY';
export const TAXONOMY_FETCH_ERROR = GENERIC_FETCH_ERROR;

export const FETCH_MARKER_GENES = 'FETCH_MARKER_GENES';
export const RECEIVE_MARKER_GENES = 'RECEIVE_MARKER_GENES';
export const ERROR_MARKER_GENES = GENERIC_FETCH_ERROR;

export const FETCH_CLUSTER_COUNTS = 'FETCH_CLUSTER_COUNTS';
export const RECEIVE_CLUSTER_COUNTS = 'RECEIVE_CLUSTER_COUNTS';
export const ERROR_CLUSTER_COUNTS = GENERIC_FETCH_ERROR;
