import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';

export default function OptionIcon(props) {
    const {
        option,
        onClickDefault = noop,
    } = props;

    const onClick = e => {
        e.stopPropagation();

        if (option.callback) {
            option.callback(props.labelText);
        } else {
            onClickDefault(e);
        }
    };

    const RowIcon = (
        <span
            className='row-label__action-icon-wrapper'
            onClick={onClick}
            title={typeof option.text === 'function' ? option.text(props.labelText) : option.text}
        >
            <i className={option.iconClassname} />
            {option.iconText && <span className='row-label__action-item-text'>{option.iconText}</span>}
        </span>
    );

    if (option.link) {
        return <a target='_blank' href={option.link(props.labelText)}>{RowIcon}</a>;
    }

    return RowIcon;
}

OptionIcon.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func,
        ]),
        link: PropTypes.func,
        callback: PropTypes.func,
    })),
    labelText: PropTypes.string,
    onClickDefault: PropTypes.func,
};
