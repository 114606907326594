/**
 * little fn label maker
 * @param {string} text - text to show in ui
 * @param {boolean} type - isHeader - if this text is a header
 */
export default function getRowLabel(text, data = {}, isHeader = false) {
    return {
        data,
        isHeader,
        text,
    };
}
