import glsl from 'glslify';

export default glsl`
#define SHADER_NAME heatmap-grid-rectangle-layer-fragment-shader

precision mediump float;

// UNIFORMS
uniform vec4 rect;

// VARYINGS
varying vec4 vFillColor;
varying vec2 unitPosition;

bool isInRect(in vec4 rect, vec2 position) {
    vec2 inInnerCorner = step(rect.xy, position);
    vec2 inOuterCorner = step(position, rect.zw);
    vec2 inRect = inInnerCorner * inOuterCorner;

    // if x OR y is 0, this is falsy
    return bool(inRect.x * inRect.y);
}

void main(){
    if (!isInRect(rect, unitPosition)) {
        discard;
    }

    gl_FragColor = picking_filterHighlightColor(vFillColor);
    gl_FragColor = picking_filterPickingColor(gl_FragColor);
}`;
