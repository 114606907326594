import React from 'react';
import PropTypes from 'prop-types';

import OptionIcon from './option-icon';
import './style.scss';

/**
 * Pop-up menu when a row label is clicked
 *
 * @param {Object} props
 * @param {Object} props.labelMenuData - data for menu
 * @param {Number} props.labelMenuData.top - top position
 * @param {Number} props.labelMenuData.left - left position
 * @param {Array} props.labelMenuData.options - options for menu
 * @param {Array} props.labelMenuData.text - text from the clicked label
 */
const Menu = ({ labelMenuData }) => (
    <div
        className={'label-menu'}
        style={{ top: labelMenuData.top, left: labelMenuData.left }}
    >
        {labelMenuData.options.map((option) => {
            const icon = <OptionIcon option={option} labelText={labelMenuData.text}/>;

            if (option.link) {
                return (
                    <div
                        key={option.text}
                        className='label-menu__option'
                        onClick={option.callback}
                    >
                        {icon}
                        <a target='_blank' href={option.link(labelMenuData.text)}>{option.text}</a>
                    </div>
                );
            }

            return option.isMenuItem && (
                <div
                    key={option.text}
                    className='label-menu__option'
                    onClick={() => option.callback(labelMenuData.text)}
                >
                    {icon}
                    {typeof option.text === 'function' ? option.text(labelMenuData.text) : option.text}
                </div>
            );
        })}
    </div>
);

export default Menu;

Menu.propTypes = {
    labelMenuData: PropTypes.shape({
        top: PropTypes.number.isRequired,
        left: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.func,
            ]),
            link: PropTypes.func,
            callback: PropTypes.func,
        })),
    }),
};
