// GRID/HEATMAP
export const GRID_DAMPENING_LAYER = 'GRID_DAMPENING_LAYER';
export const GRID_VERTICAL_LINE_LAYER = 'GRID_VERTICAL_LINE_LAYER';
export const SPARSE_HEATMAP_ZERO_VALUE_BACKGROUND = 'SPARSE_HEATMAP_ZERO_VALUE_BACKGROUND';
export const SPARSE_HEATMAP_GRID = 'SPARSE_HEATMAP_GRID';
export const GRID_COMPOSITE_LAYER = 'GRID_COMPOSITE_LAYER';
// DENDROGRAM
export const NODE_LAYER = 'NODE_LAYER';
export const EDGE_LAYER = 'EDGE_LAYER';
export const LEAF_LAYER = 'LEAF_LAYER';
export const LEAF_BACKGROUND_LAYER = 'LEAF_BACKGROUND_LAYER';
export const COUNT_BACKGROUND_LAYER = 'COUNT_BACKGROUND_LAYER';
export const COUNT_LAYER = 'COUNT_LAYER';
export const SELECTED_LEAF_OUTLINE = 'SELECTED_LEAF_OUTLINE';
// LABEL OVERRIDES FOR DENDROGRAM NODE LABELS
export const TEXT_DATA_EXCEPTION_SEA_AD = [
    'L5 ET',
    'L6 CT',
    'L6 IT Car3',
];
