import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

/**
 * A component that informs a user that an error has occurred and refreshes the application.
 *
 * @param {object} props
 * @param {object} props.reloadDelay The non default amount of time to wait before refreshing the page.
 */
const ErrorIndicator = (props) => {
    const {
        message,
    } = props;

    return (
        <div className='error-container'>
            <div className='error-content'>
                <p className='error__header'>An error occurred.</p>
                {message && <p>{message}</p>}
                <p className='error__return'> Return to <a href=''>Allen Brain Map</a></p>
            </div>
        </div>
    );
};

export default ErrorIndicator;

ErrorIndicator.propTypes = {
    message: PropTypes.string,
};
