import React from 'react';
import PropTypes from 'prop-types';

const CellCountHeader = ({ countSum, numberOfCellTypes }) => (
    <div className='scatter-plot__info-box-header'>
        {`${countSum} cells in ${numberOfCellTypes} cell types`}
    </div>
);

CellCountHeader.propTypes = {
    countSum: PropTypes.number.isRequired,
    numberOfCellTypes: PropTypes.number.isRequired,
};

export default CellCountHeader;
