/* eslint-disable no-multi-spaces */
import { ScatterplotLayer } from '@deck.gl/layers';
import fragmentShader from '../shaders/rectangle-layer.fs';

const getWidthFromAspectRatio = aspectRatio => Math.min(aspectRatio, 1);
const getHeightFromAspectRatio = aspectRatio => Math.min((1 / aspectRatio), 1);

// Scatterplot that draws rectangles instead of circles.
// As opposed to the default scatterplot that draws from the center of a vertex,
// rectangles are drawn from the left-top corner.
export default class RectangleScatterPlotLayer extends ScatterplotLayer {
    draw({ uniforms }) {
        const rect = [
            0,                                                          // x1
            1.0 - getHeightFromAspectRatio(this.props.rectAspectRatio), // y1
            getWidthFromAspectRatio(this.props.rectAspectRatio),        // x2
            1.0,                                                        // y2
        ];

        super.draw({
            uniforms: {
                ...uniforms,
                rect,
            },
        });
    }

    getShaders() {
        return Object.assign({}, super.getShaders(), { fs: fragmentShader });
    }
}

RectangleScatterPlotLayer.defaultProps = {
    ...ScatterplotLayer.defaultProps,
    rectAspectRatio: 1,
};
