import React from 'react';
import PropTypes from 'prop-types';
import DeckGL from '@deck.gl/react';
import { OrthographicView } from '@deck.gl/core';
import DendrogramLayer from '../../custom-layers/dendrogram-composite-layer';

const ID = 'DENDROGRAM_DECKGL';
const PICKING_RADIUS = 5;

// Setting the controller as false helps performance/avoids issues with slow clicking
// https://github.com/uber/deck.gl/blob/master/docs/api-reference/controller.md
const views = [
    new OrthographicView({
        id: 'DENDROGRAM_CONTROLLER',
        controller: false,
    })
];

const getCursor = () => 'auto';

export default function Dendrogram(props) {
    return (
        <DeckGL
            getCursor={getCursor}
            id={ID}
            layers={[new DendrogramLayer(props)]} // See Dendrogram.propTypes for prop annotations
            onHover={props.onHover}
            onClick={props.onDendrogramClick}
            onLoad={props.onLoad}
            pickingRadius={PICKING_RADIUS}
            views={views}
            viewState={props.viewState}
        />
    );
}

Dendrogram.propTypes = {
    // lookup for cell alias to count
    clusterCountData: PropTypes.object,

    // maximum value of cluster count
    clusterCountMax: PropTypes.number,

    // width of each leaf node,
    // used to derive width of component
    columnWidth: PropTypes.number,

    // height of dendrogram cell count chart
    countRowHeight: PropTypes.number,

    // list of D3 Cluster Link objects
    edgeData: PropTypes.arrayOf(PropTypes.object),

    // height of component
    height: PropTypes.number,

    // list of D3 Node objects
    leafData: PropTypes.arrayOf(PropTypes.object),

    // height of dendrogram leaves
    leafRowHeight: PropTypes.number,

    // key name to access color from datum objects
    nodeColorAccessor: PropTypes.string,

    // list of D3 Node objects (excluding leaves)
    nodeData: PropTypes.arrayOf(PropTypes.object),

    // key name to access label from datum objects
    nodeLabelAccessor: PropTypes.string,

    // on click handler for entire dendrogram
    onDendrogramClick: PropTypes.func,

    // on hover handler
    onHover: PropTypes.func,

    // function to be performed on load of underlying component
    onLoad: PropTypes.func,

    // amount of padding above the dendrogram
    paddingTop: PropTypes.number,

    // current scroll-left state
    scrollLeft: PropTypes.number,

    // map of selected nodes to their d3 Node objects
    // [node_id]: <D3_Node>
    selectedDescendants: PropTypes.object,

    // list of where leaf selections stop and start, by index
    selectedLeafIndexRangePairs: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),

    // width of screen space in which component is rendered
    width: PropTypes.number,

    // view state object
    viewState: PropTypes.object,
};
