import { COORDINATE_SYSTEM } from '@deck.gl/core';
import {
    SolidPolygonLayer,
    LineLayer,
} from '@deck.gl/layers';
import {
    getNumberArrayFromRgb,
    hexToNumericalRgb,
} from '../utils/color-utils';
import rectPolygon from '../utils/rect-polygon';
import {
    GRID_DAMPENING_LAYER,
    GRID_VERTICAL_LINE_LAYER,
} from '../constants';
import styles from '../styles/variables.scss';

const NON_SELECTED_FILL = styles.translucent;
const SELECTED_BOUNDARY_COLOR = styles.colorLight;
const SELECTED_LINE_WIDTH = 2;

// creates polygons that lie over non-selected columns.
export const getDampeningLayer = props => new SolidPolygonLayer({
    id: `${props.id}_${GRID_DAMPENING_LAYER}`,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.nonSelectedIndexRangePairs,
    getPolygon: ([left, right]) => rectPolygon({
        left: left * props.columnWidth,
        right: right * props.columnWidth,
        top: 0,
        bottom: props.rowHeight * props.matrix.length,
    }),
    getFillColor: getNumberArrayFromRgb(NON_SELECTED_FILL),
    updateTriggers: {
        getPolygon: [props.columnWidth, props.rowHeight, props.matrix.length],
    }
});

// outlines the border between selected and non-selected columns
export const getDampeningLineLayer = props => new LineLayer({
    id: `${props.id}_${GRID_VERTICAL_LINE_LAYER}`,
    coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
    data: props.selectedIndexRangePairs,
    getColor: hexToNumericalRgb(SELECTED_BOUNDARY_COLOR),
    getSourcePosition: x => [x * props.columnWidth, 0],
    getTargetPosition: x => [x * props.columnWidth, props.rowHeight * props.matrix.length],
    getWidth: SELECTED_LINE_WIDTH,
    updateTriggers: {
        getSourcePosition: [props.columnWidth, props.selectedIndexRangePairs],
        getTargetPosition: [props.columnWidth, props.rowHeight, props.selectedIndexRangePairs, props.matrix],
    },
});
