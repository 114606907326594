/* eslint-disable prefer-destructuring */
import { createSelector } from 'reselect';
import get from 'lodash/get';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { bin, mean, range, sum, max } from 'd3-array';

import { getSelectedDatasetName, getClusterCounts } from '.';
import { getColorByFeature } from './color-by-selectors';
import {
    getTaxonomyLeafMap,
    getSelectedLeafIdMap,
} from './taxonomy-selectors';
import {
    COLOR_BY_OPTIONS,
    TAXONOMY_LABEL_ACCESSOR,
    ZERO,
} from '../constants';
import {
    applyColorScale,
    getColorScale,
} from './color-scale-selectors';
import {
    getColorsFromAttribute,
    getBinnedContinuousColorFunction,
} from '../utils/color-utils';

export const getScatterPlotPointDataFetchState = state => state.scatterPlot.scatterPlotPointDataFetchStatus;
export const getScatterPlotGeneExpressionFetchState = state => state.scatterPlot.scatterPlotGeneExpressionFetchStatus;
export const scatterPlotPointData = state => state.scatterPlot.scatterPlotPointData;
export const scatterPlotGeneExpression = state => state.scatterPlot.scatterPlotGeneExpression;
export const scatterPlotViewState = state => state.scatterPlot.viewState;

export const getViewState = createSelector(
    [getSelectedDatasetName, scatterPlotViewState],
    (selectedDataset, viewState) => get(viewState, selectedDataset, null)
);

export const getScatterPlotPointData = createSelector(
    [getSelectedDatasetName, scatterPlotPointData],
    (selectedDataset, pointData) => get(pointData, selectedDataset, null)
);

export const hasScatterPlotPointData = createSelector(
    [getSelectedDatasetName, scatterPlotPointData],
    (selectedDataset, pointData) => !!get(pointData, selectedDataset, false)
);

export const getScatterPlotGeneExpression = createSelector(
    [getSelectedDatasetName, scatterPlotGeneExpression],
    (selectedDataset, geneExpression) => get(geneExpression, selectedDataset, null)
);

export const getScatterCellLabels = createSelector(
    [getScatterPlotPointData],
    data => data && data.cell_labels,
);

export const getScatterClusterIds = createSelector(
    [getScatterPlotPointData],
    data => data && data.cluster_ids,
);

export const getExpressionExtent = createSelector(
    [getScatterPlotGeneExpression],
    data => (data && [data.expressionMin, data.expressionMax]) || [0, 0],
);

export const getExpressionColorsFn = createSelector(
    [
        getColorScale,
        getExpressionExtent,
        getScatterCellLabels,
        getScatterPlotGeneExpression,
    ],
    (...args) => getBinnedContinuousColorFunction.bind(null, ...args),
);

export const getScatterColorsFromCellTypeFn = createSelector(
    [getTaxonomyLeafMap, getScatterClusterIds],
    (...args) => getColorsFromAttribute.bind(null, ...args),
);

export const getDefaultColorFn = createSelector(
    [getScatterCellLabels],
    (cellLabels) => () => cellLabels && new Float32Array(cellLabels.length * 3).fill(0),
);

export const getScatterFillColors = createSelector(
    [getColorByFeature, getScatterColorsFromCellTypeFn, getExpressionColorsFn, getDefaultColorFn],
    (colorByFeature, colorByCellType, colorByExpression, colorDefault) => {
        // Color function parameters are already bound by consumed selector
        switch (colorByFeature) {
            case COLOR_BY_OPTIONS.CELL_TYPE:
                return colorByCellType();
            case COLOR_BY_OPTIONS.GENE_EXPRESSION:
                return colorByExpression();
            default:
                return colorDefault();
        }
    }
);

export const getScatterPosition = createSelector(
    [getScatterPlotPointData],
    (pointData) => {
        if (!pointData) {
            return null;
        }

        const positions = new Float32Array(pointData.x_y_coords.length * 2); // [x,y,x,y, ...]

        for (let i = 0; i < pointData.x_y_coords.length; i++) {
            const point = pointData.x_y_coords[i];
            positions[i * 2] = point[0];
            positions[i * 2 + 1] = -point[1];
        }

        return positions;
    }
);

export const getScatterSelected = createSelector(
    [getScatterPlotPointData, getSelectedLeafIdMap],
    (pointData, selectedLeafHash) => {
        if (!pointData) {
            return null;
        }

        const selected = new Uint8Array(pointData.cluster_ids.length); // [1, 0, 1, 0, ...]

        if (!isEmpty(selectedLeafHash)) {
            for (let i = 0; i < pointData.cluster_ids.length; i++) {
                selected[i] = selectedLeafHash[pointData.cluster_ids[i]] ? 1 : 0;
            }
        }

        return selected;
    },
);

export const getScatterData = createSelector(
    [getScatterPlotPointData, getScatterPosition, getScatterFillColors, getScatterSelected],
    (pointData, getPosition, getFillColor, getSelected) => {
        if (!pointData) {
            return null;
        }

        return {
            length: pointData.cluster_ids.length,
            attributes: {
                getPosition: { value: getPosition, size: 2 },
                getFillColor: { value: getFillColor, size: 3, normalized: true },
                getSelected: { value: getSelected, size: 1 },
            },
        };
    }
);

const getCellsByClusterId = createSelector(
    [getScatterPlotPointData],
    (scatterPlotPointData) => scatterPlotPointData && scatterPlotPointData.cell_labels.reduce((acc, cellLabel, i) => {
        const clusterId = scatterPlotPointData.cluster_ids[i];

        if (!acc[clusterId]) {
            acc[clusterId] = [];
        }

        acc[clusterId].push(cellLabel);

        return acc;
    }, {})
);

export const getClusterInfoById = createSelector(
    [getTaxonomyLeafMap, getClusterCounts, getColorByFeature, getScatterPlotPointData, getScatterPlotGeneExpression, applyColorScale, getCellsByClusterId],
    (taxonomyLeafMap, clusterCounts, colorByFeature, scatterPlotPointData, geneExpression, expressionColorScale, cellsByClusterId) => {
        if (taxonomyLeafMap && clusterCounts && scatterPlotPointData) {
            return mapValues(taxonomyLeafMap, (data) => {
                const clusterCount = get(clusterCounts, [data[TAXONOMY_LABEL_ACCESSOR]], ZERO);
                const clusterInfo = assign({}, data, { count: clusterCount });

                if (geneExpression && colorByFeature === COLOR_BY_OPTIONS.GENE_EXPRESSION) {
                    const clusterId = data.id;
                    const cellLabels = cellsByClusterId[clusterId] || [];
                    const expressionValues = cellLabels.map(cellLabel => geneExpression.expressionData[cellLabel] || ZERO);
                    const clusterAverage = clusterCount !== ZERO ? sum(expressionValues) / clusterCount : ZERO;
                    const thresholdTicks = range(ZERO, geneExpression.expressionMax, geneExpression.expressionMax / 40);
                    const histogramBins = bin()
                        .domain([ZERO, geneExpression.expressionMax])
                        .thresholds(thresholdTicks)(expressionValues);
                    const minValue = ZERO;
                    const clusterMaxValue = max(expressionValues);
                    const maxValue = geneExpression.expressionMax;
                    const binData = histogramBins.map(bin => {
                        const frequency = bin.length;
                        const threshold = bin.x0;
                        const average = mean(bin);
                        const color = expressionColorScale(average, [ZERO, geneExpression.expressionMax]);

                        return {
                            frequency,
                            threshold,
                            average,
                            color,
                        };
                    });
                    const numberOfBins = binData.length;

                    assign(clusterInfo, { binData, minValue, clusterMaxValue, maxValue, numberOfBins, clusterAverage });
                }
                return clusterInfo;
            });
        }

        return null;
    }
);
