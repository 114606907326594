const OES_VERTEX_ARRAY_OBJECT = 'OES_vertex_array_object';
const WEBGL_CONTEXT_NAMES = [
    'webgl2',
    'webgl',
    'experimental-webgl',
    'moz-webgl',
    'webkit-3d',
];

// re-use canvas object
const utilCanvas = document.createElement('canvas');

/**
 * If the browser supports WebGL, this function returns a WebGL canvas context.
 * If not, null is returned.
 *
 * @param {object} canvas
 * @returns {object|bool}
 */
export function getWebGlContext(canvas = utilCanvas) {
    for (let i = 0; i < WEBGL_CONTEXT_NAMES.length; i++) {
        try {
            const context = canvas.getContext(WEBGL_CONTEXT_NAMES[i]);

            if (context) {
                return context;
            }
        } catch (e) {
            // swallow error.
        }
    }

    return null;
}

/**
 * Tests if vertex array objects are supported.
 *
 * @param {object} canvas
 * @returns {bool}
 */
export const vertexArrayObjectIsSupported = (canvas = utilCanvas) => {
    const gl = getWebGlContext(canvas);

    if (!gl) {
        return false;
    }

    return !!(
        // webgl1
        gl.getExtension(OES_VERTEX_ARRAY_OBJECT) ||
        // webgl2
        typeof gl.createVertexArray === 'function'
    );
};
