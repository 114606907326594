import React from 'react';
import './style.scss';

const Loader = () => {
    return (
        <div className='indicator-container'>
            <div className='loader'>
                <div className='label'>Loading</div>
                <ul className='loader-bar'>
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                    <li />
                </ul>
            </div>
        </div>
    );
};

export default Loader;
