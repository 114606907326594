const URL_REGEX = /^(.*:)\/\/([A-Za-z0-9\-.]+)(:[0-9]+)?(.*)$/;

/**
 * Given a referrer URL, returns the target origin without extra URI paths
 *
 * @param {string} referrer
 */
export function getTargetOrigin(referrer) {
    try {
        const [, protocol = '', host = '', port = ''] = referrer.match(URL_REGEX);
        return protocol && host && `${protocol}//${host}${port}`;
    } catch (e) {
        return '';
    }
}

/**
 * Discerns the top window's target origin and posts a message with the given data.
 *
 * @param {object} data
 */
export default function postTopWindow(data) {
    try {
        // most browsers use document.referrer, ie11 uses window.top.location.href
        const referrer = document.referrer || window.top.location.href;

        // target origin of top window, "http://celltypes.brain-map.org" when app is used in an iframe
        const targetOrigin = getTargetOrigin(referrer);
        if (targetOrigin) {
            window.top.postMessage(data, targetOrigin);
        }
    } catch (e) {
        console.error('Error when attempting to post to top window:', e.message);
    }
}
