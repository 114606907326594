// see: https://usehooks.com/useKeyPress/
import { useState, useEffect } from 'react';

export default function useKeyPress(targetKeys) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    useEffect(() => {
        // If pressed key is our target key then set to true
        const downHandler = ({ key }) => {
            if (targetKeys.includes(key)) {
                setKeyPressed(true);
            }
        };

        // If released key is our target key then set to false
        const upHandler = ({ key }) => {
            if (targetKeys.includes(key)) {
                setKeyPressed(false);
            }
        };

        // Add event listeners
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    // Empty array ensures that effect is only run on mount and unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return keyPressed;
}
