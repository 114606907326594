import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import {
    GOOGLE_ANALYTICS_ID,
    GOOGLE_TAG_MANAGER_ID,
    ENV_CONSTANTS
} from '../constants';

const initializeAnalytics = () => {
    const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_ID,
        auth: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_AUTH,
        preview: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_ENVIRONMENT,
    };

    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
};

initializeAnalytics();

const reportPageView = ReactGA.pageview;

export default reportPageView;
