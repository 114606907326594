import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { dismissErrorAction } from '../../actions/errors-actions';
import isProduction from '../../utils/is-production';
import './style.scss';

export default function ErrorReportItem({
    friendlyMessage,
    index,
    originalMessage,
    selfDismissTimeout,
}) {
    const dispatch = useDispatch();
    const dismissTimeout = useRef(null);

    useEffect(() => {
        if (selfDismissTimeout) {
            dismissTimeout.current = setTimeout(() => {
                dispatch(dismissErrorAction(index));
            }, selfDismissTimeout);

            // clear timeout on user dismiss
            return () => clearTimeout(dismissTimeout.current);
        }
    }, [dispatch, selfDismissTimeout, index]);

    return (
        <div className='error-report__article'>
            <i className='fas fa-exclamation-triangle error-icon' />
            {friendlyMessage.map(message => (
                <p key={`error-report:${index}`} className='error-report__article-friendly-message'>{message}</p>
            ))}
            {!isProduction() && <p className='error-report__article-original-message'>{originalMessage}</p>}
            <i className='fas fa-times dismiss-error' onClick={() => dispatch(dismissErrorAction(index))} />
        </div>
    );
}

ErrorReportItem.propTypes = {
    // Helpful error messages to all users
    // using arrays gives more flexibility for spacing/formatting
    friendlyMessage: PropTypes.arrayOf(PropTypes.string).isRequired,

    // index of error, used for error dismissal
    index: PropTypes.number.isRequired,

    // Original error message for development/test users
    originalMessage: PropTypes.string.isRequired,

    // [optional] time in milliseconds to display before self-dismissal
    selfDismissTimeout: PropTypes.number,
};
