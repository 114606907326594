import isNil from 'lodash/isNil';

/**
 * Util for getItem from session storage
 * @param {sting} key - storage key
 * @param {object or array} defaultValue - the default reponse if there is no session data for key
 */
export default function getFromSession(key, defaultValue = [], parseJSON = true) {
    const data = sessionStorage.getItem(key);

    if (isNil(data)) {
        return defaultValue;
    }

    return parseJSON ? JSON.parse(data) : data;
}
