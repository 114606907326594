import React from 'react';
import PropTypes from 'prop-types';
import {
    TAXONOMY_ACCESSION_ACCESSOR,
    TAXONOMY_CELL_TYPE_ACCESSION_ID,
    TAXONOMY_CELL_TYPE_ALIAS,
    TAXONOMY_CELL_TYPE_LABEL,
    TAXONOMY_CELL_COUNT,
    TAXONOMY_CELL_PERCENT,
    TAXONOMY_LABEL_ACCESSOR,
    TAXONOMY_DESIGNATION_ACCESSOR,
    COUNT_LAYER,
    COUNT_BACKGROUND_LAYER,
} from '../../constants';
import roundDigits from '../../utils/round-digits';

const TooltipContent = ({ data, cellCount, percent, sourceLayerId }) => {
    const alias = data[TAXONOMY_LABEL_ACCESSOR];
    const label = data[TAXONOMY_DESIGNATION_ACCESSOR];
    const accession = data[TAXONOMY_ACCESSION_ACCESSOR];

    if (sourceLayerId === COUNT_LAYER || sourceLayerId === COUNT_BACKGROUND_LAYER) {
        return (
            <div className='dendrogram__selected-cluster-container__tooltip'>
                {alias && (
                    <div><span className='dendrogram__tooltip--bold'>{TAXONOMY_CELL_TYPE_ALIAS}: </span>{alias}</div>
                )}
                <div><span className='dendrogram__tooltip--bold'>{TAXONOMY_CELL_COUNT}: </span>{cellCount}</div>
                <div><span className='dendrogram__tooltip--bold'>{TAXONOMY_CELL_PERCENT}: </span>{`${roundDigits(percent, 3)} %`}</div>
            </div>
        );
    }

    return (
        <div className='dendrogram__selected-cluster-container__tooltip'>
            {alias && (
                <div><span className='dendrogram__tooltip--bold'>{TAXONOMY_CELL_TYPE_ALIAS}: </span>{alias}</div>
            )}
            {label && (
                <div><span className='dendrogram__tooltip--bold'>{TAXONOMY_CELL_TYPE_LABEL}: </span>{label}</div>
            )}
            {accession && (
                <div><span className='dendrogram__tooltip--bold'>{TAXONOMY_CELL_TYPE_ACCESSION_ID}: </span>{accession}</div>
            )}
        </div>
    );
};

TooltipContent.propTypes = {
    data: PropTypes.object.isRequired,
    cellCount: PropTypes.number,
    percent: PropTypes.number,
    sourceLayerId: PropTypes.string,
};

export default React.memo(TooltipContent);
