import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import ScaleBar, { ScaleBarMenu } from '../../components/scale-bar';

// CONSTANTS
import colorScaleOptions from '../../constants/color-scale-constants';

// SELECTORS
import { getSelectedVisualization } from '../../selectors';
import { getColorScaleKeyByVisualization } from '../../selectors/color-scale-selectors';

// ACTIONS
import { changeColorScale } from '../../actions/color-scale-actions';

const MENU_ITEM_HEIGHT = 25;
const CHEVRON_PADDING_DIVISOR = 15;

const ScaleBarContainer = props => {
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const selectedColorScaleKey = useSelector(getColorScaleKeyByVisualization);
    const selectedVisualization = useSelector(getSelectedVisualization);

    const closeMenu = useCallback(() => setMenuOpen(false), []);
    const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);
    const selectMenuItem = useCallback((option) => {
        dispatch(changeColorScale(selectedVisualization, option));
        setMenuOpen(false);
    }, [dispatch, selectedVisualization]);
    const menuChevron = menuOpen ? 'down' : 'up';

    return (
        <div className='scalebar-wrapper__outer'>
            {menuOpen && (
                <>
                    <div
                        className='scalebar-menu__modal-clicktrap'
                        onClick={closeMenu}
                    />
                    <ScaleBarMenu
                        itemHeight={MENU_ITEM_HEIGHT}
                        onItemClick={selectMenuItem}
                        optionsMap={colorScaleOptions}
                        scalebarProps={props}
                        selectedColorScaleKey={selectedColorScaleKey}
                    />
                </>
            )}
            <div
                className='scalebar-wrapper__inner'
                onClick={toggleMenu}
            >
                <ScaleBar {...props} />
                <div className='scalebar-chevron-wrapper'>
                    <i
                        className={`fas fa-chevron-${menuChevron}`}
                        style={{ paddingTop: props.height / CHEVRON_PADDING_DIVISOR }}
                    />
                </div>
            </div>
        </ div>
    );
};

export default ScaleBarContainer;

ScaleBarContainer.propTypes = {
    colorScheme: PropTypes.func.isRequired,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    reverseScheme: PropTypes.bool,
    precision: PropTypes.number,
    title: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    tickCount: PropTypes.number,
    onClick: PropTypes.func,
};
