import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import set from 'lodash/set';

// SELECTORS
import { getSelectedDatasetName } from '../../selectors';
import {
    getSparseFlatFeatureSetMatrix,
    getCurrentMinColorValue,
    getMatrixIndexRangesWithData,
} from '../../selectors/sampling-strategy-selectors';
import {
    getColorScale,
} from '../../selectors/color-scale-selectors';

// CONSTANTS
import {
    SAMPLING_STRATEGY_SCALE_BAR_TITLE,
    SAMPLING_STRATEGY_COLUMN_LABEL_ACCESSOR,
    SAMPLING_STRATEGY_ROW_LABEL_ACCESSOR,
    SAMPLING_STRATEGY_FEATURE_SET_RANGE,
    HUMAN_AD_ROWS_TO_REMOVE,
    HUMAN_AD_DATASET_NAME,
} from '../../constants';

// COMPONENTS
import ContentGridContainer from '../content-grid';
import DendrogramContainer from '../dendrogram';
import RowLabelsContainer from '../row-labels';
import TooltipContent from '../../components/sampling-strategy';
import ScaleBarContainer from '../scale-bar';

// UTILS
import arrowScrollHandler from '../../utils/arrow-scroll-handler';
import { computeMatrixColumnWidth } from '../../utils/grid-utils';

// STYLES
import { footerHeight } from '../../components/drawer/style.scss';

const SCALE_HEIGHT = 55;
const FOOTER_HEIGHT = parseInt(footerHeight) + SCALE_HEIGHT;
const MIN_COLUMN_WIDTH = 5;
const ROW_HEIGHT = 20;
const DENDROGRAM_LEAF_ROW_HEIGHT = ROW_HEIGHT;
const DENDROGRAM_CELL_COUNT_ROW_HEIGHT = ROW_HEIGHT;
const DENDROGRAM_TREE_HEIGHT = 130;
const LEFT_HEADER_WIDTH = 175;
const TOP_HEADER_HEIGHT = DENDROGRAM_TREE_HEIGHT + DENDROGRAM_LEAF_ROW_HEIGHT + DENDROGRAM_CELL_COUNT_ROW_HEIGHT;

const SamplingStrategyTable = (props) => {
    const columnWidth = props.matrix ? computeMatrixColumnWidth(props.width - LEFT_HEADER_WIDTH, props.matrix, MIN_COLUMN_WIDTH) : 0;
    const headerTopRef = useRef(null);
    const labelsLeftRef = useRef(null);
    const contentGridRef = useRef(null);
    const featureSetRange = SAMPLING_STRATEGY_FEATURE_SET_RANGE;
    const selectedDataset = useSelector(getSelectedDatasetName);
    const minColor = useSelector(getCurrentMinColorValue);
    const colorScale = useSelector(getColorScale);
    const sparseMatrix = useSelector(getSparseFlatFeatureSetMatrix);
    const matrixRowIndexRangesWithData = useSelector(getMatrixIndexRangesWithData);
    const [{ scrollTop, scrollLeft }, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });
    const [dendrogramLoaded, setDendrogramLoaded] = useState(false);

    const handleOnKeyDown = useCallback(arrowScrollHandler(contentGridRef), [contentGridRef]);

    useEffect(() => () => {
        // reset scroll state when selected dataset changes
        set(headerTopRef, ['current', 'scrollLeft'], 0);
        set(labelsLeftRef, ['current', 'scrollTop'], 0);
        // scrollbars scroll positions are setter functions
        invoke(contentGridRef, ['current', 'scrollLeft'], 0);
        invoke(contentGridRef, ['current', 'scrollTop'], 0);

        setScrollPosition({ scrollTop: 0, scrollLeft: 0 });
    }, [selectedDataset]);

    const updateScroll = useCallback((e) => {
        const eventScrollLeft = get(e, ['target', 'scrollLeft'], null);
        const eventScrollTop = get(e, ['target', 'scrollTop'], null);

        headerTopRef.current.scrollLeft = eventScrollLeft;
        labelsLeftRef.current.scrollTop = eventScrollTop;

        setScrollPosition({
            scrollLeft: eventScrollLeft,
            scrollTop: eventScrollTop,
        });
    }, []);

    const onDendrogramLoad = useCallback(() => setDendrogramLoaded(true), []);

    //Formatting of sparse matrix
    //Biological Sex and Cortical Layer are scientifically inaccurate for Human AD
    //and must be removed, in addition associated values need modified row index to account for row removal
    const formattedSparseMatrix = useMemo(() => {
        if (selectedDataset === HUMAN_AD_DATASET_NAME && sparseMatrix) {
            const filteredMatrix = sparseMatrix.filter(value => !HUMAN_AD_ROWS_TO_REMOVE.includes(value.labelHeader));
            const formattedIndexRowMatrix = filteredMatrix.map(value => {
                return {
                    ...value,
                    rowIndex: value.rowIndex > 7 ? value.rowIndex - 6 : value.rowIndex
                };
            });
            return formattedIndexRowMatrix;
        }
        return sparseMatrix;
    }, [sparseMatrix, selectedDataset]);


    if (!props.matrix) {
        return null;
    }

    return (
        <div
            className='sampling-strategy__table'
            style={{ width: props.width, height: props.height }}
            onKeyDown={handleOnKeyDown}
        >
            <RowLabelsContainer
                className='content--left'
                columnWidth={columnWidth}
                headerHeight={TOP_HEADER_HEIGHT}
                height={props.height - FOOTER_HEIGHT}
                ref={labelsLeftRef}
                rowHeight={ROW_HEIGHT}
                rowLabels={props.rowLabels}
                width={LEFT_HEADER_WIDTH}
            />
            <div className='content--right'>
                <DendrogramContainer
                    className='header--top'
                    columnWidth={columnWidth}
                    height={TOP_HEADER_HEIGHT}
                    infoMaxHeight={props.height - FOOTER_HEIGHT}
                    isLoading={props.isLoading}
                    ref={headerTopRef}
                    leafRowHeight={DENDROGRAM_LEAF_ROW_HEIGHT}
                    countRowHeight={DENDROGRAM_CELL_COUNT_ROW_HEIGHT}
                    scrollLeft={scrollLeft}
                    onLoad={onDendrogramLoad}
                    width={props.width - LEFT_HEADER_WIDTH}
                />
                {/* HACK!!! Waiting for `dendrogramLoaded` fixes a race condition I don't fully understand */}
                {dendrogramLoaded && (
                    <>
                        <ContentGridContainer
                            columnLabelAccessor={SAMPLING_STRATEGY_COLUMN_LABEL_ACCESSOR}
                            columnWidth={columnWidth}
                            minColor={minColor}
                            height={props.height - TOP_HEADER_HEIGHT - FOOTER_HEIGHT}
                            matrix={props.matrix}
                            matrixRowIndexRangesWithData={matrixRowIndexRangesWithData}
                            ref={contentGridRef}
                            onLoad={props.onLoad}
                            rowHeight={ROW_HEIGHT}
                            rowLabelAccessor={SAMPLING_STRATEGY_ROW_LABEL_ACCESSOR}
                            scrollLeft={scrollLeft}
                            scrollTop={scrollTop}
                            sparseMatrix={formattedSparseMatrix}
                            tooltipContent={TooltipContent}
                            updateScroll={updateScroll}
                            width={props.width - LEFT_HEADER_WIDTH}
                        />
                        <ScaleBarContainer
                            colorScheme={colorScale}
                            minValue={featureSetRange.min}
                            maxValue={featureSetRange.max}
                            reverseScheme
                            title={SAMPLING_STRATEGY_SCALE_BAR_TITLE}
                            width={(props.width - LEFT_HEADER_WIDTH) * 0.33}
                            height={SCALE_HEIGHT}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default SamplingStrategyTable;

SamplingStrategyTable.propTypes = {
    height: PropTypes.number,
    isLoading: PropTypes.bool,
    matrix: PropTypes.arrayOf(PropTypes.array),
    onLoad: PropTypes.func,
    rowLabels: PropTypes.array,
    width: PropTypes.number,
};
