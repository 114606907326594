import assign from 'lodash/assign';

import { RECEIVE_MARKER_GENES } from '../actions/fetch-actions';
import { MARKER_GENE_ACCESSOR } from '../constants';

const receiveMarkerGenesReducer = (state, action) => {
    const {
        data: {
            markerGenes: {
                markers,
            },
        },

        metadata: { selectedDataset },
    } = action;

    return assign({}, state,
        { [selectedDataset]: markers.map(d => d[MARKER_GENE_ACCESSOR]) },
    );
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 * Dataset specific store items are created lazily when set into state
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createMarkerGeneReducer() {
    return (state = {}, action) => {
        switch (action.type) {
            case RECEIVE_MARKER_GENES:
                return receiveMarkerGenesReducer(state, action);

            default:
                return state;
        }
    };
}
