import hasIn from 'lodash/hasIn';

// HACK
// I had a lot of trouble creating a way to utilize the "getBoundingClientRect" method from an HTML element
// given that across the codebase we use React refs, synthetic react components and native HTML elements.
// This function duck-types the container object and returns the element who has the "getBoundingClientRect"
// method attached to it. Hoping to find a better way, but this works for now.
function getEl(container) {
    const isSyntheticElement = !!hasIn(container, ['current', 'view', 'getBoundingClientRect']);
    const isRef = !!hasIn(container, ['current', 'getBoundingClientRect']);
    const isNative = !!hasIn(container, ['getBoundingClientRect']);

    if (isSyntheticElement) return container.current.view;
    if (isRef) return container.current;
    if (isNative) return container;

    throw new Error('Invalid container: Not able to assess event position');
}

/**
 * Higher order function
 * returns an event callback to determine the relative x/y position of the event within the container
 *
 * @param {Object} containerRef - an html container reference
 */
export default function getEventPositionFromElementFunction(container) {
    return event => {
        const el = getEl(container);
        const { left, top } = el.getBoundingClientRect();

        return {
            x: event.clientX - left + el.scrollLeft,
            y: event.clientY - top + el.scrollTop,
        };
    };
}
