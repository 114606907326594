import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

/**
 * Some simple logic for getting a class name to pass to a react component.
 *
 * @param {String} baseClassName The non-optional class name to be included
 * @param {String} optionalClassName An optional string of class names to append to the base class name
 */
const getClassName = (baseClassName, optionalClassName) => {
    const classes = [];

    if (isString(baseClassName) && baseClassName.length > 0) {
        classes.push(baseClassName);
    }

    if (isString(optionalClassName) && optionalClassName.length > 0) {
        classes.push(optionalClassName);
    } else if (isArray(optionalClassName)) {
        optionalClassName.forEach(name => {
            if (isString(name) && name.length > 0) {
                classes.push(name);
            }
        });
    }


    return classes.join(' ');
};

export default getClassName;
