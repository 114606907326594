import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { createSelector } from 'reselect';
import { cluster } from 'd3-hierarchy';

import {
    LABEL_HEIGHT_LIMIT_MAP,
    TAXONOMY_LABEL_ACCESSOR,
    TEXT_DATA_EXCEPTION_SEA_AD,
} from '../constants';
import {
    getTaxonomyByDataset,
    getTaxonomyLeaves,
} from './taxonomy-selectors';
import {
    getSelectedDataset,
    getSelectedDatasetName,
    getSelectedClustersByDataset,
} from '.';

export const selectedVisualization = state => state.selectedVisualization;
export const getTaxonomyFetchState = state => state.dendrogram.taxonomyFetchState;
export const getDendrogramDimensions = state => state.dendrogram.dimensions;
export const getDendrogramIsMinimizedSelectionPane = state => state.dendrogram.isMinimizedSelectionPane;

export const getTooltipEnabled = createSelector(
    [getSelectedClustersByDataset],
    clusterSelection => isNil(clusterSelection),
);

export const getIsMinimizedSelectionPaneForDataset = createSelector(
    [getSelectedDatasetName, getDendrogramIsMinimizedSelectionPane],
    (selectedDataset, isMinimizedSelectionPane) => get(isMinimizedSelectionPane, [selectedDataset]),
);

export const getTaxonomyCluster = createSelector(
    [getDendrogramDimensions, getTaxonomyByDataset, getTaxonomyLeaves],
    (dims, hierarchy, leaves) => {
        if (!get(dims, ['columnWidth']) || !hierarchy || !leaves) {
            return null;
        }

        const width = dims.columnWidth * leaves.length;
        const height = dims.height - dims.rowHeight - dims.paddingTop;
        const layoutFunction = cluster()
            .size([width, height])
            .separation(() => 1);

        return layoutFunction(hierarchy);
    }
);

export const getEdgeData = createSelector(
    [getTaxonomyCluster, getDendrogramDimensions],
    (taxonomyCluster) => taxonomyCluster && taxonomyCluster.links(),
);

export const getNodeData = createSelector(
    [getTaxonomyCluster, getDendrogramDimensions],
    (taxonomyCluster) => taxonomyCluster && taxonomyCluster.descendants().filter(d => !!d.children),
);

export const getNodeLabelHeightThreshold = createSelector(
    [getSelectedDataset, selectedVisualization],
    (dataset, visualization) => get(LABEL_HEIGHT_LIMIT_MAP, [dataset.getSpecies(), visualization], null),
);

export const nodeTextIsSpecialCase = (selectedDataset, d) => {
    if (selectedDataset.name === 'Human-MTG-10x_SEA-AD') {
        return TEXT_DATA_EXCEPTION_SEA_AD.includes(d.data.alias);
    }
    return selectedDataset.name === 'aibs_human_m1_10x' && d.data.alias === 'L5 ET';
};

export const getNodeTextData = createSelector(
    [getNodeData, getNodeLabelHeightThreshold, getSelectedDataset],
    (nodeData, nodeLabelHeightThreshold, selectedDataset) => nodeData && nodeData.filter((d) => {
        const labelExists = !!get(d, ['data', TAXONOMY_LABEL_ACCESSOR]);
        const passesHeightThreshold = d.height > nodeLabelHeightThreshold;

        // SPECIAL CASE FOR SHOWING INDIVIDUAL ALIAS
        const isSpecialCase = nodeTextIsSpecialCase(selectedDataset, d);


        return labelExists && (passesHeightThreshold || isSpecialCase);
    }),
);
