/**
 * Checks the count and applies the suffix if plural.
 *
 * @param {number} count
 * @param {string} noun
 * @param {string} suffix
 */
export default function pluralizeString(count, noun, suffix = 's') {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
}
