export const CENTRAL_TENDENCY_AGGREGATION_MEASURES = {
    MEDIANS: {
        label: 'Median Expression Value', // used in tooltips
        title: 'Median', // used in scale-bar
    },
    TRIMMED_MEANS: {
        label: 'Trimmed Mean Expression', // used in tooltips
        title: 'Trimmed Mean (25%-75%)', // used in scale-bars
    },
};

export default {
    CENTRAL_TENDENCY_AGGREGATION_MEASURES
};
