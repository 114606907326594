import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import includes from 'lodash/includes';
import assign from 'lodash/assign';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import isString from 'lodash/isString';

// UTILS
import getRowLabel from '../utils/get-row-label';
import {
    buildMatrixIndexRangesWithData,
    buildSparseFlatFeatureSetMatrix,
} from '../utils/grid-utils';
import roundDigits from '../utils/round-digits';

// SELECTORS
import {
    getSelectedDataset,
    getSelectedDatasetName,
    getClusterCounts,
} from '.';
import {
    getTaxonomyLeaves,
    getLeafLabels,
} from './taxonomy-selectors';
import { applyColorScale } from './color-scale-selectors';

// CONSTANTS
import {
    CENTRAL_TENDENCY_AGGREGATION_MEASURES,
    MARKER_GENE_ROW_LABEL_HEADER,
    MARKER_GENE_ROW_LABEL,
    TAXONOMY_LABEL_ACCESSOR,
    USER_GENE_ROW_LABEL,
    NOT_AVAILABLE,
} from '../constants';

const DEFAULT_HIDDEN_MARKER_GENES = {};
const DEFAULT_FETCHED_USER_GENES = [];

export const getMarkerGenesFetchState = state => state.heatmap.markerGenesFetchState;
export const getFeatureSetRangeFetchState = state => state.heatmap.featureSetRangeFetchState;
export const getFeatureSetRange = state => state.heatmap.featureSetRange;
export const getMarkerGenes = state => state.heatmap.markerGenes;
export const getUserGeneMatrix = state => state.heatmap.userGeneMatrix;
export const getMarkerGeneMatrix = state => state.heatmap.markerGeneMatrix;
export const getMarkerGeneMatrixFetchState = state => state.heatmap.markerGeneMatrixFetchState;
export const getUserGeneMatrixFetchState = state => state.heatmap.userGeneMatrixFetchState;
export const getGeneSearch = state => state.geneSearch;
export const getLabelMenuData = state => state.heatmap.labelMenuData;
export const getHiddenMarkerGenes = state => get(state, 'heatmap.hiddenMarkerGenes', DEFAULT_HIDDEN_MARKER_GENES);
export const getFetchedUserGenes = state => get(state, 'heatmap.fetchedUserGenes', DEFAULT_FETCHED_USER_GENES);

const getExpressionTitle = createSelector(
    [getSelectedDataset],
    selectedDataset => {
        const measure = selectedDataset.getDefaultCentralMeasure();
        return get(CENTRAL_TENDENCY_AGGREGATION_MEASURES, [measure, 'label'], '');
    }
);

export const getHiddenMarkerGenesByDataset = createSelector(
    [getHiddenMarkerGenes, getSelectedDatasetName],
    (hiddenMarkerGenes, selectedDataset) => {
        return get(hiddenMarkerGenes, selectedDataset, []);
    }
);

export const getAllMarkerGenesByDataset = createSelector(
    [getMarkerGenes, getSelectedDatasetName],
    (markerGenes, selectedDataset) => get(markerGenes, selectedDataset, [])
);

export const getMarkerGenesByDataset = createSelector(
    [getAllMarkerGenesByDataset, getHiddenMarkerGenesByDataset],
    (allMarkerGenes, hiddenMarkerGenes) => {
        if (!hiddenMarkerGenes.length) {
            return allMarkerGenes;
        }
        return allMarkerGenes.filter(geneSymbol => !includes(hiddenMarkerGenes, geneSymbol));
    }
);

export const getFeatureSetByDataset = createSelector(
    [getUserGeneMatrix, getMarkerGeneMatrix, getMarkerGenesByDataset, getLeafLabels, getSelectedDatasetName],
    (userGeneMatrix, markerGeneMatrix, markerGenes, leafClusterLabels, selectedDataset) => {
        const userGeneData = get(userGeneMatrix, selectedDataset, {});
        const unfilteredMarkerGeneData = get(markerGeneMatrix, selectedDataset, {});
        const markerGeneData = reduce(
            unfilteredMarkerGeneData,
            (clusterData, genes, cluster) => {
                clusterData[cluster] = pick(genes, markerGenes);

                return clusterData;
            },
            {}
        );

        const featureSet = {};

        for (let i = 0; i < leafClusterLabels.length; i++) {
            const leafClusterLabel = leafClusterLabels[i];

            const userGenesByLabel = get(userGeneData, leafClusterLabel, {});
            const markerGenesByLabel = get(markerGeneData, leafClusterLabel, {});

            featureSet[leafClusterLabel] = assign({}, userGenesByLabel, markerGenesByLabel);
        }

        return featureSet;
    }
);

export const getUserGeneSymbols = createSelector(
    [getSelectedDatasetName, getGeneSearch],
    (selectedDataset, geneSearch) => {
        const userGenes = get(geneSearch, [selectedDataset, 'HEATMAP', 'userGenes'], []);
        const userGeneSymbols = userGenes.map(obj => {
            return obj.symbol;
        });

        return userGeneSymbols;
    }
);

export const getUserGeneSymbolsToFetch = createSelector(
    [getUserGeneSymbols, getFetchedUserGenes],
    (userGeneSymbols, fetchedUserGenes) => userGeneSymbols.filter(geneSymbol => !includes(fetchedUserGenes, geneSymbol))
);

export const getRowLabels = createSelector(
    [getUserGeneSymbols, getMarkerGenesByDataset],
    (userGeneSymbols, datasetMarkerGenes) => {
        const userGeneLabels = userGeneSymbols.map(text => getRowLabel(text, { type: USER_GENE_ROW_LABEL }));
        const markerGeneLabelHeader = getRowLabel(MARKER_GENE_ROW_LABEL_HEADER, { type: MARKER_GENE_ROW_LABEL_HEADER }, true);
        const markerGeneLabels = datasetMarkerGenes.map(text => getRowLabel(text, { type: MARKER_GENE_ROW_LABEL }));

        return [...userGeneLabels, markerGeneLabelHeader, ...markerGeneLabels];
    }
);

export const getFeatureSetMatrix = createSelector(
    [
        getFeatureSetByDataset,
        getFeatureSetRange,
        getTaxonomyLeaves,
        getRowLabels,
        getExpressionTitle,
        getClusterCounts,
    ],
    (featureSet, featureSetRange, taxonomyLeaves, rowLabels, expressionTitle, clusterCounts) => {
        const taxonomyData = taxonomyLeaves && taxonomyLeaves.map(leaf => get(leaf, 'data'));

        if (!taxonomyData || !featureSetRange || isEmpty(featureSet)) {
            return undefined;
        }

        const featureSetMatrix = [];

        // for loop is used to transpose `featureSet` data from API and compute matrix cell data
        for (let rowIndex = 0; rowIndex < rowLabels.length; rowIndex += 1) {
            const rowLabel = rowLabels[rowIndex];

            if (rowLabel.isHeader) {
                // if row label is a header, then push an empty row for white space
                featureSetMatrix.push([]);
            } else {
                const matrixRow = [];

                for (let columnIndex = 0; columnIndex < taxonomyData.length; columnIndex += 1) {
                    const columnDatum = taxonomyData[columnIndex];
                    const clusterLabel = columnDatum[TAXONOMY_LABEL_ACCESSOR];
                    const clusterCount = get(clusterCounts, [clusterLabel], NOT_AVAILABLE);
                    const value = isString(clusterCount) ? clusterCount : roundDigits(Number(get(featureSet, [clusterLabel, rowLabel.text], featureSetRange.min)), 2);

                    const cellDatum = {
                        clusterLabel,
                        expressionTitle,
                        geneLabel: rowLabel.text,
                        value,
                        valueExtent: [featureSetRange.min, featureSetRange.max],
                    };

                    matrixRow.push(cellDatum);
                }

                featureSetMatrix.push(matrixRow);
            }
        }

        return featureSetMatrix;
    }
);

// selector to get flat & sparse version of matrix.
// only cell values larger than the minimum are included.
export const getSparseFlatFeatureSetMatrix = createSelector(
    [getFeatureSetMatrix, getFeatureSetRange],
    (matrix, featureSetRange) => {
        return buildSparseFlatFeatureSetMatrix(matrix, featureSetRange);
    }
);

// some matrix rows are empty placeholders,
// this selector gives us ranges of non-empty rows
// useful for creating backgrounds behind sparsely rendered cells
export const getMatrixIndexRangesWithData = createSelector(
    [getFeatureSetMatrix],
    (matrix) => {
        return buildMatrixIndexRangesWithData(matrix);
    }
);

// selector that takes in the current lowest value
// and applies it to the current colorscale to get lowest color value.
export const getCurrentMinColorValue = createSelector(
    [getFeatureSetRange, applyColorScale],
    (featureSetRange, colorScale) => {
        return (
            featureSetRange &&
            colorScale(featureSetRange.min, [featureSetRange.min, featureSetRange.max])
        );
    },
);
