import { createSelector } from 'reselect';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import assign from 'lodash/assign';

// CONSTANTS
import {
    SPECIMEN_AGGREGATE_PROPERTIES,
    COLUMN_PROPERTY,
    SAMPLING_STRATEGY_FEATURE_SET_RANGE,
    SAMPLING_STRATEGY_RANGE_ARRAY,
    ZERO,
} from '../constants';

// UTILS
import getRowLabel from '../utils/get-row-label';
import {
    buildMatrixIndexRangesWithData,
    buildSparseFlatFeatureSetMatrix,
} from '../utils/grid-utils';

// SELECTORS
import {
    getSelectedDatasetName,
    getClusterCounts,
} from '.';
import { getLeafLabels } from './taxonomy-selectors';
import { applyColorScale } from './color-scale-selectors';

// select off of state directly
export const getSpecimenAggregate = state => state.samplingStrategy.specimenAggregate;
export const getSpecimenAggregateFetchState = state => state.samplingStrategy.specimenAggregateFetchState;

/**
 * Return the key/value pair received from the API
 * where each key is the 'alias' from SPECIMEN_AGGREGATE_PROPERTIES
 * and the value is an array of aggregate objects.
 * We add two additional properties for rowLabel and columnLabel.
 */
export const getSpecimenAggregateByDataset = createSelector(
    [getSelectedDatasetName, getSpecimenAggregate],
    (selectedDataset, specimenAggregate) => {
        const data = get(specimenAggregate, selectedDataset, null);
        const specimentAggregatePropertiesByAlias = SPECIMEN_AGGREGATE_PROPERTIES.reduce((acc, p) => {
            acc[p.alias] = p;
            return acc;
        }, {});

        if (isNil(data)) {
            return null;
        }

        const findPropertyWithKey = (d, key) => find(d.properties, ({ property }) => property === key);

        // Map over the values from API data.
        return mapValues(data, (propertyData, key) => propertyData.map(d => {
            const specimenAggregatePropertyForKey = specimentAggregatePropertiesByAlias[key];

            // Return a new object with additional information.
            return assign({}, d, {
                rowLabel: get(findPropertyWithKey(d, specimenAggregatePropertyForKey.property), 'value'),
                columnLabel: get(findPropertyWithKey(d, COLUMN_PROPERTY), 'value'),
            });
        }));
    }
);

export const getMaxValueSpecimenAggregateByDataset = createSelector(
    [getSpecimenAggregateByDataset],
    (specimenAggregate) => {
        if (isNil(specimenAggregate)) {
            return 0;
        }
        return mapValues(specimenAggregate, (propertyData) => {
            return propertyData.reduce((max, d) => Math.max(max, d.count), -Infinity);
        });
    }
);

/**
 *
 * @returns {String} with format "rgb(<number>, <number>, <number)"
 */
export const getCurrentMinColorValue = createSelector(
    [applyColorScale],
    (colorScale) => (
        colorScale(SAMPLING_STRATEGY_FEATURE_SET_RANGE.min, SAMPLING_STRATEGY_RANGE_ARRAY)
    )
);

/**
 * Returns an array of rowLabel objects
 */
export const getRowLabels = createSelector(
    [getSpecimenAggregateByDataset, getMaxValueSpecimenAggregateByDataset],
    (specimenAggregate, maxValueSpecimenAggregateByDataset) => {
        const rowLabels = [];

        /**
         * this process is easier to do in a for loop
         * 1. get the row label header (PROPERTY)
         * 2. get the row labels for the group from the term aggregation data
         */
        for (let i = 0; i < SPECIMEN_AGGREGATE_PROPERTIES.length; i += 1) {
            // 1.
            // row label header property name
            const { alias, displayName, property } = SPECIMEN_AGGREGATE_PROPERTIES[i];
            const headerData = {
                maxValue: maxValueSpecimenAggregateByDataset[alias],
                alias,
                property,
                displayName,
            };

            const rowLabelHeader = getRowLabel(displayName, headerData, true);

            rowLabels.push(rowLabelHeader);

            // 2.
            // get all row data for this row header group
            const propertyData = get(specimenAggregate, alias, []);

            // get distinct labels for this row group. Sort happens in the graphql api
            const propertyRowLabels = uniq(propertyData.map(d => d.rowLabel));

            propertyRowLabels.forEach(label => {
                const rowLabel = getRowLabel(label);

                rowLabels.push(rowLabel);
            });
        }

        return rowLabels;
    }
);

/**
 * Returns a matrix array of objects containing count, countMax, rowLabel, columnLabel
 */
export const getSpecimenAggregateMatrix = createSelector(
    [getRowLabels, getSpecimenAggregateByDataset, getLeafLabels, getClusterCounts],
    (rowLabels, specimenAggregate, taxonomyLabels, totalClusterCounts) => {
        if (isEmpty(taxonomyLabels) || isEmpty(specimenAggregate)) {
            return undefined;
        }

        // compute the max count for each PROPERTY group
        // return the results as a dictionary
        const maxCountByHeader = reduce(specimenAggregate, (maxByGroup, values, key) => {
            const specimenAggregatePropertyForKey = find(SPECIMEN_AGGREGATE_PROPERTIES, ({ alias }) => alias === key);

            maxByGroup[specimenAggregatePropertyForKey.alias] = values.reduce((acc, d) => Math.max(acc, d.count), -Infinity);
            return maxByGroup;
        }, {});

        // compute row data as key/value pairs.
        // key is the alias from SPECIMEN_AGGREGATE_PROPERTIES
        // values are an array
        const rowDataByLabel = SPECIMEN_AGGREGATE_PROPERTIES.reduce(
            (acc, { alias }) => {
                const propertyData = specimenAggregate[alias];
                const rowGroupData = groupBy(propertyData, 'rowLabel');
                acc[alias] = rowGroupData;

                return acc;
            },
            {}
        );

        // building this matrix is easier to understand in a loop
        const specimenAggregateMatrix = [];
        // current header for the group of row labels
        let currentHeaderAlias;
        let currentHeaderDisplayName;
        // iterate across row labels
        for (let rowIndex = 0; rowIndex < rowLabels.length; rowIndex += 1) {
            const rowLabel = rowLabels[rowIndex];

            if (rowLabel.isHeader) {
                // if row label is a header, then push an empty row for white space and set current header
                specimenAggregateMatrix.push([]);
                currentHeaderAlias = rowLabel.data.alias;
                currentHeaderDisplayName = rowLabel.data.displayName;
            } else {
                const matrixRow = [];
                // grouping row data by column label allows easy look up and preserves column order
                const rowDataForHeader = groupBy(rowDataByLabel[currentHeaderAlias][rowLabel.text], 'columnLabel');
                // iterate across ordered columns
                for (let columnIndex = 0; columnIndex < taxonomyLabels.length; columnIndex += 1) {
                    const columnLabel = taxonomyLabels[columnIndex];
                    // if no cell, count is zero
                    const cellDatum = get(rowDataForHeader, [columnLabel, 0], false) || { rowLabel: rowLabel.text, columnLabel, count: ZERO };
                    cellDatum.countMax = maxCountByHeader[currentHeaderAlias];
                    cellDatum.labelHeader = currentHeaderDisplayName;

                    cellDatum.totalCellsInCluster = totalClusterCounts[columnLabel] || ZERO;
                    // if total cells in cluster is positive, compute percent, otherwise use zero.
                    cellDatum.percentCellsInCluster = Math.round((cellDatum.count / cellDatum.totalCellsInCluster) * 1000) / 10 || ZERO;

                    // properties used heatmap-grid-composite-layer to appropriately draw grid cell color
                    cellDatum.value = cellDatum.percentCellsInCluster;
                    cellDatum.valueExtent = SAMPLING_STRATEGY_RANGE_ARRAY;

                    matrixRow.push(cellDatum);
                }

                specimenAggregateMatrix.push(matrixRow);
            }
        }
        return specimenAggregateMatrix;
    }
);

// selector to get flat & sparse version of matrix.
// only cell values larger than the minimum are included.
export const getSparseFlatFeatureSetMatrix = createSelector(
    [getSpecimenAggregateMatrix],
    (matrix) => {
        return buildSparseFlatFeatureSetMatrix(matrix, SAMPLING_STRATEGY_FEATURE_SET_RANGE);
    }
);

// some matrix rows are empty placeholders,
// this selector gives us ranges of non-empty rows
// useful for creating backgrounds behind sparsely rendered cells
export const getMatrixIndexRangesWithData = createSelector(
    [getSpecimenAggregateMatrix, getSelectedDatasetName],
    (matrix, selectedDataset) => {
        return buildMatrixIndexRangesWithData(matrix, selectedDataset);
    }
);
