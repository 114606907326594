import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';

import useWindowHeight from '../../utils/use-window-height';
import './style.scss';

/**
 * A side effect that returns the calculated content height of this component when opened.
 *
 * @param {function} setContentHeight React hook used to update the contentHeight
 * @param {object} contentRef A ref to the content container
 * @param {object} headerRef A ref to the drawer header.
 */
const onContentChanged = (setContentHeight, contentRef, headerRef) => {
    const contentRect = contentRef.current.getBoundingClientRect();
    const headerRect = headerRef.current.getBoundingClientRect();

    setContentHeight(contentRect.height + headerRect.height);
};

/**
 * Drawer component
 *
 * @param {array} props.children The elements nested inside this drawer.
 * @param {element} props.headerLeftContent Elements to be placed in the left half of the drawer header.
 * @param {element} props.headerRightContent Elements to be placed in the right half of the drawer header.
 */
const Drawer = (props) => {
    const {
        children,
        headerLeftContent,
        headerRightContent
    } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(null);
    const windowHeight = useWindowHeight();

    const contentRef = useRef();
    const headerRef = useRef();

    useEffect(() => onContentChanged(setContentHeight, contentRef, headerRef), [children]);

    const heightStyle = isOpen ? { height: Math.min(windowHeight, contentHeight) } : null;

    return (
        <div
            className={isOpen ? 'drawer__expanding-container--open' : 'drawer__expanding-container--closed'}
            style={heightStyle}>
            <div className='drawer__header' ref={headerRef}>
                <div className='drawer__header-content--left' >
                    { headerLeftContent}
                </div>
                <div className={isOpen ? 'drawer__header-toggle--closed' : 'drawer__header-toggle--open'}
                    onClick={() => setIsOpen(!isOpen)}>
                    <i className='fas fa-chevron-up' />
                </div>
                <div className='drawer__header-content--right'>
                    { headerRightContent }
                </div>
            </div>
            <div className='drawer__content' ref={contentRef}>
                { children }
            </div>
        </div>
    );
};

Drawer.propTypes = {
    children: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node
    ]),
    headerLeftContent: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node
    ]),
    headerRightContent: propTypes.oneOfType([
        propTypes.arrayOf(propTypes.node),
        propTypes.node
    ])
};

export default Drawer;
