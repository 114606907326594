import isNil from 'lodash/isNil';

/**
 * Util for getItem from local storage
 * @param {sting} key - storage key
 * @param {object or array} defaultValue - the default reponse if there is no local data for key
 */
export default function getFromLocal(key, defaultValue = [], parseJSON = true) {
    const data = localStorage.getItem(key);

    if (isNil(data)) {
        return defaultValue;
    }

    return parseJSON ? JSON.parse(data) : data;
}
