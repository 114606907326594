import assign from 'lodash/assign';
import reduce from 'lodash/reduce';
import { combineReducers } from 'redux';

import {
    CHANGE_DATASET,
    CHANGE_VISUALIZATION,
} from '../actions';
import { VISUALIZATIONS } from '../constants';
import availableDatasets from './available-datasets';
import scatterPlot from './scatter-plot-reducer';
import errors from './errors-reducer';
import fetch from './fetch-reducer';
import geneSearch from './gene-search-reducer';
import heatmap from './heatmap-reducer';
import dendrogram from './dendrogram-reducer';
import samplingStrategy from './sampling-strategy-reducer';
import tooltip from './tooltip-reducer';
import geneValidation from './gene-validation-reducer';
import taxonomy from './taxonomy-reducer';
import clusterSelection from './cluster-selections-reducer';
import markerGenes from './marker-genes-reducer';
import colorBy from './color-by-reducer';
import clusterCounts from './cluster-counts-reducer';
import colorScale from './color-scale-reducer';
import grid from './grid-reducer';

// Default selected dataset uses first item in datasets array
const selectedDataset = ({
    datasets: [defaultDataset],
    selectedDataset,
}) => {
    const initialState = selectedDataset || defaultDataset;

    return (state = initialState, action) => {
        switch (action.type) {
            case CHANGE_DATASET:
                return action.selectedDataset;
            default:
                return state;
        }
    };
};

const selectedVisualization = (metadata) => {
    const initialState = metadata.selectedVisualization || VISUALIZATIONS.HEATMAP;

    return (state = initialState, action) => {
        switch (action.type) {
            case CHANGE_VISUALIZATION:
                return action.selectedVisualization;
            default:
                return state;
        }
    };
};

const reducerCreators = {
    availableDatasets,
    clusterCounts,
    clusterSelection,
    colorBy,
    colorScale,
    dendrogram,
    errors,
    fetch,
    geneSearch,
    geneValidation,
    grid,
    heatmap,
    markerGenes,
    samplingStrategy,
    selectedDataset,
    selectedVisualization,
    taxonomy,
    tooltip,
    scatterPlot,
};

/**
 * Takes in store metadata and initiates the redux store with it.
 *
 * @param {object} storeMetadata { [string]: object | Array<object> }
 */
export default function createRootReducer(metadata) {
    // Apply metadata to each reducer and accumulate in an object.
    const reducers = reduce(
        reducerCreators,
        (acc, reducerCreator, reducerName) => assign(acc, { [reducerName]: reducerCreator(metadata) }),
        {},
    );

    return combineReducers(reducers);
}
