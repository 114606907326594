import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';

export { default as fetchStoreMetadata } from './store-metadata';

const middlewares = [
    thunk,
];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function createStoreWithMetadata(metadata) {
    // Apply metadata to any reducer that needs to initialize with it.
    return createStore(
        createRootReducer(metadata),
        composeEnhancers(applyMiddleware(...middlewares))
    );
}
