import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import DeckGL from '@deck.gl/react';
import { OrthographicView, COORDINATE_SYSTEM } from '@deck.gl/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ScatterplotLayer from '../../custom-layers/selection-optimized-scatterplot-layer';

const PICKING_RADIUS = 0.01;

const ScatterPlotChart = (props) => {
    const {
        pointData,
        selectedClustersHash,
        height,
        scatterData,
        width,
        viewState,
        viewStateChangeCB,
    } = props;

    // Use ref for static methods.
    const deckglRef = useRef(null);
    const selectionExists = !isEmpty(selectedClustersHash) ? 1 : 0;

    const onHover = (d) => {
        const hoveredId = get(pointData, ['cluster_ids', d.index], null);

        if (hoveredId) {
            props.hoveredClusterCB([hoveredId]);
        } else {
            props.hoveredClusterCB([]);
        }
    };

    const onClick = (_, e) => {
        props.selectClusterCB(props.hoveredClusters[0], e);

        // Returns a truthy value.
        // The click event is marked as handled and will not bubble up to the onClick callback of the DeckGL canvas.
        // https://github.com/uber/deck.gl/blob/master/docs/api-reference/layer.md#onclick-function-optional
        return true;
    };

    const layer = new ScatterplotLayer({
        id: 'scatterplot-layer',
        coordinateSystem: COORDINATE_SYSTEM.CARTESIAN,
        data: scatterData,
        pickable: true,
        filled: true,
        stroked: true,
        colorFormat: 'RGB',
        radiusScale: 0.09,
        radiusMinPixels: 1,
        radiusMaxPixels: 4,
        lineWidthScale: 0.03,
        lineWidthMinPixels: 0.5,
        lineWidthMaxPixels: 1,
        onHover,
        onClick,
        uniforms: { selectionExists },
    });

    const view = new OrthographicView({
        controller: {
            doubleClickZoom: false,
            keyboard: false,
        },
    });

    // Update zoom/pan
    const stateChange = useCallback((e) => viewStateChangeCB(e.viewState), [viewStateChangeCB]);

    // Use 'arrow' cursor.
    const getCursor = useCallback(() => 'auto', []);

    // Memoized style object.
    const wrapperHeight = useMemo(() => ({ height }), [height]);

    return (
        <div className='scatter-plot__plot' style={wrapperHeight}>
            <DeckGL
                getCursor={getCursor}
                useDevicePixels={false}
                height={height}
                width={width}
                layers={layer}
                viewState={viewState}
                views={view}
                onViewStateChange={stateChange}
                pickingRadius={PICKING_RADIUS}
                ref={deckglRef}
                onClick={onClick}
            />
            {props.children}
        </div>
    );
};

export default ScatterPlotChart;

ScatterPlotChart.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    pointData: PropTypes.shape({
        x_y_coords: PropTypes.array,
        cluster_ids: PropTypes.array,
        x_min_max: PropTypes.array,
        y_min_max: PropTypes.array,
        cell_labels: PropTypes.array,
    }),
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    topOffset: PropTypes.number,
    scatterData: PropTypes.shape({
        length: PropTypes.number,
        attributes: PropTypes.shape({
            getPosition: PropTypes.shape({
                value: PropTypes.object, // Float32Array
                size: PropTypes.number,
            }),
            getFillColor: PropTypes.shape({
                value: PropTypes.object, // Float32Array
                size: PropTypes.number,
                normalized: PropTypes.bool,
            }),
            getSelected: PropTypes.shape({
                value: PropTypes.object, // UInt8Array
                size: PropTypes.number,
            }),
        }),
    }),
    selectedClustersHash: PropTypes.object,
    selectClusterCB: PropTypes.func,
    hoveredClusters: PropTypes.array,
    hoveredClusterCB: PropTypes.func,
    viewStateChangeCB: PropTypes.func,
    viewState: PropTypes.object,
};
