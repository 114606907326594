import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';

import { BRAIN_MAP_URL } from '../../constants';

import './style.scss';

const NotFound = (props) => {
    const path = get(props, ['location', 'pathname'], null);

    return (
        <div className='not-found__container'>
            <div className='not-found__header'>404</div>
            <div className='not-found__sub-head'>
                <span className='not-found__path'>{path}</span> does not exist.</div>
            <ul className='not-found__links'>
                <li className='not-found__home-link'>
                    <a href={BRAIN_MAP_URL}>Brain Map</a>
                </li>
                <li className='not-found__tv-data'>
                    <Link className='not-found__tv-label' to='/'>Transcriptomics Viewer</Link>
                </li>
            </ul>
        </div>
    );
};

export default NotFound;
