import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

// ACTIONS
import { tooltipUpdate, tooltipHide } from '../../actions/tooltip-actions';
import { updateHoveredRow } from '../../actions/grid-actions';

// UTILS
import getEventPositionFromElementFunction from '../../utils/get-event-position-from-element';
import getMatrixIndicesFromEventFunction from '../../utils/get-matrix-indices-from-event';

// COMPONENTS
import RowLabels from '../../components/row-labels';

const RowLabelsContainer = React.forwardRef((props, ref) => {
    const {
        className,
        columnWidth,
        headerHeight,
        height,
        hoveredLabel,
        labelMenuData,
        labelMenuOptionsMap,
        onLabelClick,
        rowHeight,
        rowLabels,
        tooltipContent,
        width,
    } = props;

    const dispatch = useDispatch();

    const getEventPositionFromElement = useCallback(getEventPositionFromElementFunction(ref), []);

    const getRowIndexFromEvent = useCallback(
        getMatrixIndicesFromEventFunction({ rowHeight }, getEventPositionFromElement),
        [getEventPositionFromElement, rowHeight]
    );

    const onInfoOver = useCallback((event) => {
        const { rowIndex } = getRowIndexFromEvent(event);
        const { data } = rowLabels[rowIndex];

        dispatch(tooltipUpdate({
            contentComponent: tooltipContent,
            contentProps: {
                data,
                rowHeight,
                columnWidth,
            },
            x: event.pageX,
            y: event.pageY,
        }));
    }, [dispatch, tooltipContent, rowHeight, columnWidth, getRowIndexFromEvent, rowLabels]);

    const onInfoLeave = useCallback(() => {
        dispatch(tooltipHide());
    }, [dispatch]);

    const onLabelHover = useCallback((e) => {
        const { rowIndex } = getRowIndexFromEvent(e);
        const text = get(rowLabels, [rowIndex, 'text']);

        dispatch(updateHoveredRow(text));
    }, [dispatch, rowLabels, getRowIndexFromEvent]);

    const onLabelLeave = () => {
        dispatch(updateHoveredRow(null));
    };

    return (
        <RowLabels
            className={className}
            headerHeight={headerHeight}
            height={height}
            hoveredLabel={hoveredLabel}
            labelMenuData={labelMenuData}
            labelMenuOptionsMap={labelMenuOptionsMap}
            onInfoLeave={onInfoLeave}
            onInfoOver={onInfoOver}
            onLabelClick={onLabelClick}
            onLabelHover={onLabelHover}
            onLabelLeave={onLabelLeave}
            ref={ref}
            rowHeight={rowHeight}
            rowLabels={rowLabels}
            tooltipContent={tooltipContent}
            width={width}
        />
    );
});

export default RowLabelsContainer;

RowLabelsContainer.propTypes = {
    // class name of the outer most div
    className: PropTypes.string,

    // width of columns
    columnWidth: PropTypes.number,

    // width of the outer most div
    width: PropTypes.number,

    // height of the outer most div
    height: PropTypes.number,

    // height of the header above the labels
    headerHeight: PropTypes.number,

    // hovered row label
    hoveredLabel: PropTypes.string,

    // Menu map for action options of a label
    labelMenuOptionsMap: PropTypes.object,

    // array of row labels, some of which are headers for a group of labels
    rowLabels: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        isHeader: PropTypes.bool,
    })),

    // height of each row
    rowHeight: PropTypes.number,

    // click handler for label
    onLabelClick: PropTypes.func,

    // data to be used in label menu
    labelMenuData: PropTypes.object,

    // content to pass to tooltip
    tooltipContent: PropTypes.object,
};
