import {
    BROWSER_INCOMPATIBILITY_WARNING_GIVEN_DATE_KEY,
    FETCH_STATE,
    WARNING_SELF_DISMISS_TIME,
} from '../constants';
import setLocalStorage from '../utils/set-local-storage';
import eventError from '../analytics/event-error';

export const GENERIC_FETCH_ERROR = 'GENERIC_FETCH_ERROR';
export const MISSING_TECHNOLOGY_WARNING = 'MISSING_TECHNOLOGY_WARNING';
export const DISMISS_ERROR = 'DISMISS_ERROR';

export const fetchErrorAction = (
    error,
    metadata = {},
    type = GENERIC_FETCH_ERROR,
    status = FETCH_STATE.ERROR,
) => {
    // for Google Tag Manager
    eventError(error.message, type);

    return {
        error,
        metadata,
        type,
        status,
    };
};

export const browserIncompatibilityErrorAction = (
    error,
    date,
    metadata = { selfDismissTimeout: WARNING_SELF_DISMISS_TIME },
    type = MISSING_TECHNOLOGY_WARNING,
) => {
    // Track when message was displayed in local storage
    setLocalStorage(BROWSER_INCOMPATIBILITY_WARNING_GIVEN_DATE_KEY, date);

    return {
        error,
        metadata,
        type,
    };
};

export const dismissErrorAction = index => ({
    type: DISMISS_ERROR,
    index,
});
