import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

import getGeneSymbolsQuery from '../../queries/get-gene-symbols-query';
import { CLASS_NAME_PREFIX } from '../../components/dropdown-select';
import { makeQuery, stopEventPropogation } from '../../utils';
import { fetchErrorAction } from '../../actions/errors-actions';
import { getSelectedDatasetName } from '../../selectors';

import '../../components/dropdown-select/style.scss';

/**
 * Returns a callback that requests a list of gene symbols similar to the input text.
 * @param {string} currentDataset The current dataset
 */
const getTypeAheadCallback = (dispatch, currentDataset) => async (userInput, callback) => {
    const query = getGeneSymbolsQuery(userInput, currentDataset, 10);

    try {
        const queryResults = await makeQuery(query);
        const { geneArray: geneResults } = queryResults.data;

        // Map the returned genes into options objects
        const typeAheadOptions = geneResults.map(({ symbol }) => ({ value: symbol, label: symbol }));

        callback(typeAheadOptions);
    } catch (error) {
        dispatch(fetchErrorAction(error));
        callback([]);
    }
};

// Overriding the SingleValue component puts resting text caret at end of search input
const SingleValue = props => <div>{props.children}</div>;
SingleValue.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const selectComponents = {
    SingleValue,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null
};

// Marker genes would be the default
// Load options would be a function passed in.
const GeneSearchDropdownContainer = React.forwardRef((props, innerRef) => {
    const {
        defaultOptions,
        onChangeHandler,
        defaultValue
    } = props;
    const dispatch = useDispatch();
    const selectedDataset = useSelector(getSelectedDatasetName);
    const typeAheadHandler = useCallback(getTypeAheadCallback(dispatch, selectedDataset), [selectedDataset]);

    return (
        <div className='gene-search gene-search__gui-elements'>
            <i className='fas fa-search gene-search__search-icon' />
            <AsyncSelect
                {...props}
                value=''
                ref={innerRef}
                components={selectComponents}
                defaultOptions={defaultOptions}
                loadOptions={typeAheadHandler}
                onChange={onChangeHandler}
                onKeyDown={stopEventPropogation}
                defaultValue={defaultValue}
                classNamePrefix={CLASS_NAME_PREFIX}/>
        </div>
    );
});

GeneSearchDropdownContainer.propTypes = {
    defaultOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string
        })),
    defaultValue: PropTypes.arrayOf(PropTypes.object),
    onChangeHandler: PropTypes.func.isRequired,
    selection: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string
    }),
};

export default GeneSearchDropdownContainer;
