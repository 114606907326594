import { useEffect } from 'react';
import castArray from 'lodash/castArray';
import useKeyIsDown from './use-key-is-down';

/**
 * Custom hook that takes in a key or array of key strings.
 * the given handler will execute when a key down change has occured concerning the given key(s).
 *
 * @param {string | string[]} key
 * @param {function} handler - (keyDownState) => void
 */
export default function useKeyHandler(key, handler) {
    const keyIsDown = useKeyIsDown(castArray(key));

    useEffect(
        () => {handler(keyIsDown);},
        [keyIsDown, handler],
    );
}
