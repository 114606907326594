/**
 * Find an Object's key based on its value.
 * This fnc commonly used for the objects defined in constants.js
 *
 * @param {Object} obj - an object with key-value properties
 * @param {string} value - a single value from a key-value pair
 * @returns {string} key corresponding to the value, or undefined if not found
 */
const getKeyUsingValue = (obj, value) => {
    return Object.keys(obj).find(key => obj[key] === value);
};

export default getKeyUsingValue;
