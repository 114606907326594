import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import set from 'lodash/set';

// ACTIONS
import {
    updateLabelMenuDataAction,
    hideMarkerGeneAction,
    hideAllMarkerGenesAction,
    unhideAllMarkerGenesAction,
    moveMarkerGene,
    moveUserGene,
} from '../../actions/heatmap-actions';
import { changeColorByFeatureAction } from '../../actions/color-by-actions';
import { changeVisualization } from '../../actions';
import { deleteUserGeneAction } from '../../actions/gene-search-actions';

// SELECTORS
import {
    getSelectedDataset,
    getSelectedDatasetName,
} from '../../selectors';
import {
    getLabelMenuData,
    getCurrentMinColorValue,
    getFeatureSetRange,
    getSparseFlatFeatureSetMatrix,
    getMatrixIndexRangesWithData,
} from '../../selectors/heatmap-selectors';
import {
    getColorScale,
} from '../../selectors/color-scale-selectors';
import { getHoveredGridRow } from '../../selectors/grid-selectors';

// CONSTANTS
import {
    HEATMAP_SCALE_BAR_TITLE,
    HEATMAP_COLUMN_LABEL_ACCESSOR,
    HEATMAP_ROW_LABEL_ACCESSOR,
    MARKER_GENE_ROW_LABEL,
    MARKER_GENE_ROW_LABEL_HEADER,
    USER_GENE_ROW_LABEL,
    VISUALIZATIONS,
    COLOR_BY_OPTIONS,
    CENTRAL_TENDENCY_AGGREGATION_MEASURES,
} from '../../constants';

// COMPONENTS
import ContentGridContainer from '../content-grid';
import DendrogramContainer from '../dendrogram';
import RowLabelsContainer from '../row-labels';
import { TooltipContent, getRowLabelMenuOptions } from '../../components/heatmap';
import ScaleBarContainer from '../scale-bar';

// UTILS
import arrowScrollHandler from '../../utils/arrow-scroll-handler';
import getEventPositionFromElementFunction from '../../utils/get-event-position-from-element';
import getKeyUsingValue from '../../utils/get-key-using-value';
import getMatrixIndicesFromEventFunction from '../../utils/get-matrix-indices-from-event';
import { computeMatrixColumnWidth } from '../../utils/grid-utils';

// STYLES
import { footerHeight } from '../../components/drawer/style.scss';

const SCALE_HEIGHT = 55;
const FOOTER_HEIGHT = parseInt(footerHeight) + SCALE_HEIGHT;
const MIN_COLUMN_WIDTH = 5;
const ROW_HEIGHT = 20;
const DENDROGRAM_LEAF_ROW_HEIGHT = ROW_HEIGHT;
const DENDROGRAM_CELL_COUNT_ROW_HEIGHT = ROW_HEIGHT;
const DENDROGRAM_TREE_HEIGHT = 130;
const LEFT_HEADER_WIDTH = 175;
const TOP_HEADER_HEIGHT = DENDROGRAM_TREE_HEIGHT + DENDROGRAM_LEAF_ROW_HEIGHT + DENDROGRAM_CELL_COUNT_ROW_HEIGHT;

const HeatmapTableContainer = (props) => {
    const dispatch = useDispatch();
    const columnWidth = props.matrix ? computeMatrixColumnWidth(props.width - LEFT_HEADER_WIDTH, props.matrix, MIN_COLUMN_WIDTH) : 0;
    const headerTopRef = useRef(null);
    const labelsLeftRef = useRef(null);
    const contentGridRef = useRef(null);
    const featureSetRange = useSelector(getFeatureSetRange);
    const labelMenuData = useSelector(getLabelMenuData);
    const selectedDataset = useSelector(getSelectedDatasetName);
    const selectedDatasetObject = useSelector(getSelectedDataset);
    const selectedVisualization = useSelector(state => state.selectedVisualization);
    const minColor = useSelector(getCurrentMinColorValue);
    const colorScale = useSelector(getColorScale);
    const hoveredGridRow = useSelector(getHoveredGridRow);
    const sparseMatrix = useSelector(getSparseFlatFeatureSetMatrix);
    const matrixRowIndexRangesWithData = useSelector(getMatrixIndexRangesWithData);
    const [{ scrollTop, scrollLeft }, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });
    const [dendrogramLoaded, setDendrogramLoaded] = useState(false);

    const centralTendencyProperty = selectedDatasetObject.getDefaultCentralMeasure();
    const centralTendencyTitle = get(CENTRAL_TENDENCY_AGGREGATION_MEASURES, [centralTendencyProperty, 'title']);
    const scalebarTitle = `Measure of central tendency: ${centralTendencyTitle} ${HEATMAP_SCALE_BAR_TITLE}`;
    const handleOnKeyDown = useCallback(arrowScrollHandler(contentGridRef), [contentGridRef]);

    useEffect(() => () => {
        // reset scroll state when selected dataset changes
        set(headerTopRef, ['current', 'scrollLeft'], 0);
        set(labelsLeftRef, ['current', 'scrollTop'], 0);
        // scrollbars scroll positions are setter functions
        invoke(contentGridRef, ['current', 'scrollLeft'], 0);
        invoke(contentGridRef, ['current', 'scrollTop'], 0);

        setScrollPosition({ scrollTop: 0, scrollLeft: 0 });
    }, [selectedDataset]);

    const updateScroll = useCallback((e) => {
        const eventScrollLeft = get(e, ['target', 'scrollLeft'], null);
        const eventScrollTop = get(e, ['target', 'scrollTop'], null);

        headerTopRef.current.scrollLeft = eventScrollLeft;
        labelsLeftRef.current.scrollTop = eventScrollTop;

        setScrollPosition({
            scrollLeft: eventScrollLeft,
            scrollTop: eventScrollTop,
        });
    }, []);

    const hideMarkerGene = useCallback((geneSymbol) => {
        dispatch(hideMarkerGeneAction(selectedDataset, geneSymbol));
    }, [dispatch, selectedDataset]);

    const hideAllMarkerGenes = useCallback(() => {
        dispatch(hideAllMarkerGenesAction(selectedDataset));
    }, [dispatch, selectedDataset]);

    const unhideAllMakerGenes = useCallback(() => {
        dispatch(unhideAllMarkerGenesAction(selectedDataset));
    }, [dispatch, selectedDataset]);

    const deleteUserGene = useCallback((geneSymbol) => {
        dispatch(deleteUserGeneAction(selectedDataset, getKeyUsingValue(VISUALIZATIONS, selectedVisualization), geneSymbol));
    }, [dispatch, selectedDataset, selectedVisualization]);

    const closeLabelMenu = useCallback(() => {
        dispatch(updateLabelMenuDataAction());
    }, [dispatch]);

    const colorScatterPlotByGene = useCallback((geneSymbol) => {
        dispatch(changeVisualization(VISUALIZATIONS.SCATTER_PLOT));
        dispatch(changeColorByFeatureAction(selectedDataset, COLOR_BY_OPTIONS.GENE_EXPRESSION, geneSymbol));
    }, [dispatch, selectedDataset]);

    const moveUpUserGene = useCallback((geneSymbol) => {
        dispatch(moveUserGene(selectedVisualization, selectedDataset, geneSymbol, -1));
    }, [dispatch, selectedVisualization, selectedDataset]);

    const moveDownUserGene = useCallback((geneSymbol) => {
        dispatch(moveUserGene(selectedVisualization, selectedDataset, geneSymbol, 1));
    }, [dispatch, selectedVisualization, selectedDataset]);

    const moveUpMarkerGene = useCallback((geneSymbol) => {
        dispatch(moveMarkerGene(selectedVisualization, selectedDataset, geneSymbol, -1));
    }, [dispatch, selectedVisualization, selectedDataset]);

    const moveDownMarkerGene = useCallback((geneSymbol) => {
        dispatch(moveMarkerGene(selectedVisualization, selectedDataset, geneSymbol, 1));
    }, [dispatch, selectedVisualization, selectedDataset]);

    const labelMenuOptionsMap = {
        [MARKER_GENE_ROW_LABEL]: getRowLabelMenuOptions(MARKER_GENE_ROW_LABEL, { hideMarkerGene, closeLabelMenu, selectedDatasetObject, colorScatterPlotByGene, moveUpMarkerGene, moveDownMarkerGene }),
        [MARKER_GENE_ROW_LABEL_HEADER]: getRowLabelMenuOptions(MARKER_GENE_ROW_LABEL_HEADER, { hideAllMarkerGenes, unhideAllMakerGenes }),
        [USER_GENE_ROW_LABEL]: getRowLabelMenuOptions(USER_GENE_ROW_LABEL, { deleteUserGene, closeLabelMenu, selectedDatasetObject, colorScatterPlotByGene, moveUpUserGene, moveDownUserGene }),
    };

    const eventPositionFromRowLabels = useCallback(getEventPositionFromElementFunction(labelsLeftRef), []);

    const getRowIndexFromEvent = useCallback(
        getMatrixIndicesFromEventFunction({ rowHeight: ROW_HEIGHT }, eventPositionFromRowLabels),
        [eventPositionFromRowLabels]
    );

    const onLabelClick = useCallback((e) => {
        const { clientX, clientY } = e;
        const { rowIndex } = getRowIndexFromEvent(e);
        const datum = props.rowLabels[rowIndex];
        const text = get(datum, ['text']);
        const type = get(datum, ['data', 'type']);

        if (labelMenuData) {
            dispatch(updateLabelMenuDataAction());
        } else {
            dispatch(updateLabelMenuDataAction({ top: clientY - TOP_HEADER_HEIGHT, left: clientX, text, options: labelMenuOptionsMap[type] }));
        }
    }, [labelMenuData, dispatch, labelMenuOptionsMap, getRowIndexFromEvent, props.rowLabels]);

    const onDendrogramLoad = useCallback(() => setDendrogramLoaded(true), []);

    if (!props.matrix) {
        return null;
    }

    return (
        <div
            className='heatmap__table'
            style={{ width: props.width, height: props.height }}
            tabIndex='0'
            onKeyDown={handleOnKeyDown}
        >
            <RowLabelsContainer
                className='content--left'
                columnWidth={columnWidth}
                headerHeight={TOP_HEADER_HEIGHT}
                height={props.height - FOOTER_HEIGHT}
                hoveredLabel={hoveredGridRow}
                labelMenuData={labelMenuData}
                labelMenuOptionsMap={labelMenuOptionsMap}
                onLabelClick={onLabelClick}
                ref={labelsLeftRef}
                rowHeight={ROW_HEIGHT}
                rowLabels={props.rowLabels}
                width={LEFT_HEADER_WIDTH}
            />
            <div className='content--right'>
                <DendrogramContainer
                    className='header--top'
                    columnWidth={columnWidth}
                    height={TOP_HEADER_HEIGHT}
                    infoMaxHeight={props.height - FOOTER_HEIGHT}
                    isLoading={props.isLoading}
                    ref={headerTopRef}
                    leafRowHeight={DENDROGRAM_LEAF_ROW_HEIGHT}
                    countRowHeight={DENDROGRAM_CELL_COUNT_ROW_HEIGHT}
                    scrollLeft={scrollLeft}
                    onLoad={onDendrogramLoad}
                    width={props.width - LEFT_HEADER_WIDTH}
                />
                {/* HACK!!! Waiting for `dendrogramLoaded` fixes a race condition I don't fully understand */}
                {dendrogramLoaded && (
                    <>
                        <ContentGridContainer
                            columnLabelAccessor={HEATMAP_COLUMN_LABEL_ACCESSOR}
                            columnWidth={columnWidth}
                            minColor={minColor}
                            height={props.height - TOP_HEADER_HEIGHT - FOOTER_HEIGHT}
                            matrix={props.matrix}
                            matrixRowIndexRangesWithData={matrixRowIndexRangesWithData}
                            ref={contentGridRef}
                            onLoad={props.onLoad}
                            rowHeight={ROW_HEIGHT}
                            rowLabelAccessor={HEATMAP_ROW_LABEL_ACCESSOR}
                            scrollLeft={scrollLeft}
                            scrollTop={scrollTop}
                            sparseMatrix={sparseMatrix}
                            tooltipContent={TooltipContent}
                            updateScroll={updateScroll}
                            width={props.width - LEFT_HEADER_WIDTH}
                        />
                        <ScaleBarContainer
                            colorScheme={colorScale}
                            minValue={featureSetRange.min}
                            maxValue={featureSetRange.max}
                            reverseScheme
                            precision={2}
                            title={scalebarTitle}
                            width={(props.width - LEFT_HEADER_WIDTH) * 0.33}
                            height={SCALE_HEIGHT}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default HeatmapTableContainer;

HeatmapTableContainer.propTypes = {
    height: PropTypes.number,
    isLoading: PropTypes.bool,
    matrix: PropTypes.arrayOf(PropTypes.array),
    onLoad: PropTypes.func,
    rowLabels: PropTypes.array,
    width: PropTypes.number,
};
