import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CONSTANTS
import { GENE_SEARCH } from '../../constants';

// COMPONENTS
import MultiGeneSelectionFormContainer from '../../containers/gene-search/multi-gene-selection-form-container';
import GeneSearchContainer from '../../containers/gene-search';

import './style.scss';

const UI_SEARCH = 'GENE_SEARCH';
const UI_MULTI_ADD = 'GENE_MULTI_ADD';

const GenesHeaderLabel = ({ className, onClick, text, style }) => (
    <div
        className={className}
        onClick={onClick}
        style={style}
    >
        {text}
    </div>
);

GenesHeaderLabel.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
    style: PropTypes.object,
};

const AddGenes = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [addGeneState, setAddGeneState] = useState(UI_SEARCH);
    const displayMultiAdd = isOpen && addGeneState === UI_MULTI_ADD;
    const closeUI = () => setIsOpen(false);

    return (
        <div className='add-gene'>
            {isOpen && <div className='add-gene__modal-clicktrap' onClick={closeUI} />}

            {!isOpen && <GenesHeaderLabel
                className='add-gene__text-label'
                onClick={() => setIsOpen(true)}
                text={GENE_SEARCH.TEXT_LABEL}
            />}

            <div className='add-gene__gui-elements'>
                {isOpen && <GenesHeaderLabel
                    className='add-gene__text-label add-gene__multi-label'
                    onClick={() => setAddGeneState(displayMultiAdd ? UI_SEARCH : UI_MULTI_ADD)}
                    style={{ fontWeight: displayMultiAdd ? 'bold' : 'normal' }}
                    text='Add Multiple Genes'
                />}

                {isOpen && <div className='add-gene__gene-search-container'>
                    <GeneSearchContainer
                        className='gene-search'
                        autoFocus={!displayMultiAdd}
                        onFocus={() => setAddGeneState(UI_SEARCH)}
                    />
                </div>}

                {/* Added last to be on the far right */}
                {displayMultiAdd && <MultiGeneSelectionFormContainer onSubmit={closeUI} />}
            </div>
        </div>
    );
};

export default AddGenes;
