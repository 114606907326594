import { scaleLinear } from 'd3-scale';
import isFinite from 'lodash/isFinite';

const OFF_WHITE = '#f5f5f5'; // some might call it grayish
const GRAY = 'rgb(200, 200, 200)';

export const interpolateOrGyBl = scaleLinear().domain([0, 0.25, 0.5, 0.75, 1]).range(['#ff4900', '#fda606', '#cccbcc', '#2291fd', '#00018c']).clamp(true);
export const interpolateWtRd = scaleLinear().domain([0, 0.33, 0.66, 1]).range(['#a70000', '#ff5252', '#ffbaba', OFF_WHITE]).clamp(true);
export const interpolateWtBl = scaleLinear().domain([0, 0.33, 0.66, 1]).range(['#2121ff', '#79aaff', '#cdddff', OFF_WHITE]).clamp(true);

export const getColorFunction = colorScale => (value, extent) => {
    if (!isFinite(value)) {
        return GRAY;
    }

    const [min, max] = extent;

    if (max === min) {
        return colorScale(1);
    }

    const t = (value - min) / (max - min);

    return colorScale(1 - t);
};
