import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// COMPONENTS
import ContentGrid from '../../components/content-grid';

// ACTIONS
import {
    changeClusterSelection,
    removeClusterSelection,
} from '../../actions';
import { updateHoveredRow } from '../../actions/grid-actions';
import { tooltipUpdate, tooltipHide } from '../../actions/tooltip-actions';

// SELECTORS
import {
    getSelectedDatasetName,
} from '../../selectors';
import {
    getNodeByFunction,
    getNonSelectedLeafIndexRangePairs,
    getSelectedLeafIndexRangePairsFlat,
    getSelectedTaxonomyNodeDescendantMap,
} from '../../selectors/taxonomy-selectors';
import { applyColorScale } from '../../selectors/color-scale-selectors';

// CONSTANTS
import {
    TAXONOMY_LABEL_ACCESSOR,
} from '../../constants';

// UTILS
import useReferenceWrapper from '../../utils/use-reference-wrapper';
import getEventPositionFromElementFunction from '../../utils/get-event-position-from-element';
import getMatrixIndicesFromEventFunction from '../../utils/get-matrix-indices-from-event';
import getMatrixWidth from '../../utils/get-matrix-width';

const ContentGridContainer = React.forwardRef((props, contentGridRef) => {
    const {
        columnLabelAccessor,
        columnWidth,
        minColor,
        height,
        onLoad,
        matrix,
        matrixRowIndexRangesWithData,
        rowHeight,
        rowLabelAccessor,
        scrollLeft,
        scrollTop,
        sparseMatrix,
        updateScroll,
        width,
        tooltipContent,
    } = props;

    // do not render if there is no matrix data.
    if (!matrix) {
        return null;
    }

    const dispatch = useDispatch();
    const nonSelectedIndexRangePairs = useSelector(getNonSelectedLeafIndexRangePairs);
    const selectedDataset = useSelector(getSelectedDatasetName);
    const selectedIndexRangePairs = useSelector(getSelectedLeafIndexRangePairsFlat);
    const selectedNodeMap = useSelector(getSelectedTaxonomyNodeDescendantMap);
    const getNodeByPath = useSelector(getNodeByFunction);
    const getColor = useSelector(applyColorScale);
    const selectionRef = useReferenceWrapper(selectedNodeMap);

    const eventPositionFromRowLabels = useCallback(getEventPositionFromElementFunction(contentGridRef), []);

    const getMatrixIndicesFromEvent = useCallback(
        getMatrixIndicesFromEventFunction({ rowHeight, columnWidth }, eventPositionFromRowLabels),
        [eventPositionFromRowLabels, rowHeight, columnWidth]
    );

    // gets the matrix datum corresponding to an event
    const getEventMatrixDatum = useCallback((event) => {
        const {
            rowIndex,
            columnIndex,
        } = getMatrixIndicesFromEvent(event);

        return get(matrix, [rowIndex, columnIndex], null);
    }, [matrix, getMatrixIndicesFromEvent]);

    const onMouseMove = useCallback((event) => {
        const data = getEventMatrixDatum(event);
        const rowLabel = get(data, [rowLabelAccessor]);

        if (!data) {
            dispatch(updateHoveredRow());
            dispatch(tooltipHide());
        } else {
            dispatch(updateHoveredRow(rowLabel));
            dispatch(tooltipUpdate({
                contentComponent: tooltipContent,
                contentProps: { data },
                x: event.pageX,
                y: event.pageY,
            }));
        }
    }, [dispatch, getEventMatrixDatum, tooltipContent, rowLabelAccessor]);

    const onMouseLeave = useCallback(() => {
        dispatch(updateHoveredRow());
        dispatch(tooltipHide());
    }, [dispatch]);

    const onClick = useCallback((event) => {
        const isMultiSelect = event.shiftKey || event.metaKey || event.ctrlKey;

        // Select cluster id only if there are no other clusters selected or user is selecting an additional node.
        // Provides deselect functionality.
        if (isEmpty(selectionRef.current) || isMultiSelect) {
            const data = getEventMatrixDatum(event);
            const columnLabel = get(data, columnLabelAccessor, null);
            const node = getNodeByPath(columnLabel, ['data', TAXONOMY_LABEL_ACCESSOR]);

            dispatch(changeClusterSelection(node, selectedDataset));
        } else {
            dispatch(removeClusterSelection(selectedDataset));
        }
    }, [
        dispatch,
        getEventMatrixDatum,
        columnLabelAccessor,
        selectionRef,
        selectedDataset,
        getNodeByPath,
    ]);

    const matrixWidth = useMemo(() => getMatrixWidth(matrix), [matrix]);
    const onAfterRender = useCallback(() => {
        const isRendering = false;
        onLoad(isRendering);
    }, [onLoad]);

    const viewState = {
        target: [
            (width / 2) + scrollLeft,
            (height / 2) + scrollTop,
        ],
    };

    return (
        <ContentGrid
            columnWidth={columnWidth}
            getColor={getColor}
            height={height}
            matrix={matrix}
            matrixRowIndexRangesWithData={matrixRowIndexRangesWithData}
            matrixWidth={matrixWidth}
            onAfterRender={onAfterRender}
            viewState={viewState}
            minColor={minColor}
            nonSelectedIndexRangePairs={nonSelectedIndexRangePairs}
            onClick={onClick}
            onLoad={onLoad}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
            ref={contentGridRef}
            rowHeight={rowHeight}
            scrollLeft={scrollLeft}
            scrollTop={scrollTop}
            selectedIndexRangePairs={selectedIndexRangePairs}
            sparseMatrix={sparseMatrix}
            updateScroll={updateScroll}
            width={width}
        />
    );
});

ContentGridContainer.propTypes = {
    // key of column label
    columnLabelAccessor: PropTypes.string,

    // width of each column
    columnWidth: PropTypes.number,

    // color used for minimum grid cell value
    minColor: PropTypes.string,

    // height of the constraining element
    // used to show scroll bars
    height: PropTypes.number,

    // function used to display the loading indicator
    onLoad: PropTypes.func,

    // 2d matrix data. Array for each row in Grid. Each array element contains an arrays for each column
    matrix: PropTypes.PropTypes.arrayOf(PropTypes.array),

    matrixRowIndexRangesWithData: PropTypes.array,

    // height of each row
    rowHeight: PropTypes.number,

    // key of row label
    rowLabelAccessor: PropTypes.string,

    // current left scroll position
    scrollLeft: PropTypes.number,

    // current top scroll position
    scrollTop: PropTypes.number,

    // sparse array of GridCells containing data
    sparseMatrix: PropTypes.arrayOf(PropTypes.object),

    // (ref: element) => void
    // callback used to get scroll position when changed
    updateScroll: PropTypes.func,

    // width of the constraining element
    // used to show scroll bars
    width: PropTypes.number,

    tooltipContent: PropTypes.func,
};

export default ContentGridContainer;
