import TagManager from 'react-gtm-module';
import {
    ENV_CONSTANTS,
    GOOGLE_TAG_MANAGER_ID,
} from '../constants';

export default function eventError(errorMessage, errorContext) {
    const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_ID,
        auth: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_AUTH,
        preview: ENV_CONSTANTS.GOOGLE_TAG_MANAGER_ENVIRONMENT,
        dataLayer: {
            event: 'Error',
            errorMessage,
            action: errorContext, // GTA action is an easy way to categorize user analytics
        }
    };

    TagManager.initialize(tagManagerArgs);
}
