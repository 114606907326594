import get from 'lodash/get';

import makeQuery from '../utils/make-graphql-query';
import getDatasetsQuery from '../queries/get-list-transcriptomic-datasets-query';
import TranscriptomicsDataset from '../model/transcriptomics-dataset';

export default function getDatasets() {
    return fetchDatasets()
        .then((apiDatasets) => {
            const datasets = apiDatasets.map(apiDataset => new TranscriptomicsDataset(apiDataset));
            return datasets;
        }).catch((e) => {throw e;});
}

export function fetchDatasets() {
    const query = getDatasetsQuery();

    return makeQuery(query)
        .then(response => get(response, ['data', 'listTranscriptomicDataSets'], []))
        .catch((e) => {throw e;});
}
