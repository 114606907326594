import {
    SPACE_HYPHEN_GLOBAL_REGEX,
    URI_FROM_DATASET_NAME_REGEX,
} from '../constants';

/**
 * Class that models the high level properties of a Transcriptomics Dataset
 *
 * Config is the initial properties from the API.
 * We derive any other properties by methods in the class.
 */
export default class TranscriptomicsDataset {
    constructor(config) {
        this.name = config.name;
        this.displayName = config.displayName;
        this.downloadPage = config.downloadPage;
        this.protocolsUrl = config.protocolsUrl;
        this.defaultCentralMeasureDisplayName = config.defaultCentralMeasureDisplayName || config.defaultCentralMeasure;
        this.scatterPlotNames = config.scatterPlotNames;
    }

    getURI() {
        if (!this.uri) {
            return this.setURI();
        }

        return this.uri;
    }

    setURI(givenURI) {
        if (givenURI) {
            this.uri = givenURI;
        } else {
            this.uri = TranscriptomicsDataset.deriveUriFromName(this.name);
        }

        return this.uri;
    }

    static deriveUriFromName(name) {
        return `/${name.replace(URI_FROM_DATASET_NAME_REGEX, '')}`;
    }

    getSpecies() {
        return this.species || this.setSpecies();
    }

    setSpecies() {
        const [species] = this.displayName.split(' - ');
        this.species = species.toUpperCase();

        return this.species;
    }

    getDefaultCentralMeasure() {
        return this.defaultCentralMeasure || this.setDefaultCentralMeasure();
    }

    setDefaultCentralMeasure() {
        // API provides this property in a different form than it is consumed.
        // convert to SCREAMING_SNAKE_CASE for API to consume.
        this.defaultCentralMeasure = this.defaultCentralMeasureDisplayName.toUpperCase().replace(SPACE_HYPHEN_GLOBAL_REGEX, '_');

        return this.defaultCentralMeasure;
    }
}
