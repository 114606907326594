import assign from 'lodash/assign';
import min from 'lodash/min';
import { stratify } from 'd3-hierarchy';

import { RECEIVE_TAXONOMY } from '../actions/fetch-actions';

export const transformTaxonomy = taxonomy => stratify()(taxonomy).sort((a, b) => {
    // If `a` is a leaf and `b` is not, compare `a` with `b`'s first sorted child
    if (!a.children && b.children) {
        return a.data.order - min(b.leaves().map(l => l.data.order));
    }

    // If `b` is a leaf and `a` is not, compare `a` with `b`'s first sorted child
    if (a.children && !b.children) {
        return min(a.leaves().map(l => l.data.order)) - b.data.order;
    }

    // order nodes with an order property
    if (a.data.order && b.data.order) {
        return a.data.order - b.data.order;
    }

    // as last resort for non-leaf nodes, order by the first sorted leaves of each datum.
    return min(a.leaves().map(l => l.data.order)) - min(b.leaves().map(l => l.data.order));
});

const receiveTaxonomyReducer = (state, action) => {
    const {
        data: {
            getTranscriptomicDataSet: {
                cellTypeTaxonomy: [{ taxonomy }],
            },
        },
        metadata: { selectedDataset },
    } = action;

    // Only store transformed hierarchy since apollo stores raw response
    return assign({}, state, { [selectedDataset]: transformTaxonomy(taxonomy) });
};

/**
 * creates reducer with given metadata
 * dataset specific store items are created lazily when set into state
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createTaxonomyReducer() {
    return (state = {}, action) => {
        switch (action.type) {
            case RECEIVE_TAXONOMY:
                return receiveTaxonomyReducer(state, action);

            default:
                return state;
        }
    };
}
