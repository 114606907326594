import assign from 'lodash/assign';
import get from 'lodash/get';

import {
    FETCH_TAXONOMY,
    RECEIVE_TAXONOMY,
    TAXONOMY_FETCH_ERROR,
    FETCH_MARKER_GENES,
    RECEIVE_MARKER_GENES,
    ERROR_MARKER_GENES,
    FETCH_CLUSTER_COUNTS,
    RECEIVE_CLUSTER_COUNTS,
    ERROR_CLUSTER_COUNTS,
} from '../actions/fetch-actions';
import { CHANGE_DATASET } from '../actions';
import { FETCH_STATE } from '../constants';

const initialState = {
    taxonomyFetchState: FETCH_STATE.INIT,
    markerGeneFetchState: FETCH_STATE.INIT,
    clusterCountsFetchState: FETCH_STATE.INIT,
};

const fetchTypeToStateMap = {
    [FETCH_TAXONOMY]: 'taxonomyFetchState',
    [RECEIVE_TAXONOMY]: 'taxonomyFetchState',
    [TAXONOMY_FETCH_ERROR]: 'taxonomyFetchState',
    [FETCH_MARKER_GENES]: 'markerGeneFetchState',
    [RECEIVE_MARKER_GENES]: 'markerGeneFetchState',
    [ERROR_MARKER_GENES]: 'markerGeneFetchState',
    [FETCH_CLUSTER_COUNTS]: 'clusterCountsFetchState',
    [RECEIVE_CLUSTER_COUNTS]: 'clusterCountsFetchState',
    [ERROR_CLUSTER_COUNTS]: 'clusterCountsFetchState',
};

/**
 * Creates reducer in the same pattern as other reducer creators that accept metadata.
 *
 * @returns {function} (state: object, action: object) => object
 */
export default function createFetchReducer() {
    return (state = initialState, action) => {
        const fetchStateKey = get(fetchTypeToStateMap, action.type, null);

        if (fetchStateKey) {
            return assign({}, state, { [fetchStateKey]: action.status });
        }

        switch (action.type) {
            case CHANGE_DATASET:
                return assign({}, state, initialState);

            default:
                return state;
        }
    };
}
