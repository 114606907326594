import React from 'react';
import PropTypes from 'prop-types';

// UTILS
import getClassName from '../../utils/get-class-name';

import './style.scss';

export default function MinimizeExpandContainer(props) {
    const {
        children = null,
        containerClassName = '',
        containerStyle = null,
        expandText = '',
        headerStyle = null,
        headerClassName = '',
        isMinimized,
        onMinimizeClick,
        onExpandClick,
        minimizeText = '',
        shouldDisplayHeader = true,
        showIconText = true,
        subHeaderComponent = null,
    } = props;

    const iconText = isMinimized ? expandText : minimizeText;
    const iconClass = isMinimized ? 'fas fa-expand-alt' : 'fa fa-window-minimize';
    const onClick = isMinimized ? onExpandClick : onMinimizeClick;

    return (
        <div
            className={getClassName('hide-show__container__base', containerClassName)}
            style={containerStyle}
        >
            {shouldDisplayHeader && (
                <div
                    className={getClassName('hide-show__header__base', headerClassName)}
                    onClick={onClick}
                    style={headerStyle}
                    title={iconText}
                >
                    <i className={iconClass}/>
                    {showIconText && iconText}
                </div>
            )}
            {subHeaderComponent}
            {!isMinimized && children}
        </div>
    );
}

MinimizeExpandContainer.propTypes = {
    // react children
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),

    // css class name for container
    containerClassName: PropTypes.string,

    // inline style for container
    containerStyle: PropTypes.object,

    // css class name for header
    headerClassName: PropTypes.string,

    // inline style for header
    headerStyle: PropTypes.object,

    // any text to display next to minimize icon
    minimizeText: PropTypes.string,

    // onclick for when minimize is clicked
    onMinimizeClick: PropTypes.func,

    // onclick for when expand
    onExpandClick: PropTypes.func,

    // any text to display next to expand icon
    expandText: PropTypes.string,

    // should display minimized or not
    isMinimized: PropTypes.bool.isRequired,

    // if component should render the minimize/expand header
    shouldDisplayHeader: PropTypes.bool,

    // if minimizeText|expandText should be shown next to icon.
    // either way the iconText will be applied to the title attribute.
    showIconText: PropTypes.bool,

    // a component to render just below the minimize/expand header
    // note, if provided, this component will always be rendered
    // this will be good for menu options that we want to persist, even when minimized
    subHeaderComponent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
};
