import React from 'react';
import PropTypes from 'prop-types';

const InfoBox = props => {
    return (
        <div className={props.wrapperClassName} style={props.wrapperStyle}>
            {<props.headerComponent {...props.headerProps}/>}
            {props.itemData.map(itemProps => (
                <div className={props.itemClassName} style={props.itemStyle} key={itemProps[props.itemKeyAccessor]}>
                    <props.itemComponent {...itemProps}/>
                </div>
            ))}
        </div>
    );
};

InfoBox.propTypes = {
    wrapperClassName: PropTypes.string.isRequired,
    itemClassName: PropTypes.string.isRequired,
    headerComponent: PropTypes.func.isRequired,
    headerProps: PropTypes.object.isRequired,
    itemComponent: PropTypes.func.isRequired,
    itemData: PropTypes.array.isRequired,
    itemKeyAccessor: PropTypes.string.isRequired,
    wrapperStyle: PropTypes.object,
    itemStyle: PropTypes.object,
};

export default InfoBox;
