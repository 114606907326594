import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import './style.scss';

const TooltipContent = (props) => {
    const { data } = props;
    const tooltipInfo = {
        'Cell Type': data.clusterLabel,
        Gene: data.geneLabel,
        [data.expressionTitle]: data.value,
    };

    return (
        <div className='heatmap__tooltip'>
            {map(tooltipInfo, (value, key) => (
                <div key={`${value}:${key}`}>
                    <span className='heatmap__tooltip--bold'>{key}: </span> {value}
                </div>
            ))}
        </div>
    );
};

export default TooltipContent;

TooltipContent.propTypes = {
    data: PropTypes.object,
};
