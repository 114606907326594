import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import map from 'lodash/map';
import isNil from 'lodash/isNil';

// COMPONENTS
import Header from '../../components/header';
import DropdownSelect from '../../components/dropdown-select';
import { ColorBy, AddGenes } from '../../components/visualization-header';

// CONSTANTS
import {
    VISUALIZATIONS,
    GENE_SEARCH_VISUALIZATIONS,
    COLOR_BY_VISUALIZATIONS,
    COLOR_BY_OPTIONS,
} from '../../constants';

// ACTIONS
import {
    changeVisualization,
    changeDataset,
} from '../../actions';
import { changeColorByFeatureAction } from '../../actions/color-by-actions';

// SELECTORS
import {
    getDatasetOptions,
    getSelectedDatasetOption,
} from '../../selectors/dataset-option-selectors';
import { getSelectedDatasetName, getMarkerGeneList } from '../../selectors';
import { getColorByFeature, getColorByFeatureValue, getColorBySelectValue } from '../../selectors/color-by-selectors';
import { getUserGeneSymbols } from '../../selectors/gene-search-selectors';

// UTILS
import postTopWindow from '../../utils/post-top-window-dataset-change';

// STYLES
import './style.scss';

const visualizationList = map(VISUALIZATIONS, value => ({ value, label: value }));

const updateDatasetRoute = (dispatch, dataset, history) => {
    const route = dataset.uri;

    if (isNil(route)) { return; }

    // Updates the browser url without creating additional history
    history.replace(route);
    dispatch(changeDataset(dataset));
    postTopWindow(changeDataset(dataset));
};

const VisualizationHeader = (props) => {
    const { history } = props;

    const dispatch = useDispatch();
    const selectedVisualization = useSelector(state => state.selectedVisualization);
    const selectedVisualizationOption = visualizationList.filter(opt => opt.value === selectedVisualization);
    const selectedDatasetOption = useSelector(getSelectedDatasetOption);
    const datasetOptions = useSelector(getDatasetOptions);
    const selectedDatasetName = useSelector(getSelectedDatasetName);
    const colorByFeature = useSelector(getColorByFeature);
    const colorByFeatureValue = useSelector(getColorByFeatureValue);
    const colorBySelectValue = useSelector(getColorBySelectValue);
    const userSelectedGenes = useSelector(getUserGeneSymbols);
    const markerGenes = useSelector(getMarkerGeneList);

    const onChangeDatasetHandler = useCallback(
        e => updateDatasetRoute(dispatch, e.value, history),
        [dispatch, history]
    );

    const onChangeVisualizationHandler = useCallback(e => dispatch(changeVisualization(e.value)), [dispatch]);

    const defaultFeatureValueOptions = [].concat(userSelectedGenes, markerGenes)
        .map(symbol => ({ label: symbol, value: symbol }));
    const colorByFeatureSelection = { value: colorByFeature, label: colorByFeature };

    // Handlers
    const colorByHandler = useCallback(
        ({ value: colorByFeature }) => dispatch(changeColorByFeatureAction(selectedDatasetName, colorByFeature, colorByFeatureValue)),
        [dispatch, selectedDatasetName, colorByFeatureValue]
    );
    const selectGeneHandler = useCallback(
        (selection) => {
            if (isNil(selection)) { return; }

            const { label } = selection;
            dispatch(changeColorByFeatureAction(selectedDatasetName, COLOR_BY_OPTIONS.GENE_EXPRESSION, label));
        },
        [dispatch, selectedDatasetName]
    );

    return (
        <Header className='visualization__header'>
            <div className='visualization-dropdown__container'>
                <span className='visualization-dropdown__label'>Dataset:</span>
                <DropdownSelect
                    className='visualization-dropdown__select--dataset'
                    value={selectedDatasetOption}
                    options={datasetOptions}
                    onChangeHandler={onChangeDatasetHandler}
                />
                <span className='visualization-dropdown__label'>Visualization:</span>
                <DropdownSelect
                    className='visualization-dropdown__select--visualization'
                    value={selectedVisualizationOption}
                    options={visualizationList}
                    onChangeHandler={onChangeVisualizationHandler}
                />
            </div>
            <div className='visualization-dropdown__container'>
                {COLOR_BY_VISUALIZATIONS.includes(selectedVisualization) && <ColorBy
                    colorByFeature={colorByFeature}
                    colorByFeatureSelection={colorByFeatureSelection}
                    colorByHandler={colorByHandler}
                    colorBySelectValue={colorBySelectValue}
                    defaultFeatureValueOptions={defaultFeatureValueOptions}
                    selectGeneHandler={selectGeneHandler}
                />}
                {GENE_SEARCH_VISUALIZATIONS.includes(selectedVisualization) && <AddGenes />}
            </div>
        </Header>
    );
};

export default VisualizationHeader;

VisualizationHeader.propTypes = {
    history: PropTypes.object,
};
