import React from 'react';
import PropTypes from 'prop-types';

import ErrorReportItem from './error-report-item';
import './style.scss';

export default function ErrorReport(props) {
    const { errors } = props;

    return (
        <div className='error-report'>
            {errors.map(({ friendlyMessage, originalMessage, selfDismissTimeout }, index) => (
                <ErrorReportItem
                    key={`${friendlyMessage}${index}`}
                    index={index}
                    friendlyMessage={friendlyMessage}
                    originalMessage={originalMessage}
                    selfDismissTimeout={selfDismissTimeout}
                />
            ))}
        </div>
    );
}

ErrorReport.propTypes = {
    errors: PropTypes.array,
};
