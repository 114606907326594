import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';

// components
import Histogram from '../histogram';

// utils
import roundDigits from '../../utils/round-digits';

const CellExpressionHistogram = (props) => {
    const { color, count, alias } = props;
    const [cellFrequency, setCellFrequency] = useState(null);
    const [averageExpression, setAverageExpression] = useState(null);

    const colorStyle = useMemo(() => ({ backgroundColor: color }), [color]);

    const onHoverCB = useCallback((binDatum) => {
        setCellFrequency(binDatum.frequency);
        setAverageExpression(roundDigits(binDatum.average, 2));
    }, []);

    const onLeaveCB = useCallback(() => {
        setCellFrequency(null);
        setAverageExpression(null);
    }, []);

    return (
        <>
            <div>
                <Histogram
                    svgClassName='info-box__histogram'
                    onHoverCB={onHoverCB}
                    onLeaveCB={onLeaveCB}
                    {...props}
                />
                <div className='cell-expression-histogram__label-container'>
                    <div className='cell-expression-histogram__color' style={colorStyle}/>
                    <div>{alias}</div>
                    <div className='cell-expression-histogram__quantities'>
                        {!isNull(averageExpression) && <div className='cell-expression-histogram__average'>{`${averageExpression} exp`}</div>}
                        {<div>{!isNull(cellFrequency) ? `${cellFrequency} cells` : `${count} cells`}</div>}
                    </div>
                </div>
            </div>
        </>
    );
};

CellExpressionHistogram.propTypes = {
    color: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    alias: PropTypes.string.isRequired,
    ...Histogram.propTypes,
};

export default CellExpressionHistogram;
