import { createSelector } from 'reselect';
import get from 'lodash/get';

import { COLOR_SCALE_OPTIONS } from '../constants';
import { getSelectedVisualization } from '.';
import { getColorFunction } from '../utils/color-scales';

export const getColorScaleMap = state => state.colorScale;

export const getColorScaleKeyByVisualization = createSelector(
    [getColorScaleMap, getSelectedVisualization],
    (colorScaleMap, selectedVisualization) => get(colorScaleMap, [selectedVisualization], null),
);

export const getColorScale = createSelector(
    [getColorScaleKeyByVisualization],
    colorScaleKey => get(COLOR_SCALE_OPTIONS, [colorScaleKey], COLOR_SCALE_OPTIONS.BLUE_TO_RED),
);

export const applyColorScale = createSelector([getColorScale], getColorFunction);
