import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import getGeneSymbolsQuery from '../../queries/get-gene-symbols-query';
import makeQuery from '../../utils/make-graphql-query';
import getExactGeneMatch from '../../utils/get-exact-gene-match';
import getKeyUsingValue from '../../utils/get-key-using-value';
import { ARROW_DOWN, ARROW_UP, ENTER_KEY, GENE_SEARCH, VISUALIZATIONS } from '../../constants';
import { addUserGenesAction } from '../../actions/gene-search-actions';
import { fetchErrorAction } from '../../actions/errors-actions';
import { getSelectedDatasetName } from '../../selectors';
import getClassName from '../../utils/get-class-name';

import './style.scss';

// overrides react-select default styles. Requires use of 'classNamePrefix' property.
import { CLASS_NAME_PREFIX } from '../../components/dropdown-select';
import '../../components/dropdown-select/style.scss';

/**
 * Called whenever the user types into the text input box.
 * Will ask GraphQL server for any matching entries, return values
 * formatted and used to populate react-Select dropdown menu.
 * Ignores any whitespace entries.
 *
 * @param {function} dispatch - redux dispatch
 * @param {string} whatUserTyped - entire contents of search input box
 * @param {string} selectedDataset - name of dataset
 * @returns array with elements in form {value: {entrezId: <number>, symbol: <string>}, label:<string>}
 */
async function findGeneSymbols(dispatch, whatUserTyped, selectedDataset) {
    const stringNoWhitespace = whatUserTyped.replace(/\s/g, '');
    if (stringNoWhitespace.length === 0) {
        return;
    }

    const query = getGeneSymbolsQuery(stringNoWhitespace, selectedDataset, 10);

    try {
        const queryResults = await makeQuery(query); // executes the GraphQL query
        const { geneArray } = queryResults.data;

        if (isEmpty(geneArray)) {
            return [];
        }

        return geneArray.map(element => {
            const value = {
                symbol: element.symbol,
                entrezId: element.entrezId,
            };

            return {
                value,
                label: element.symbol
            };
        });
    } catch (error) {
        dispatch(fetchErrorAction(error));
        return [];
    }
}

/**
 * Triggered when user mouse-clicks one of the gene symbol menu items,
 * or uses arrow keys + ENTER to select a gene symbol menu item.
 *
 * Adds the selected gene symbol to the Heatmap.
 *
 * @param {Object} userSelection - contains {value: {entrezId: <number>, symbol: <string>}, label:<string>}
 * @param {Object[]} dependencies
 * @param {function} dependencies.setFocusInTextBox - set value of 'focusInTextBox'
 * @param {string} dependencies.selectedDataset - name of dataset
 * @param {string} dependencies.selectedVisualization - display name of data visualization
 * @param {Object} dependencies.geneSearchRef - reference to <AsyncSelect> node
 * @param {function} dependencies.dispatch - Redux dispatch
 */
const onChangeHandler = (userSelection, dependencies) => {
    const [setFocusInTextBox, selectedDataset, selectedVisualization, geneSearchRef, dispatch] = dependencies;

    // whenever a gene is added to heatmap, revert focus flag to the text box
    setFocusInTextBox(true);

    // clear the select box contents, then restore focus and blinking cursor
    geneSearchRef.current.blur();
    geneSearchRef.current.focus();

    // 'value' is {symbol:<string>, entrezId:<number>}
    dispatch(addUserGenesAction(selectedDataset, getKeyUsingValue(VISUALIZATIONS, selectedVisualization), userSelection.value));
};

/**
 * ENTER key set up to submit contents of text box if it has focus,
 * otherwise submit the currently-highlighted menu item, which is the
 * default react-select processing pathway.
 *
 * @param {Object} event - contains keypress codes
 * @param {Object[]} dependencies
 * @param {boolean} dependencies.focusInTextBox
 * @param {function} dependencies.setFocusInTextBox
 * @param {string} dependencies.selectedDataset - name of dataset
 * @param {string} dependencies.selectedVisualization - display name of data visualization
 * @param {Object} dependencies.geneSearchRef - reference to <AsyncSelect> node
 * @param {function} dependencies.dispatch - Redux dispatch
 */
const onKeyDownHandler = (event, dependencies) => {
    const [focusInTextBox, setFocusInTextBox, selectedDataset, selectedVisualization, geneSearchRef, dispatch] = dependencies;

    switch (event.which) {
        case ENTER_KEY:
            if (focusInTextBox) {
                event.preventDefault();
                // get contents of text box and try to add to heatmap as a user gene
                const whatUserTyped = geneSearchRef.current.state.inputValue;
                findGeneSymbols(dispatch, whatUserTyped, selectedDataset).then(result => {
                    const matchingGeneObj = getExactGeneMatch(result, whatUserTyped);
                    if (matchingGeneObj) {
                        // clear the text box contents, then restore focus and blinking cursor
                        geneSearchRef.current.blur();
                        geneSearchRef.current.focus();

                        // 'value' is {symbol:<string>, entrezId:<number>}
                        dispatch(addUserGenesAction(selectedDataset, getKeyUsingValue(VISUALIZATIONS, selectedVisualization), matchingGeneObj.value));
                    }
                });
            }
            break;
        case ARROW_UP:
        case ARROW_DOWN:
            // user now in menu, so next press of ENTER key adds the menu item's gene symbol to heatmap via onChangeHandler
            setFocusInTextBox(false);
            break;
        default:
            break;
    }
};

/*
 * Renders a text label  "Add Genes". When clicked, the link is replaced by a text input box that accepts typed
 * user input. Each char typed is submitted to a gene symbol search service, and the 1st 10 matching entries are displayed
 * beneath the text input box. If the user clicks on a displayed entry, that value is added to the Redux store. If the user
 * presses the Enter/Return key while in the text input box, the currently-selected search result entry is added to the
 * Redux store.
 *
 * If the user clicks outside of the gene search UI, the gene search UI is hidden and the text label "Add Genes" is restored.
 *
 * @param {Object} props - React properties
 * @returns JSX render content
 */
const GeneSearchContainer = props => {
    // global state variables (Redux)
    const selectedDataset = useSelector(getSelectedDatasetName);
    const selectedVisualization = useSelector(state => state.selectedVisualization);

    // local state variables:
    const [focusInTextBox, setFocusInTextBox] = useState(props.autoFocus);

    const dispatch = useDispatch();
    const geneSearchRef = useRef(null);

    const onChangeDependencies = [
        setFocusInTextBox,
        selectedDataset,
        selectedVisualization,
        geneSearchRef,
        dispatch,
    ];

    const onKeyDownDependencies = [
        focusInTextBox,
        setFocusInTextBox,
        selectedDataset,
        selectedVisualization,
        geneSearchRef,
        dispatch,
    ];

    // callbacks

    const onChangeCallback = useCallback((userSelection) => onChangeHandler(userSelection, onChangeDependencies), [onChangeDependencies]);
    const onKeyDownCallback = useCallback((event) => onKeyDownHandler(event, onKeyDownDependencies), [onKeyDownDependencies]);
    const findGeneSymbolsCallback = useCallback((inputValue) => findGeneSymbols(dispatch, inputValue, selectedDataset), [dispatch, selectedDataset]);

    /**
     * Clear text input box when 'x' button pressed
     * and set focus back on the input box to get blinking cursor
     */
    const clearInput = () => {
        geneSearchRef.current.focus();
    };

    // set up <AsyncSelect /> properties. See https://react-select.com/home
    const searchProps = {
        value: '',
        onChange: onChangeCallback,
        onKeyDown: onKeyDownCallback,
        openMenuOnClick: true,
        onFocus: props.onFocus,

        // ensure focus and blinking cursor visible in the text input box
        autoFocus: props.autoFocus,
        blurInputOnSelect: false,
        closeMenuOnSelect: false, // side effect: after adding gene to heatmap, focus returned to text input box AND blinking cursor restored!

        // initial search box results set to be empty, when user types in box,
        // uses loadOptions to fill dropdown menu with search results.
        defaultOptions: [],
        loadOptions: findGeneSymbolsCallback,

        // custom text box messages
        placeholder: GENE_SEARCH.PLACEHOLDER,
        noOptionsMessage: () => GENE_SEARCH.NOTHING_FOUND,

        // add this prefix to classNames of react-select subcomponents so we can user our custom styles
        classNamePrefix: CLASS_NAME_PREFIX,

        // remove react-Select default UI elements
        components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
        },

        ref: geneSearchRef,

        className: 'gene-search__react-select',
    };

    return (
        <div className={getClassName(props.className, 'gene-search__gui-elements')}>
            <i className='fas fa-search gene-search__search-icon' />
            <AsyncSelect {...searchProps} />
            <i className='fas fa-times gene-search__text-entry-clear' onClick={clearInput} />
        </div>
    );
};

GeneSearchContainer.propTypes = {
    className: PropTypes.string,
    onFocus: PropTypes.func,
    autoFocus: PropTypes.bool,
};

export default GeneSearchContainer;
