import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import assign from 'lodash/assign';

// style
import './style.scss';

// constants
import {
    SPECIMEN_AGGREGATE_PROPERTIES,
    FETCH_STATE,
    HUMAN_AD_ROWS_TO_REMOVE,
    HUMAN_AD_DATASET_NAME,
} from '../../constants';

// queries
import { getSpecimenAggregateQuery } from '../../queries';

// actions
import { fetchData } from '../../actions';
import {
    SAMPLING_STRATEGY_ERROR_SPECIMEN_AGGREGATE,
    SAMPLING_STRATEGY_FETCH_SPECIMEN_AGGREGATE,
    SAMPLING_STRATEGY_RECEIVE_SPECIMEN_AGGREGATE,
} from '../../actions/sampling-strategy-actions';

// selectors
import { getSelectedDatasetName } from '../../selectors';
import {
    getRowLabels,
    getSpecimenAggregateMatrix,
    getSpecimenAggregateFetchState,
} from '../../selectors/sampling-strategy-selectors';

// hooks and utils
import useWindowWidth from '../../utils/use-window-width';
import useWindowHeight from '../../utils/use-window-height';

// components
import SamplingStrategyTableContainer from './sampling-strategy-table-container';
import { Loader } from '../../components/indicators';

const fetchSpecimenAggregate = (dispatch, selectedDataset, specimenAggregateProperties) => {
    const queryParamsList = specimenAggregateProperties.map(properties => assign({}, properties, { dataset: selectedDataset }));
    const graphqlQuery = getSpecimenAggregateQuery(queryParamsList);
    const metadata = { selectedDataset };
    dispatch(fetchData(
        graphqlQuery,
        SAMPLING_STRATEGY_FETCH_SPECIMEN_AGGREGATE,
        SAMPLING_STRATEGY_RECEIVE_SPECIMEN_AGGREGATE,
        SAMPLING_STRATEGY_ERROR_SPECIMEN_AGGREGATE,
        metadata,
    ));
};

const SamplingStrategy = () => {
    const specimenAggregateFetchState = useSelector(getSpecimenAggregateFetchState);
    const rowLabels = useSelector(getRowLabels);
    const selectedDataset = useSelector(getSelectedDatasetName);
    const specimenAggregateMatrix = useSelector(getSpecimenAggregateMatrix);
    const windowHeight = useWindowHeight();
    const windowWidth = useWindowWidth();
    const dispatch = useDispatch();
    const samplingStrategyContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(true);

    const samplingStrategyTop = samplingStrategyContainerRef.current && samplingStrategyContainerRef.current.getBoundingClientRect().top;
    const fetchingSpecimenAggregateData = specimenAggregateFetchState === FETCH_STATE.FETCHING;
    const specimenAggregateShouldFetch = specimenAggregateFetchState === FETCH_STATE.INIT;
    const showLoader = useMemo(() => isLoading || fetchingSpecimenAggregateData, [isLoading, fetchingSpecimenAggregateData]);
    const onHeatmapLoad = useCallback(isLoading => setIsLoading(isLoading), []);

    useEffect(() => {setIsLoading(true);}, [selectedDataset]);

    useEffect(() => {
        if (specimenAggregateShouldFetch) {
            fetchSpecimenAggregate(dispatch, selectedDataset, SPECIMEN_AGGREGATE_PROPERTIES);
        }
    }, [dispatch, selectedDataset, specimenAggregateShouldFetch]);

    //Formatting of Row Labels and Matrix below
    //Biological Sex and Cortical Layer are scientifically inaccurate for Human AD
    //and must be removed
    const formattedRowLabels = useMemo(() => {
        if (selectedDataset === HUMAN_AD_DATASET_NAME) {
            return rowLabels.filter(label => !HUMAN_AD_ROWS_TO_REMOVE.includes(label.text));
        }
        return rowLabels;
    }, [rowLabels, selectedDataset]);

    const formattedMatrix = useMemo(() => {
        if (selectedDataset === HUMAN_AD_DATASET_NAME && specimenAggregateMatrix) {
            const mutableAggregateMatrix = specimenAggregateMatrix;
            const initialValues = mutableAggregateMatrix.splice(0, 2);
            const endValues = mutableAggregateMatrix.splice(6, 29);
            return [...initialValues, ...endValues];
        }
        return specimenAggregateMatrix;
    }, [specimenAggregateMatrix, selectedDataset]);

    return (
        <div className='sampling-strategy__container' ref={samplingStrategyContainerRef}>
            {showLoader && <Loader/>}
            <SamplingStrategyTableContainer
                height={windowHeight - samplingStrategyTop}
                isLoading={isLoading}
                matrix={formattedMatrix}
                onLoad={onHeatmapLoad}
                rowLabels={formattedRowLabels}
                width={windowWidth}
            />
        </div>
    );
};

export default SamplingStrategy;
