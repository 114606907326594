/**
 * Util for setting a key value pair in session storage
 * @param {string} key - key used to access data in session storage
 * @param {string or object or array} value - value to store that is a string or can be converted to a string via JSON.stringify
 */
export default function setSessionStorage(key, value) {
    const storageValue = typeof value === 'string' ? value : JSON.stringify(value);

    sessionStorage.setItem(key, storageValue);
}
