import assign from 'lodash/assign';
import min from 'lodash/min';
import max from 'lodash/max';
import values from 'lodash/values';

import {
    SCATTER_PLOT_FETCH_POINT_DATA,
    SCATTER_PLOT_RECEIVE_POINT_DATA,
    SCATTER_PLOT_ERROR_POINT_DATA,
    SCATTER_PLOT_FETCH_GENE_EXPRESSION,
    SCATTER_PLOT_RECEIVE_GENE_EXPRESSION,
    SCATTER_PLOT_ERROR_GENE_EXPRESSION,
    SCATTER_PLOT_UPDATE_VIEW_STATE,
} from '../actions/scatter-plot-actions';
import { CHANGE_COLOR_BY_FEATURE } from '../actions/color-by-actions';
import { CHANGE_DATASET, CHANGE_VISUALIZATION } from '../actions';
import { FETCH_STATE } from '../constants';
import unpackCompressedData from '../utils/unpack-compressed-data';

const initialFetchState = {
    scatterPlotPointDataFetchStatus: FETCH_STATE.INIT,
    scatterPlotGeneExpressionFetchStatus: FETCH_STATE.INIT,
};

const receiveScatterPlotPointDataReducer = (state, action) => {
    const {
        data: {
            scatterPlotPointData: {
                tSNEData: {
                    data: compressedData,
                }
            }
        },
        metadata: { selectedDatasetName },
        status,
    } = action;

    const unpackedData = unpackCompressedData(compressedData);

    const nextScatterPlotPointData = assign({}, state.scatterPlotPointData, { [selectedDatasetName]: unpackedData });

    return assign({}, state,
        {
            scatterPlotPointData: nextScatterPlotPointData,
            scatterPlotPointDataFetchStatus: status,
        },
    );
};

const receiveScatterPlotGeneExpressionReducer = (state, action) => {
    const {
        data: { expressionData },
        metadata: { selectedDataset },
        status,
    } = action;

    const expressionMin = min(values(expressionData));
    const expressionMax = max(values(expressionData));
    const nextScatterPlotGeneExpression = assign({}, state.scatterPlotGeneExpression, { [selectedDataset]: { expressionData, expressionMin, expressionMax } });

    return assign({}, state,
        {
            scatterPlotGeneExpression: nextScatterPlotGeneExpression,
            scatterPlotGeneExpressionFetchStatus: status,
        },
    );
};

const updateViewState = (state, action) => {
    const nextViewState = assign({}, state.viewState, { [action.selectedDataset]: action.viewState });

    return assign({}, state, { viewState: nextViewState });
};

/**
 * creates reducer with given metadata
 * dataset specific store items are created lazily when set into state
 *
 * @param {object} metadata
 * @returns {function} (state: object, action: object) => object
 */
export default function createScatterPlotReducer(metadata) {
    const initialViewState = metadata.datasets.reduce((acc, dataset) => {
        acc[dataset.name] = {
            zoom: 3.5,
            maxZoom: 10,
            minZoom: 0,
        };
        return acc;
    }, {});

    const defaultState = {
        ...initialFetchState,
        viewState: initialViewState,
    };

    return (state = defaultState, action) => {
        switch (action.type) {
            case SCATTER_PLOT_FETCH_POINT_DATA:
                return assign({}, state, { scatterPlotPointDataFetchStatus: action.status });

            case SCATTER_PLOT_RECEIVE_POINT_DATA:
                return receiveScatterPlotPointDataReducer(state, action);

            case SCATTER_PLOT_ERROR_POINT_DATA:
                return assign({}, state, { scatterPlotPointDataFetchStatus: action.status });

            case SCATTER_PLOT_FETCH_GENE_EXPRESSION:
                return assign({}, state, { scatterPlotGeneExpressionFetchStatus: action.status });

            case SCATTER_PLOT_RECEIVE_GENE_EXPRESSION:
                return receiveScatterPlotGeneExpressionReducer(state, action);

            case SCATTER_PLOT_ERROR_GENE_EXPRESSION:
                return assign({}, state, { scatterPlotGeneExpressionFetchStatus: action.status });

            case CHANGE_DATASET:
                return assign({}, state, initialFetchState);

            case CHANGE_VISUALIZATION:
                return assign({}, state, initialFetchState);

            case CHANGE_COLOR_BY_FEATURE:
                return assign({}, state, initialFetchState);

            case SCATTER_PLOT_UPDATE_VIEW_STATE:
                return updateViewState(state, action);

            default:
                return state;
        }
    };
}
